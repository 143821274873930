import { serverErrorMessage } from "./utils/server-error-message";

export class NonValidationErrors {
  #errors: { code: number; message: string }[] = [];

  add(code: number, message: string) {
    this.#errors.push({ code, message });
  }

  get message() {
    return this.#errors.map(e => serverErrorMessage(e.code, e.message)).join(". ");
  }
  get codes() {
    return this.#errors.map(e => e.code);
  }

  get name() {
    return "NonValidationErrors";
  }
}
