export enum ApiShieldingStatus {
  Queued = "QUEUED",
  InProgress = "IN_PROGRESS",
  Failed = "FAILED",
  Success = "SUCCESS",
  Timeout = "TIMEOUT",
  Aborted = "ABORTED",
}
export const enum ShieldingsActions {
  Details = "details",
  Download = "download",
  Progress = "progress",
}
