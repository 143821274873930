import { Theme, ThemeOptions } from "@mui/material/styles";

export const dialogTheme = (theme: Theme): ThemeOptions["components"] => ({
  MuiDialog: {
    styleOverrides: {
      paper: {
        boxShadow: "none",
        margin: theme.spacing(1),
      },
      paperScrollPaper: {
        maxHeight: `calc(100% - ${theme.spacing(2)})`,
      },
    },
  },
});
