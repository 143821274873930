import { AxiosInstance } from "axios";

import { type ID } from "../../types";
import { CONTAINER_ID } from "../../ui/constants";
import { searchParams } from "../../utils/search-params";
import { ApiEntity } from "../api-entity";
import { ApiPresignedUrl } from "./types/api-presigned-url";
import { getRequest } from "./utils/get-request";

export function downloadOne(
  restClient: AxiosInstance,
  apiEntity: ApiEntity,
  entityId: ID | number | undefined,
  fileName: string,
  queryParams?: Record<string, string>,
) {
  const params = searchParams({ action: "download", ...queryParams });
  const url = entityId ? `${apiEntity}/${entityId}${params}` : `${apiEntity}${params}`;

  return getRequest(restClient, url, ApiPresignedUrl).then(({ url }) => {
    const element = document.getElementById(CONTAINER_ID)!;
    const link = document.createElement("a");

    link.style.display = "none";
    link.href = url;
    link.setAttribute("download", fileName);
    element.appendChild(link);
    link.click();
    element.removeChild(link);
    URL.revokeObjectURL(url);
  });
}
