import { Box } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { ApiAccessPanel } from "../../../../api-access/api-access-panel";
import { ConfigStatus } from "../../../../configurations/api/types/config-status";
import { GetProject } from "../../../api/get-project";
import { BasicInfo } from "./basic-info";
import { ConfigurationInfo } from "./configuration-info";

interface ProjectDetailsInfoPanelProps {
  project?: GetProject;
  isLoading?: boolean;
  isFetching?: boolean;
}

export const ProjectDetailsInfoPanel = ({ project, isLoading }: ProjectDetailsInfoPanelProps) => {
  const { classes } = useStyles();
  const isConfigurationDefined = !isLoading && project && project.configStatus !== ConfigStatus.None;

  return (
    <Box className={classes.container}>
      <BasicInfo project={project} isLoading={isLoading} />
      <ConfigurationInfo project={project} isLoading={isLoading} />
      {isConfigurationDefined && <ApiAccessPanel project={project} isLoading={isLoading} />}
    </Box>
  );
};

const useStyles = makeStyles()(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    marginRight: theme.spacing(1.25),
    width: 384,
    minWidth: 384,
    "& > *": {
      overflow: "visible !important",
      // Using margin instead of gap so we don't add an extra gap before the last pseudo-element
      marginBottom: theme.spacing(2.5),
    },
    "& > *:last-child": {
      marginBottom: 0,
    },
    // Using this pseudo-element to add a margin at the bottom of the column
    "&::after": {
      content: '""',
      height: theme.spacing(2.5),
      flexShrink: "0",
      width: "100%",
    },
  },
}));
