import { Box } from "@mui/material";

import { TextSkeleton } from "../../../../../../../../_common/ui/skeleton/text-skeleton";
import { ColumnCellProps } from "../../../../../../../../_common/ui/table/data-table";
import { RowLink } from "../../../../../../../../_common/ui/table/data-table/row-link";
import { DebugModeTag } from "../../../../../../_common/ui/debug-mode-tag";
import { GetShieldingsListItem } from "../../../../api/get-shieldings-list-item";
import { ShieldingSource } from "../../../../types";
import { ShieldingSourceTag } from "../../../shielding-source-tag";

type ShieldingCellProps = ColumnCellProps<GetShieldingsListItem>;
export const ShieldingCell = ({
  id,
  isLoading,
  debugMode,
  shieldingInputFileName,
  shieldingSource,
}: ShieldingCellProps) => {
  const showSourceTag = shieldingSource === ShieldingSource.Api;

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      {isLoading ? (
        <TextSkeleton height="24px" />
      ) : (
        <>
          {(showSourceTag || debugMode) && (
            <Box display="flex" flexDirection="row" gap={1}>
              <ShieldingSourceTag source={shieldingSource} />
              <DebugModeTag show={debugMode ?? false} />
            </Box>
          )}
          <RowLink to={`shieldings/${id}`}>{shieldingInputFileName}</RowLink>
        </>
      )}
    </Box>
  );
};
