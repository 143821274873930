import { hashKey } from "@tanstack/react-query";

import { QueryKey } from "../../../../_common/api/query-keys/query-key.type";
import { onPremShieldersRootPath } from "../../on-prem-shielders-root-path";

export const onPremShieldersQueryKeysFactory = () => ({
  all: () => [onPremShieldersRootPath] as const,
  list: () => [...onPremShieldersQueryKeysFactory().all(), "list"] as const,

  items: () => [...onPremShieldersQueryKeysFactory().all(), "items"] as const,
  item: (fileName: string | undefined) => {
    const appShieldingToolItem = [...onPremShieldersQueryKeysFactory().items(), fileName] as const;

    return {
      everything: () => appShieldingToolItem,
      download: () => [...appShieldingToolItem, "download"] as const,
    };
  },
});

// Valid 404 error can be only on item
// Other 404 errors are considered as BE bug => 500
export const considerAppShieldingToolsQueryKey404As500 = (key: QueryKey) => {
  const all = onPremShieldersQueryKeysFactory().all();
  const items = onPremShieldersQueryKeysFactory().items();

  return hashKey(all) === hashKey(key.slice(0, all.length)) && hashKey(items) !== hashKey(key.slice(0, items.length));
};

export const invalidationAppShieldingToolsKeysOn404 = (_key: QueryKey) => ({
  invalidateKey: onPremShieldersQueryKeysFactory().list(),
});
