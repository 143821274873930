import { useState } from "react";
import { useFormState } from "react-hook-form";

import { addAction, AppShieldingActions, AppShieldingFeatures } from "../../../../../../_common/datadog";
import { jumpToElement } from "../../../../../../_common/ui/utils/jump-to-element";
import { itemId } from "../../utils/item-id";

export const useErrorsNavigation = (items: string[]) => {
  const { errors: formErrors } = useFormState();
  const errors = items.filter(item => formErrors[item]);
  const [currentError, setCurrentError] = useState<string>("");

  const prevError =
    errors.length <= 1 || !currentError
      ? undefined
      : items.slice(0, items.indexOf(currentError)).findLast(item => formErrors[item]);

  const nextError =
    errors.length <= 1
      ? undefined
      : !currentError
        ? errors[0]
        : items.slice(items.indexOf(currentError) + 1).find(item => formErrors[item]);

  return {
    errorsCount: errors.length,

    jumpToErrorButtonClick:
      errors.length === 1
        ? () => {
            addAction(AppShieldingActions.JumpToConfigurationError, AppShieldingFeatures.Configurations);
            jumpToElement(itemId(errors[0]));
            setCurrentError(errors[0]);
          }
        : undefined,

    prevButtonClick: prevError
      ? () => {
          addAction(AppShieldingActions.JumpToPreviousConfigurationError, AppShieldingFeatures.Configurations);
          jumpToElement(itemId(prevError));
          setCurrentError(prevError);
        }
      : undefined,

    nextButtonClick: nextError
      ? () => {
          addAction(AppShieldingActions.JumpToNextConfigurationError, AppShieldingFeatures.Configurations);
          jumpToElement(itemId(nextError));
          setCurrentError(nextError);
        }
      : undefined,
  };
};
