import { Box, BoxProps } from "@mui/material";
import React, { forwardRef, ReactNode, useMemo } from "react";
import { tss } from "tss-react/mui";

import { FormFieldContext } from "./form-field-context";
import { FormFieldHelperText } from "./form-field-helper-text";
import { FormFieldLabel } from "./form-field-label";
import { LabelPlacement } from "./types";

type ClassesType = {
  root: string;
  labelFieldWrapper: string;
  fieldWrapper: string;
};

type FormFieldChild = ReactNode;

export interface FormFieldProps extends BoxProps {
  className?: string;
  classes?: ClassesType;
  children: FormFieldChild[];
  fullWidth?: boolean;
  id: string;
  labelPlacement?: LabelPlacement;
  status?: "error" | "success" | "warning";
}

export const FormField = forwardRef<HTMLElement, FormFieldProps>(
  ({ id, children, classes, className, fullWidth, labelPlacement = "top", status, ...props }, ref) => {
    const { cx } = useStyles();

    let label: ReactNode = null;
    let content: ReactNode = null;
    let helperText: ReactNode = null;

    children.forEach((child: ReactNode) => {
      if (React.isValidElement(child)) {
        switch (child.type) {
          case FormFieldLabel:
            label = child;
            break;
          case FormFieldHelperText:
            helperText = child;
            break;
          default:
            content = child;
        }
      }
    });

    const context = useMemo(
      () => ({
        helperTextId: helperText ? `${id}--helperText` : undefined,
        fullWidth,
        id,
        placement: labelPlacement,
        status,
      }),
      [fullWidth, helperText, id, labelPlacement, status],
    );

    return (
      <FormFieldContext.Provider value={context}>
        <Box
          display="inline-flex"
          width={fullWidth ? "100%" : undefined}
          justifyContent="flex-start"
          flexDirection="column"
          className={cx(className, classes?.root)}
          {...props}
          ref={ref}
        >
          <Box
            display="flex"
            alignItems={labelPlacement === "right" ? "center" : "flex-start"}
            flexDirection={labelPlacement === "right" ? "row-reverse" : "column"}
            justifyContent={labelPlacement === "right" ? "flex-end" : "flex-start"}
            width="100%"
            className={classes?.labelFieldWrapper}
          >
            {label}
            <Box
              alignItems="center"
              display="flex"
              flexDirection="row"
              mr={labelPlacement === "right" ? 1 : undefined}
              mt={labelPlacement === "right" ? undefined : 0.5}
              width={fullWidth && labelPlacement === "top" ? "100%" : undefined}
              className={classes?.fieldWrapper}
            >
              {content}
            </Box>
          </Box>
          {helperText}
        </Box>
      </FormFieldContext.Provider>
    );
  },
);

FormField.displayName = "FormField";

const useStyles = tss.create(() => ({}));
