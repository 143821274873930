import { ReactNode } from "react";
import { RawIntlProvider } from "react-intl";

import { i18n } from "./i18n";

interface I18nProviderProps {
  children: ReactNode;
}
export const I18nProvider = ({ children }: I18nProviderProps) => (
  <RawIntlProvider value={i18n.intl}>{children}</RawIntlProvider>
);
