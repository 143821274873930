import { CircularProgress } from "@mui/material";
import { forwardRef } from "react";

type ButtonProgressProps = Record<string, unknown>;

export const ButtonProgress = forwardRef<SVGSVGElement, ButtonProgressProps>((props, ref) => (
  <CircularProgress size={21} color="inherit" ref={ref} {...props} />
));

ButtonProgress.displayName = "ButtonProgress";
