import { createTheme } from "@mui/material/styles";
import createMixins from "@mui/material/styles/createMixins";

declare module "@mui/material/styles/createMixins" {
  interface Mixins {
    lineClamp: (lines: number) => Pick<CSSProperties, "overflow" | "display" | "wordBreak">;
  }
}

const { breakpoints } = createTheme();

export const mixins = createMixins(breakpoints, {
  lineClamp: lines => ({
    display: "-webkit-box",
    overflow: "hidden",
    wordBreak: "break-word",
    WebkitLineClamp: `${lines}`,
    WebkitBoxOrient: "vertical",
  }),
});
