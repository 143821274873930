import type { ConfigurationItemVisibility as ConfigurationItemVisibilityType } from "@mob/shielder-metadata";
import { ReactNode, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";

interface ConfigurationItemVisibilityProps {
  invisible: ConfigurationItemVisibilityType;
  name: string;
  children: ReactNode;
}
export const ConfigurationItemVisibility = ({ invisible, name, children }: ConfigurationItemVisibilityProps) => {
  const values = useWatch({ disabled: !invisible, ...(invisible ? { name: invisible.map(i => i.name) } : {}) });
  const { clearErrors, trigger, formState } = useFormContext();
  const { isSubmitted } = formState;

  const hideItem = invisible?.some(({ negate, value }, index) =>
    negate ? values[index] !== value : values[index] === value,
  );

  useEffect(() => {
    if (invisible) {
      if (hideItem) clearErrors(name);
      else if (isSubmitted) void trigger(name);
    }
  }, [clearErrors, hideItem, invisible, isSubmitted, name, trigger]);

  return hideItem ? null : <>{children}</>;
};
