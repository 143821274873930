import { ConfigurationItem as ConfigurationItemType } from "@mob/shielder-metadata";

export type ValidationDependencies = Record<string, string[]>;

export const getValidationDependencies = (items: ConfigurationItemType[]) => {
  const result: ValidationDependencies = {};

  for (const item of items) {
    const dependOnName =
      "validations" in item && item.validations && "allowedValue" in item.validations
        ? item.validations.allowedValue.condition.name
        : "validations" in item.data && item.data.validations && "allowedValue" in item.data.validations
          ? item.data.validations.allowedValue.condition.name
          : undefined;

    if (dependOnName) {
      const deps = (result[dependOnName] ||= []);
      deps.push(item.name);
    }
  }

  return result;
};
