import { useSearchParams } from "react-router-dom";

import { DEFAULT_PARAM_NAME } from "./default-param-name";

const onlyNumbers = /^\d+$/;

export const useCurrentPage = (paramName: string = DEFAULT_PARAM_NAME) => {
  const [searchParams] = useSearchParams();
  const paramValue = searchParams.get(paramName);
  if (!paramValue) {
    return 1;
  }

  if (!onlyNumbers.test(paramValue)) {
    return 0;
  }

  const value = parseInt(paramValue);
  return isFinite(value) ? value : 0;
};
