import { useMutation, useQueryClient } from "@tanstack/react-query";

import { ApiEntity } from "../../../../../_common/api/api-entity";
import { MutationError } from "../../../../../_common/api/errors/mutation-error";
import { deleteOne } from "../../../../../_common/api/methods/delete-one";
import { queryKeys } from "../../../../../_common/api/query-keys/query-keys";
import { useRestClient } from "../../../../../_common/api/rest-client/rest-client-context";
import { ID, Maybe } from "../../../../../_common/types";
import { appShieldingRootPath } from "../../../app-shielding-root-path";
import { ProjectI18nToastKey } from "./_support/project-i18n-toast-key";

interface UseDeleteProjectOptions {
  onSuccess: (() => void) | undefined;
}
export function useDeleteProject(id: Maybe<ID>, { onSuccess }: UseDeleteProjectOptions) {
  const queryClient = useQueryClient();
  const restClient = useRestClient();

  return useMutation<void, MutationError<ID>, ID>({
    mutationKey: queryKeys[appShieldingRootPath].projects().item(id!).delete(),
    mutationFn: (projectId: ID) => deleteOne(restClient, ApiEntity.Project, projectId),
    onSuccess() {
      void queryClient.invalidateQueries({
        queryKey: queryKeys[appShieldingRootPath].projects().item(id!).everything(),
        refetchType: "none",
      });
      void queryClient.invalidateQueries({ queryKey: queryKeys[appShieldingRootPath].projects().lists() });
      onSuccess?.();
    },
    meta: { i18nToastKey: ProjectI18nToastKey.DELETE_PROJECT },
  });
}
