import { ID } from "../../../../../../_common/types";
import { ShielderStatus } from "../../../shielders/types/shielder-status";
import { Version } from "../../../shielders/types/version";

export interface UpdateShieldVersionInput {
  projectUpdatedAt: Date;
  shielderId: ID;
  shielderVersion: Version;
  shielderVersionStatus: ShielderStatus;
}

interface ApiUpdateShieldVersionInput {
  projectUpdatedAt: Date;
  shielderId: number;
  shielderVersion: Version;
}

export const updateShiedVersionInputTransformer = ({
  shielderVersionStatus,
  shielderId,
  ...input
}: UpdateShieldVersionInput): ApiUpdateShieldVersionInput => ({ ...input, shielderId: +shielderId });
