import { Navigate, Route, Routes } from "react-router-dom";

import { AuthGuard } from "../_common/auth/auth-guard";
import { useAuthUserProfile } from "../_common/auth/auth-provider/auth-user-profile-context";
import { CallbackPage } from "../_common/auth/callback-page";
import { ENV } from "../_common/env-vars";
import { NotFound } from "../_common/ui/not-found/not-found";
import { isNotFalsy } from "../_common/utils/is-not-falsy";
import { appShieldingRootPath } from "../products/app-shielding/app-shielding-root-path";
import { AppShieldingRouter } from "../products/app-shielding/app-shielding-router";
import { onPremShieldersRootPath } from "../products/on-prem-shielders/on-prem-shielders-root-path";
import { OnPremShieldersRouter } from "../products/on-prem-shielders/on-prem-shielders-router";
import { settingsRootPath } from "../products/settings/settings-root-path";
import { SettingsRouter } from "../products/settings/settings-router";

export const AppRouter = () => {
  const {
    roles: { hasAppShielding, hasOnPremShielders, hasSettings },
  } = useAuthUserProfile();
  const defaultPath = [
    hasAppShielding && appShieldingRootPath,
    hasOnPremShielders && onPremShieldersRootPath,
    hasSettings && settingsRootPath,
  ].filter(isNotFalsy);

  return (
    <Routes>
      {defaultPath[0] && <Route path="/" element={<Navigate to={defaultPath[0]} />} />}
      <Route path={ENV.COGNITO_CALLBACK_PATH} element={<CallbackPage />} />
      <Route element={<AuthGuard />}>
        {hasAppShielding && <Route path={`${appShieldingRootPath}/*`} element={<AppShieldingRouter />} />}
        {hasOnPremShielders && <Route path={`${onPremShieldersRootPath}/*`} element={<OnPremShieldersRouter />} />}
        {hasSettings && <Route path={`${settingsRootPath}/*`} element={<SettingsRouter />} />}
        <Route path="not-found" element={<NotFound rootPath="/" />} />
        <Route path="*" element={<NotFound rootPath="/" />} />
      </Route>
    </Routes>
  );
};
