import { InputBaseComponentProps, SkeletonProps } from "@mui/material";
import { ChangeEvent, ReactNode } from "react";
import { useController, useFormContext } from "react-hook-form";

import { useHasTranslationKey } from "../../i18n/hooks/use-has-translation-key";
import { useTranslate } from "../../i18n/hooks/use-translate.hook";
import { FormField } from "../form-field/form-field";
import { FormFieldHelperText } from "../form-field/form-field-helper-text";
import { FormFieldLabel } from "../form-field/form-field-label";
import { TextSkeleton } from "../skeleton/text-skeleton";
import { TextInput } from "../text-input/text-input";
import { InputCharacterCounter } from "./input-character-counter";

type FormInputProps = {
  id?: string;
  name: string;
  value?: string;
  label?: ReactNode;
  disabled?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  placeholder?: string;
  helperText?: ReactNode;
  inputProps?: InputBaseComponentProps;
  multiline?: boolean;
  isLoading?: boolean;
  skeletonProps?: SkeletonProps & { "data-testid"?: string };
  maxLength?: number;
  minRows?: number;
};

export type InputStatus = "error" | "success" | "warning";

export function FormInput({
  id,
  label,
  helperText,
  isLoading,
  disabled,
  inputProps,
  maxLength,
  skeletonProps = { width: "100%" },
  value,
  ...props
}: FormInputProps) {
  const t = useTranslate();
  const hasTranslation = useHasTranslationKey();
  const {
    field,
    fieldState: { error },
  } = useController({ name: props.name });
  const {
    clearErrors,
    formState: { isSubmitting },
  } = useFormContext();
  const fieldValue = value ?? (field.value as unknown)?.toString() ?? "";
  const status = error ? "error" : undefined;
  const errorMessage = hasTranslation(error?.message ?? "") ? t(error?.message ?? "") : error?.message;

  return (
    <FormField id={id ?? props.name} data-testid={id && `${id}-textfield`} fullWidth>
      <FormFieldLabel>
        {!isLoading ? label : <TextSkeleton {...skeletonProps} data-testid={id && `${id}-skeleton-loader`} />}
      </FormFieldLabel>
      <TextInput
        {...props}
        {...field}
        value={fieldValue}
        onChange={e => {
          field.onChange(e);
          clearErrors(props.name);
          props.onChange?.(e);
        }}
        disabled={disabled || isLoading || isSubmitting}
        inputProps={{ ...inputProps, maxLength }}
        status={status}
      />
      <FormFieldHelperText
        status={status}
        afterAddon={<InputCharacterCounter value={fieldValue.length} max={maxLength} />}
      >
        {errorMessage ?? helperText}
      </FormFieldHelperText>
    </FormField>
  );
}
