import { z } from "zod";

export const MAX_PROJECT_NAME_LENGTH = 50;
export const MAX_PROJECT_DESCRIPTION_LENGTH = 160;

export const EditProjectSummaryFormSchema = z.object({
  projectName: z
    .string({
      required_error: "validation.required",
    })
    .nonempty("validation.required")
    .trim()
    .nonempty("validation.must-not-contain-only-whitespace")
    .max(MAX_PROJECT_NAME_LENGTH),
  projectDescription: z.string().trim().max(MAX_PROJECT_DESCRIPTION_LENGTH).optional(),
});

export type EditProjectSummaryFormSchema = z.infer<typeof EditProjectSummaryFormSchema>;
