import dayjs from "dayjs";
import { useEffect } from "react";

import { DateTimeFormat, useDateTime } from "../i18n/hooks/use-date-time.hook";
import { usePrevious } from "./use-previous";
import { useRerenderWithInterval } from "./use-rerender-with-interval";

type CountdownTimerProps = {
  expiryDate?: Date | string;
  onExpiry?: () => void;
};

const REFRESH_INTERVAL = 1000;
const noop = () => {};

export const useCountdownTimer = ({ expiryDate = "", onExpiry = noop }: CountdownTimerProps): string => {
  const dateFormat = useDateTime();
  const isExpired = dayjs(expiryDate).isBefore(dayjs());

  useRerenderWithInterval({
    enabled: !isExpired,
    refreshInterval: REFRESH_INTERVAL,
  });

  const prevIsExpired = usePrevious(isExpired);
  useEffect(() => {
    if (!prevIsExpired && isExpired) {
      onExpiry();
    }
  }, [isExpired, onExpiry, prevIsExpired]);

  return dateFormat(expiryDate, DateTimeFormat.timeDifferenceHHMMSS);
};
