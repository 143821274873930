import { alpha } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useLinkStyles = makeStyles()(theme => ({
  root: {
    color: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
    "&:hover": {
      background: "transparent",
      textDecoration: "none",
    },
    "&:active, &:active:focus": {
      boxShadow: "none",
      outline: "none",
      background: alpha(theme.palette.primary.main, 0.18),
    },
    "&:focus": {
      outline: `solid 3px ${theme.palette.primary.main}`,
      textDecoration: "underline",
      "&:not(:focus-visible)": {
        borderColor: "none",
        outline: "none",
      },
    },
  },
}));
