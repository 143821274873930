import { useEffect, useRef } from "react";

import { i18n } from "../../../../../_common/i18n/i18n";
import { showToast } from "../../../../../_common/ui/toast";
import { ApiShieldingStatus } from "./types";

export const useShowToastOnShieldingSuccess = ({
  initialShieldingStatus,
  currentShieldingStatus,
}: {
  initialShieldingStatus?: ApiShieldingStatus;
  currentShieldingStatus?: ApiShieldingStatus;
}) => {
  const prevShieldingStatus = useRef<ApiShieldingStatus | undefined>(initialShieldingStatus);

  useEffect(() => {
    if (
      prevShieldingStatus.current === ApiShieldingStatus.InProgress &&
      currentShieldingStatus === ApiShieldingStatus.Success
    ) {
      showToast(
        "success",
        i18n.intl.formatMessage({ id: `component.shielding.error-alert.${ApiShieldingStatus.Success}.title` }),
      );
    }

    // When the query goes from inProgress to success the status becomes undefined
    // To make sure the check above works we only update the prev status if it has a value.
    if (currentShieldingStatus) {
      prevShieldingStatus.current = currentShieldingStatus;
    }
  }, [currentShieldingStatus]);
};
