import { I18nToastKey } from "./i18n-toast-key";

export const enum ApiCallType {
  Query = "query",
  Mutation = "mutation",
}

export const enum ApiCallResult {
  Success = "success",
  SuccessMessage = "successMessage",
  SuccessLink = "successLink",
  UnknownError = "unknown",
}

export const getApiCallToastI18nKey = (
  apiCallType: ApiCallType,
  result: number | string | ApiCallResult,
  i18nToastKey?: I18nToastKey,
) => `toast.${apiCallType}.${i18nToastKey ?? "generic"}.${result}`;
