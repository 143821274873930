import { Fragment } from "react";

interface MultilineProps {
  value: string;
}

export const Multiline = ({ value }: MultilineProps) => {
  const lines = value.split("\n");
  return (
    <>
      {lines.map((line, i) => (
        <Fragment key={i}>
          {Boolean(i) && <br />}
          {line}
        </Fragment>
      ))}
    </>
  );
};
