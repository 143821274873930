import { makeStyles } from "tss-react/mui";

import { useTranslate } from "../../../../_common/i18n/hooks/use-translate.hook";
import { CopyToClipboardButton } from "../../../../_common/ui/copy-to-clipboard-button/copy-to-clipboard-button";
import { FullWidthSkeleton } from "../../../../_common/ui/skeleton/full-width-skeleton";

interface TextForClipboardProps {
  isLoading: boolean;
  text: string | null;
  onClick: () => void;
}
export const TextForClipboardBlock = ({ isLoading, text, onClick }: TextForClipboardProps) => {
  const t = useTranslate();
  const { classes } = useStyles();

  return (
    <div className={classes.keyContent}>
      {isLoading ? (
        <FullWidthSkeleton />
      ) : text === null ? (
        t("word.not-available.short")
      ) : (
        <>
          {text}
          <CopyToClipboardButton text={text} variant="contained" color="default" onClick={onClick} />
        </>
      )}
    </div>
  );
};

const useStyles = makeStyles()(theme => ({
  keyContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    wordBreak: "break-all",
    gap: theme.spacing(1),
    minHeight: "34px",
  },
}));
