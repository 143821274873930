import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { ApiEntity } from "../../../../../_common/api/api-entity";
import { getMany } from "../../../../../_common/api/methods/get-many";
import { queryKeys } from "../../../../../_common/api/query-keys/query-keys";
import { useRestClient } from "../../../../../_common/api/rest-client/rest-client-context";
import { DateTime } from "../../../../../_common/types";
import { appShieldingRootPath } from "../../../app-shielding-root-path";
import { GetProject } from "../../projects/api/get-project";
import { Platform } from "../../shielders/types/platform";
import { ShielderStatus } from "../../shielders/types/shielder-status";
import { Version } from "../../shielders/types/version";
import { ShieldingSource } from "../types";
import { ShieldingI18nToastKey } from "./_support/shielding-i18n-toast-key";
import { ApiShieldingStatus } from "./types";

export const ApiGetShieldingsListItem = z.object({
  shieldingId: z.string(),
  shieldingStatus: z.nativeEnum(ApiShieldingStatus),
  shieldingStartedAt: DateTime,
  shieldingUpdatedAt: DateTime,
  shielderId: z.number(),
  shielderPlatform: z.nativeEnum(Platform),
  shielderVersion: Version,
  shielderVersionStatus: z.nativeEnum(ShielderStatus),
  testMode: z.boolean(),
  shieldingInputFileName: z.string(),
  shieldingOutputPackageName: z.string().optional(),
  shieldingExpiryTime: DateTime.optional(),
  shieldingSource: z.nativeEnum(ShieldingSource),
  shieldingOutputPackageSize: z.number().int().positive().optional(),
});
export type ApiGetShieldingsListItem = z.input<typeof ApiGetShieldingsListItem>;

export function getShieldingsListItemTransformer<T extends z.infer<typeof ApiGetShieldingsListItem>>(
  { shieldingId, shielderVersionStatus, testMode, shielderId, ...shielding }: T,
  ctx: z.RefinementCtx,
) {
  if (shielding.shieldingStatus === ApiShieldingStatus.Success) {
    const message = "is missing";

    if (!("shieldingOutputPackageName" in shielding)) {
      ctx.addIssue({ code: z.ZodIssueCode.custom, message, path: ["shieldingOutputPackageName"] });
    }
    if (!("shieldingExpiryTime" in shielding)) {
      ctx.addIssue({ code: z.ZodIssueCode.custom, message, path: ["shieldingExpiryTime"] });
    }
    if (!("shieldingOutputPackageSize" in shielding)) {
      ctx.addIssue({ code: z.ZodIssueCode.custom, message, path: ["shieldingOutputPackageSize"] });
    }
  }

  return {
    ...shielding,
    id: shieldingId,
    shielderId: shielderId.toString(),
    shielderStatus: shielderVersionStatus,
    debugMode: testMode,
  };
}

export const GetShieldingsListItem = ApiGetShieldingsListItem.transform(getShieldingsListItemTransformer);
export type GetShieldingsListItem = z.infer<typeof GetShieldingsListItem>;

const PAGE_LIMIT = 25;

interface UseGetShieldingsList {
  project: GetProject | undefined;
  page: number;
}

export const useGetShieldingsList = ({ project, page }: UseGetShieldingsList) => {
  const restClient = useRestClient();

  return useQuery({
    queryKey: queryKeys[appShieldingRootPath]
      .projects()
      .item(project?.id ?? "")
      .shieldings()
      .lists(),
    queryFn: async () => getMany(restClient, ApiEntity.Shielding, { projectId: project!.id }, GetShieldingsListItem),
    enabled: Boolean(project),
    meta: { i18nToastKey: ShieldingI18nToastKey.GET_SHIELDINGS_LIST, resourceName: project?.projectName },
    select: (data: { data: GetShieldingsListItem[] }): { shieldings: GetShieldingsListItem[]; totalPages: number } => {
      const items = data?.data || [];
      const currentIndex = (page - 1) * PAGE_LIMIT;

      // paginating and sorting by started date
      return {
        shieldings: items
          .sort((a, b) => new Date(b.shieldingStartedAt).valueOf() - new Date(a.shieldingStartedAt).valueOf())
          .slice(currentIndex, currentIndex + PAGE_LIMIT),
        totalPages: items.length ? Math.ceil(items.length / PAGE_LIMIT) : 1,
      };
    },
  });
};
