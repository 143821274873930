import { alpha, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { Fragment, KeyboardEvent, ReactNode } from "react";
import { makeStyles } from "tss-react/mui";

import { ID } from "../../../types";
import { TableHeader } from "./table-header";
import { DataTableProps } from "./types";

export const DEFAULT_LOADING_ROW_COUNT = 6;

export function DataTable<
  TApiSortColumn extends string,
  TUrlSortColumn extends string,
  TData extends { id: ID; expandedRow?: ReactNode },
  TContext,
>({
  loadingRowCount = DEFAULT_LOADING_ROW_COUNT,
  context,
  isLoading = false,
  isHeadersLoading = false,
  columns,
  defaultSortColumn,
  onClickRow,
  sortingParamNames,
  data = [],
  caption,
  noPaperContainer,
  ...tableProps
}: DataTableProps<TApiSortColumn, TUrlSortColumn, TData, TContext>) {
  const { classes } = useStyles({ isLoading, noPaperContainer, isRowClickEnabled: Boolean(onClickRow) });
  const collection = isLoading ? Array.from(Array(loadingRowCount), (_, i) => ({ id: i.toString() }) as TData) : data;

  const handleKeyDown = (row: TData) => (event: KeyboardEvent) => {
    if ((event.key === "Enter" || event.key === " ") && onClickRow && !isLoading) {
      onClickRow(row);
    }
  };

  return (
    <TableContainer className={classes.tableContainer}>
      <Table {...tableProps}>
        {caption && <caption className={classes.tableCaption}>{caption}</caption>}
        <TableHeader
          isDataLoading={isLoading}
          isHeadersLoading={isHeadersLoading}
          columns={columns}
          defaultSortColumn={defaultSortColumn}
          sortingParamNames={sortingParamNames}
        />
        <TableBody>
          {collection.map(row => (
            <Fragment key={row.id}>
              <TableRow
                key={row.id}
                className={classes.tableRow}
                onClick={onClickRow && !isLoading ? () => onClickRow(row) : undefined}
                onKeyDown={handleKeyDown(row)}
                tabIndex={0}
              >
                {columns.map(({ key, component: Component }) => (
                  <TableCell className={classes.tableCell} key={key}>
                    <Component {...row} isLoading={isLoading} context={context} />
                  </TableCell>
                ))}
              </TableRow>
              {row.expandedRow && (
                <TableRow className={classes.tableRowExpanded}>
                  <TableCell className={classes.tableCellExpanded} colSpan={columns.length}>
                    {row.expandedRow}
                  </TableCell>
                </TableRow>
              )}
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const loadingFadingOut = Object.fromEntries(
  Array.from({ length: DEFAULT_LOADING_ROW_COUNT - 1 }).map((_, i, arr) => [
    `&:nth-of-type(${2 + i})`,
    { opacity: (1.0 / (arr.length + 1)) * (arr.length - i) },
  ]),
);

const useStyles = makeStyles<{
  isLoading: boolean;
  isRowClickEnabled: boolean;
  noPaperContainer: boolean | undefined;
}>()((theme, { isLoading, isRowClickEnabled, noPaperContainer }) => ({
  tableContainer: {
    backgroundColor: theme.palette.background.paper,
    paddingBottom: 1,
    boxShadow: noPaperContainer ? "none" : theme.shadows[1],
    "&.MuiTableContainer-root": {
      boxSizing: "content-box",
    },
    overflowX: "visible",
    position: "relative",
  },
  tableRow: {
    width: "100%",
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    borderTop: `1px solid ${alpha(theme.palette.common.black, 0.1)}`,
    ...(isLoading
      ? loadingFadingOut
      : isRowClickEnabled
        ? {
            "&:hover": {
              cursor: "pointer",
              backgroundColor: alpha(theme.palette.common.black, 0.05),
            },
            "&:focus-visible": {
              outline: `solid 2px ${theme.palette.primary.main}`,
              transform: "scale(1)",
            },
          }
        : {}),
  },
  tableCell: {
    padding: theme.spacing(2.5),
    borderBottom: "none",
    "& .MuiTypography-root": {
      lineHeight: theme.typography.pxToRem(24),
    },
  },
  tableRowExpanded: {
    borderTop: `1px solid ${alpha(theme.palette.common.black, 0.1)}`,
    padding: theme.spacing(2.5),
    borderBottom: "none",
    "& .MuiTypography-root": {
      lineHeight: theme.typography.pxToRem(24),
    },
  },
  tableCellExpanded: {
    padding: theme.spacing(2.5),
    borderBottom: "none",
  },
  tableCaption: {
    fontSize: "0!important",
    padding: "0!important",
  },
}));

DataTable.DEFAULT_LOADING_ROW_COUNT = DEFAULT_LOADING_ROW_COUNT;
