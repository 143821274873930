import { ID } from "../../../../../../_common/types";

export interface DuplicateProjectInput {
  projectName: string;
  projectDescription: string;
}

export function duplicateProjectInputTransformer(
  sourceProjectId: ID,
  input: DuplicateProjectInput,
): ApiDuplicateProjectInput {
  return { copyProjectId: +sourceProjectId, ...input };
}

export interface ApiDuplicateProjectInput {
  projectName: string;
  projectDescription: string;
  copyProjectId: number;
}
