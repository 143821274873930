import { Button } from "@mui/material";

import { addAction, AppShieldingActions, AppShieldingFeatures } from "../../../../../../../_common/datadog";
import { useTranslate } from "../../../../../../../_common/i18n/hooks/use-translate.hook";
import { ID } from "../../../../../../../_common/types";
import { ButtonProgress } from "../../../../../../../_common/ui/button-progress/button-progress";
import { useDownloadSdk } from "../../../../shielders/api/use-download-sdk";
import { Platform } from "../../../../shielders/types/platform";
import { ShielderStatus } from "../../../../shielders/types/shielder-status";
import { Version } from "../../../../shielders/types/version";

type DownloadSdkButtonProps = {
  className: string;
  shielder: {
    shielderId: ID;
    shielderPlatform: Platform;
    shielderVersion: Version;
    shielderStatus: ShielderStatus;
  };
};

export const DownloadSdkButton = ({ className, shielder }: DownloadSdkButtonProps) => {
  const t = useTranslate();

  const { mutateAsync: downloadSDK, isPending: isDownloading } = useDownloadSdk(shielder);

  const handleClick = () => {
    addAction(AppShieldingActions.DownloadShielderSdk, AppShieldingFeatures.ProjectsDetails);
    void downloadSDK(shielder.shielderId);
  };

  const hasDownloadSdkUrl =
    shielder.shielderStatus !== ShielderStatus.Unsupported && shielder.shielderStatus !== ShielderStatus.Deleted;

  return hasDownloadSdkUrl ? (
    <Button
      variant="text"
      color="primary"
      size="small"
      className={className}
      startIcon={isDownloading ? <ButtonProgress role="progressbar" size={12} /> : undefined}
      onClick={!isDownloading ? handleClick : undefined}
    >
      {t("component.basic-info.download-sdk.button")}
    </Button>
  ) : null;
};
