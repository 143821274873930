import { useMutation, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

import { ApiEntity } from "../../../../../_common/api/api-entity";
import { MutationError } from "../../../../../_common/api/errors/mutation-error";
import { postOne } from "../../../../../_common/api/methods/post-one";
import { queryKeys } from "../../../../../_common/api/query-keys/query-keys";
import { useRestClient } from "../../../../../_common/api/rest-client/rest-client-context";
import { ClientCredentialsI18nToastKey } from "./_support/client-credentials-i18n-toast-key";
import {
  ApiGetClientCredentials,
  GetClientCredentials,
  getClientCredentialsTransformer,
} from "./get-client-credentials";

export const ApiCreateClientCredentials = ApiGetClientCredentials;
export type ApiCreateClientCredentials = z.input<typeof ApiCreateClientCredentials>;

const createClientCredentialsTransformer = getClientCredentialsTransformer;

const CreateClientCredentials = ApiCreateClientCredentials.transform(createClientCredentialsTransformer);
type CreateClientCredentials = z.infer<typeof CreateClientCredentials>;

export function useCreateClientCredentials() {
  const queryClient = useQueryClient();
  const restClient = useRestClient();

  return useMutation<void, MutationError<void>, void>({
    mutationKey: queryKeys.settings.clientCredentials().create(),
    mutationFn: () =>
      postOne(restClient, ApiEntity.ClientCredentials, undefined, CreateClientCredentials).then(data => {
        queryClient.setQueryData<GetClientCredentials>(queryKeys.settings.clientCredentials().item().details(), data);
      }),
    meta: { i18nToastKey: ClientCredentialsI18nToastKey.DELETE },
  });
}
