import { InputBase, InputBaseClassKey, InputBaseProps } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { forwardRef, RefObject } from "react";
import { tss } from "tss-react/mui";

import { useFormFieldContext } from "../form-field/form-field-context";
import { InputStatus } from "../form-input/form-input";
import { getBorderColor } from "../utils/get-border-color";

type TextInputClassKey = "statusPrimary" | "statusError" | "statusSuccess" | "statusWarning";
type TextInputClasses = {
  [K in InputBaseClassKey | TextInputClassKey]?: string;
};

export interface TextInputProps extends Omit<InputBaseProps, "classes"> {
  classes?: TextInputClasses;
  status?: InputStatus | "primary";
  ref?: RefObject<HTMLInputElement>;
  notched?: boolean;
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      "aria-describedby": ariaDescribedByProp,
      classes = {},
      className,
      disabled,
      fullWidth: fullWidthProp,
      id: idProp,
      multiline,
      status: statusProp,
      ...props
    },
    ref,
  ) => {
    const fieldContext = useFormFieldContext();
    const ariaDescribedBy = fieldContext?.helperTextId || ariaDescribedByProp;
    const id = fieldContext?.id || idProp;
    const fullWidth = fieldContext?.fullWidth || fullWidthProp;
    const status = fieldContext?.status || statusProp || undefined;
    const { classes: internalClasses, cx } = useStyles({ status: status || "primary" });

    const { root: rootClass, ...otherClasses } = classes;
    const { notched: _, ...rest } = props;

    return (
      <InputBase
        aria-describedby={ariaDescribedBy}
        classes={{
          root: cx(className, rootClass, internalClasses.root),
          ...otherClasses,
        }}
        disabled={disabled}
        id={id}
        fullWidth={fullWidth}
        multiline={multiline}
        ref={ref}
        {...rest}
      />
    );
  },
);

TextInput.displayName = "TextInput";

const useStyles = tss.withParams<{ status: InputStatus | "primary" }>().create(({ theme, status }) => ({
  root: {
    border: `solid 1px ${getBorderColor({ theme, status })}`,
    "&:hover": {
      border: `1px solid ${theme.palette[status].main}`,
      backgroundColor: alpha(theme.palette[status].main, 0.08),
    },
    "&.Mui-disabled": {
      border: `1px solid ${alpha(theme.palette[status].main, 0.5)}`,
      "&:hover": {
        border: `1px solid ${alpha(theme.palette[status].main, 0.5)}`,
      },
    },
    "&.Mui-focused": {
      border: `1px solid ${theme.palette[status].main}`,
      outline: `1px solid ${theme.palette.primary.main}`,
      "&:active": {
        border: `1px solid ${theme.palette[status].main}`,
        outline: `none`,
      },
    },
  },
}));
