import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import { makeStyles } from "tss-react/mui";

import { addAction, AppShieldingActions, AppShieldingFeatures } from "../../../../_common/datadog";
import { ENV } from "../../../../_common/env-vars";
import { useTranslate } from "../../../../_common/i18n/hooks/use-translate.hook";
import { CodeBlock } from "../../../../_common/ui/code-block/code-block";
import { CopyToClipboardButton } from "../../../../_common/ui/copy-to-clipboard-button/copy-to-clipboard-button";
import { TextSkeleton } from "../../../../_common/ui/skeleton/text-skeleton";
import { GetProject } from "../projects/api/get-project";
import { useGetAccessApiKey } from "./api/get-access-api-key/get-access-api-key";
import { RevokeAccessApiKeyDialog } from "./components/revoke-access-api-key-dialog";
import { GenerateAccessApiKeyButton } from "./generate-access-api-key-button";
import { ApiAccessDocumentationButton } from "./ui/api-access-documentation-button";

interface ApiAccessPanelProps {
  project?: GetProject;
  isLoading?: boolean;
}

export function ApiAccessPanel({ project, isLoading }: ApiAccessPanelProps) {
  const { classes } = useStyles();
  const t = useTranslate();
  const [openRevokeDialog, setOpenRevokeDialog] = useState(false);

  const { data: accessApiKey, isLoading: isLoadingAccessApiKey } = useGetAccessApiKey({
    projectId: project?.id,
    apiKeyId: project?.apiKeyId,
    enabled: Boolean(!isLoading && project?.apiKeyId),
  });

  return (
    <section aria-label={t("component.api-access.title")}>
      <Accordion slotProps={{ heading: { component: "h2" } }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.title}>
          <strong>{t("component.api-access.title")}</strong>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <Box display="flex" flexDirection="column" gap="20px">
            <Typography className={classes.description}>{t("component.api-access.description")}</Typography>
            {isLoadingAccessApiKey ? (
              <CodeBlock>
                <TextSkeleton />
              </CodeBlock>
            ) : project?.apiKeyId && accessApiKey?.apiKey ? (
              <Box display="flex" gap="8px">
                <CodeBlock className={classes.codeBlock}>{accessApiKey?.apiKey}</CodeBlock>
                <CopyToClipboardButton
                  text={accessApiKey?.apiKey}
                  onClick={() => {
                    addAction(
                      AppShieldingActions.CopyConfigurationKeyToClipboard,
                      AppShieldingFeatures.ProjectsDetails,
                    );
                  }}
                />
              </Box>
            ) : null}
            {!isLoading && (
              <Box className={classes.buttonContainer}>
                {!project?.apiKeyId ? (
                  <GenerateAccessApiKeyButton project={project} />
                ) : (
                  <>
                    <Button
                      onClick={() => {
                        addAction(AppShieldingActions.OpenRevokeConfigurationKey, AppShieldingFeatures.ProjectsDetails);
                        setOpenRevokeDialog(true);
                      }}
                      variant="outlined"
                      size="small"
                      color="error"
                    >
                      {t("component.api-access.revoke-api-key.button")}
                    </Button>
                  </>
                )}
                {ENV.API_DOC_URL && (
                  <ApiAccessDocumentationButton
                    size="small"
                    onClick={() => {
                      addAction(AppShieldingActions.GoToApiDocumentation, AppShieldingFeatures.ProjectsDetails);
                    }}
                  />
                )}
              </Box>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>

      <RevokeAccessApiKeyDialog
        open={openRevokeDialog}
        project={project}
        onCancel={() => setOpenRevokeDialog(false)}
        title={t("component.api-access.revoke-dialog-confirm.title")}
        content={t("component.api-access.revoke-dialog-confirm.message")}
      />
    </section>
  );
}

const useStyles = makeStyles()(theme => ({
  buttonContainer: {
    display: "flex",
    gap: theme.spacing(3.5),
    alignItems: "center",
  },
  description: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
  },
  downloadSDKButton: {
    width: "100%",
    textDecoration: "none",
    marginLeft: theme.spacing(1),
    padding: theme.spacing(1.2, 2.2),
  },
  link: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 400,
    lineHeight: theme.spacing(2.5),
    color: theme.palette.primary.main + "!important",
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
  },
  title: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(28),
    margin: 0,
  },
  codeBlock: {
    fontSize: theme.typography.pxToRem(12),
  },
  accordionDetails: {
    paddingTop: "0!important",
  },
}));
