import { i18n } from "../../../../../../../../../_common/i18n/i18n";

export function validationHelperText<T extends string | number>(
  validation: { value: T; helperText?: string } | undefined,
  validationTranslationKey?: string,
): string | undefined {
  if (!validation) return undefined;

  return (
    validation.helperText ??
    (validationTranslationKey
      ? i18n.intl.formatMessage(
          { id: `component.configuration-form.item.validation.${validationTranslationKey}.helperText` },
          { value: validation.value },
        )
      : undefined)
  );
}
