import { Box, CircularProgress, Typography } from "@mui/material";
import { useEffect } from "react";
import { unstable_usePrompt } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { useTranslate } from "../../i18n/hooks/use-translate.hook";

export function UploadingState() {
  const t = useTranslate();
  const { classes } = useStyles();

  unstable_usePrompt({
    message: t("component.drop-zone.navigate-away"),
    when: ({ currentLocation, nextLocation }) => currentLocation.pathname !== nextLocation.pathname,
  });

  useEffect(() => {
    function showNavigationAlert(e: BeforeUnloadEvent) {
      if (!confirm(t("component.drop-zone.navigate-away"))) {
        e.preventDefault();
      }
    }

    window.addEventListener("beforeunload", showNavigationAlert);
    return () => {
      window.removeEventListener("beforeunload", showNavigationAlert);
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <CircularProgress size={20} />
      <Typography className={classes.loadingMessage}>{t("word.uploading")}...</Typography>
    </Box>
  );
}

const useStyles = makeStyles()(theme => ({
  loadingMessage: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 400,
  },
}));
