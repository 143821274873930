import { alpha, Theme, ThemeOptions } from "@mui/material";

export const listItemButtonTheme = (theme: Theme): ThemeOptions["components"] => ({
  MuiListItemButton: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        "&:hover": {
          backgroundColor: alpha(theme.palette.common.black, 0.05),
        },
        "&:focus": {
          outline: "none",
          boxShadow: `inset 0 0 0 2px ${theme.palette.primary.main}`,
          backgroundColor: "initial",
          "&:not(:focus-visible)": {
            boxShadow: "none",
          },
        },
        "&:focus:hover": {
          backgroundColor: alpha(theme.palette.common.black, 0.05),
        },
        "&:active": {
          backgroundColor: alpha(theme.palette.common.black, 0.1),
          "&:focus:hover": {
            backgroundColor: alpha(theme.palette.common.black, 0.1),
          },
        },
        "&.Mui-selected": {
          borderLeft: `solid 2px ${theme.palette.primary.main}`,
          background: "none",
          "&:hover, &:focus:hover": {
            backgroundColor: alpha(theme.palette.primary.main, 0.05),
          },
          "&:focus": {
            borderLeft: "none",
            background: "none",
            "&:not(:focus-visible)": {
              borderLeft: `solid 2px ${theme.palette.primary.main}`,
            },
          },
        },
        "& .MuiAvatar-root": {
          backgroundColor: theme.palette.grey[600],
          width: 24,
          height: 24,
        },
      },
    },
  },
});
