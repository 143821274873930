import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { ApiEntity } from "../../../../../_common/api/api-entity";
import { getOne } from "../../../../../_common/api/methods/get-one";
import { queryKeys } from "../../../../../_common/api/query-keys/query-keys";
import { useRestClient } from "../../../../../_common/api/rest-client/rest-client-context";
import { ID } from "../../../../../_common/types";
import { appShieldingRootPath } from "../../../app-shielding-root-path";
import { ConfigStatus } from "../../configurations/api/types/config-status";
import { Platform } from "../../shielders/types/platform";
import { ShielderStatus } from "../../shielders/types/shielder-status";
import { Version } from "../../shielders/types/version";
import { ProjectI18nToastKey } from "./_support/project-i18n-toast-key";
import { ApiUpdateProject, updateProjectTransformer } from "./update-project/update-project";

export const ApiGetProject = ApiUpdateProject.extend({
  projectName: z.string(),
  projectDescription: z.string(),

  shielderId: z.number(),
  shielderPlatform: z.nativeEnum(Platform),
  shielderVersion: Version,
  shielderVersionStatus: z.nativeEnum(ShielderStatus),

  configId: z.number(),
  configStatus: z.nativeEnum(ConfigStatus),

  testMode: z.boolean(),
  apiKeyId: z.number().optional(),
});
export type ApiGetProject = z.input<typeof ApiGetProject>;

export function getProjectTransformer<T extends z.infer<typeof ApiGetProject>>({
  shielderVersionStatus,
  testMode,
  shielderId,
  configId,
  apiKeyId,
  ...value
}: T) {
  return {
    shielderStatus: shielderVersionStatus,
    debugMode: testMode,
    shielderId: shielderId.toString(),
    configId: configId.toString(),
    apiKeyId: apiKeyId?.toString(),
    ...updateProjectTransformer(value),
  };
}

export const GetProject = ApiGetProject.transform(getProjectTransformer);
export type GetProject = z.infer<typeof GetProject>;

export function useGetProject(projectId: ID) {
  const restClient = useRestClient();

  return useQuery({
    queryKey: queryKeys[appShieldingRootPath].projects().item(projectId).details(),
    queryFn: () => getOne(restClient, ApiEntity.Project, projectId, GetProject),
    meta: { i18nToastKey: ProjectI18nToastKey.GET_PROJECT },
  });
}
