import { ComponentProps } from "react";

export const Logo = (props: ComponentProps<"svg">) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
    <path
      d="M1.96001 16C1.96001 14.1157 2.31013 12.3651 3.00401 10.7354C3.6979 9.11212 4.66551 7.69252 5.89413 6.483C7.12912 5.27348 8.59964 4.32496 10.3184 3.64381C12.0309 2.96266 13.9215 2.6189 15.9841 2.6189C18.0212 2.6189 19.9055 2.96266 21.6306 3.64381C23.3558 4.32496 24.8391 5.27348 26.0868 6.483C27.3281 7.69252 28.3021 9.11212 28.996 10.7354C29.6899 12.3587 30.04 14.1157 30.04 16C30.04 17.8843 29.6899 19.635 28.996 21.2646C28.3021 22.8879 27.3281 24.3075 26.0868 25.5171C24.8454 26.7266 23.3558 27.6751 21.6306 28.3562C19.9055 29.0374 18.0212 29.3812 15.9841 29.3812C13.9215 29.3812 12.0309 29.0374 10.3184 28.3562C8.60601 27.6751 7.12912 26.7266 5.89413 25.5171C4.65915 24.3075 3.6979 22.8879 3.00401 21.2646C2.30377 19.635 1.96001 17.8843 1.96001 16ZM8.84791 16C8.84791 17.0058 9.03889 17.9416 9.42084 18.7883C9.8028 19.6413 10.3184 20.3798 10.9678 21.01C11.6171 21.6402 12.3746 22.124 13.2404 22.4742C14.1061 22.8243 15.0165 22.9962 15.9777 22.9962C16.939 22.9962 17.8557 22.8243 18.7151 22.4742C19.5808 22.124 20.3384 21.6402 21.0004 21.01C21.6625 20.3798 22.1845 19.6413 22.5664 18.7883C22.9484 17.9353 23.1394 17.0058 23.1394 16C23.1394 14.9942 22.9484 14.0584 22.5664 13.2118C22.1845 12.3587 21.6625 11.6203 21.0004 10.9901C20.3384 10.3598 19.5745 9.87603 18.7151 9.5259C17.8493 9.17578 16.939 9.0039 15.9777 9.0039C15.0165 9.0039 14.0998 9.17578 13.2404 9.5259C12.3746 9.87603 11.6171 10.3598 10.9678 10.9901C10.3184 11.6203 9.8028 12.3587 9.42084 13.2118C9.03889 14.0584 8.84791 14.9878 8.84791 16Z"
      fill="#096271"
    />
  </svg>
);
