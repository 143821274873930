import { ThemeOptions } from "@mui/material/styles";

export const switchTheme = (): ThemeOptions["components"] => ({
  MuiSwitch: {
    styleOverrides: {
      colorPrimary: {
        "&.Mui-checked": {
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.15)",
          },
        },
        "&:hover, &:focus, &.Mui-focusVisible": {
          backgroundColor: "rgba(0, 0, 0, 0.15)",
        },
      },
    },
  },
});
