import { AxiosInstance } from "axios";
import { z, ZodTypeDef } from "zod";

import { searchParams } from "../../utils/search-params";
import { ApiEntity } from "../api-entity";
import { getRequest } from "./utils/get-request";

export function listData<TItem extends z.ZodTypeAny>(item: TItem) {
  return z.object({
    data: z.array(item),
  });
}

// export type ListData = z.infer<ReturnType<typeof listData>>
export interface ListData<TItem> {
  data: TItem[];
}

export function getMany<
  TItem,
  TApiItem,
  TSearchParams extends Record<string, string | number>,
  Def extends ZodTypeDef = ZodTypeDef,
>(restClient: AxiosInstance, apiEntity: ApiEntity, params: TSearchParams, itemType: z.ZodType<TItem, Def, TApiItem>) {
  return getRequest(restClient, `${apiEntity}${searchParams(params)}`, listData(itemType));
}
