import { ApiDateTime, ID } from "../../../../../../_common/types";
import { stringifyDateTime } from "../../../../../../_common/utils/date-time";
import { GetProject } from "../get-project";

export interface UpdateProjectInput {
  id: ID;

  projectName: string;
  projectDescription: string;

  projectUpdatedAt: Date;
}

export function updateProjectInputTransformer(
  input: Omit<UpdateProjectInput, "id">,
  oldProject: GetProject,
): ApiUpdateProjectInput {
  return {
    ...(input.projectName !== oldProject.projectName ? { projectName: input.projectName } : {}),
    ...(input.projectDescription !== oldProject.projectDescription
      ? { projectDescription: input.projectDescription }
      : {}),
    projectUpdatedAt: stringifyDateTime(input.projectUpdatedAt),
  };
}

export interface ApiUpdateProjectInput {
  projectName?: string;
  projectDescription?: string;

  projectUpdatedAt: ApiDateTime;
}
