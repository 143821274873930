import { Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { Multiline } from "../../../../../_common/ui/multiline/multiline";

interface ItemReadOnlyValueProps {
  value: string;
  uppercase?: boolean;
}

export function ItemReadOnlyValue({ value, uppercase }: ItemReadOnlyValueProps) {
  const { classes } = useStyles({ uppercase });
  return <Typography className={classes.value}>{value ? <Multiline value={value} /> : "None"}</Typography>;
}

const useStyles = makeStyles<{ uppercase?: boolean }>()((theme, { uppercase }) => ({
  value: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
    maxWidth: "100%",
    overflow: "hidden",
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
    textTransform: uppercase ? "uppercase" : undefined,
  },
}));
