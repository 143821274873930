import { useState } from "react";
import { z } from "zod";

import { Alert } from "../../../../../../_common/ui/alert/alert";
import { bannersData } from "./banners-data";

const BANNERS_KEY = "AppShieldingBanners";

const CollapsedState = z
  .record(z.string(), z.boolean())
  .transform(collapsedBanners =>
    Object.fromEntries(
      Object.entries(collapsedBanners).filter(([k, _]) => bannersData.some(banner => banner.title === k)),
    ),
  );

type CollapsedState = z.infer<typeof CollapsedState>;

function getInitialCollapsedState(): CollapsedState {
  const record = localStorage.getItem(BANNERS_KEY);
  let collapsed = {};

  if (record) {
    try {
      collapsed = CollapsedState.parse(JSON.parse(record));
    } catch (e) {
      localStorage.removeItem(BANNERS_KEY);
      return {};
    }
  }

  return collapsed;
}

export const Banners = () => {
  const [collapsed, setCollapsed] = useState(getInitialCollapsedState);

  return bannersData.map(({ type, title, description }) => (
    <Alert
      key={title}
      type={type}
      title={title}
      description={description}
      onCollapse={() => {
        setCollapsed(collapsed => {
          const newState = {
            ...collapsed,
            [title]: !collapsed[title],
          };

          localStorage.setItem(BANNERS_KEY, JSON.stringify(newState));

          return newState;
        });
      }}
      collapsed={collapsed[title]}
    />
  ));
};
