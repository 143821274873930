import { Close as CloseIcon } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";

import { useTranslate } from "../../i18n/hooks/use-translate.hook";

export const CloseButton = () => {
  const t = useTranslate();
  return (
    <Box
      alignSelf="center"
      aria-label={t("toast.close-button")}
      boxSizing="border-box"
      color="inherit"
      component={IconButton}
      display="inline-flex"
      marginLeft={1}
      marginRight={2}
    >
      <CloseIcon />
    </Box>
  );
};
