import { DataType } from "@mob/shielder-metadata";
import { z } from "zod";

import { FileCertificate } from "../../api/types/file-certificate";

export const TYPES: Record<DataType, z.ZodTypeAny> = {
  [DataType.String]: z.string(),
  [DataType.Integer]: z.union([z.number().nullable(), z.string()]),
  // number comes from the server, it becomes a string when
  // it is changed by user in the UI
  [DataType.File]: z.union([z.instanceof(File).nullable(), FileCertificate]),
  [DataType.Boolean]: z.union([z.boolean().nullable(), z.string()]),
};
