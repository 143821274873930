import { ConfigurationItem as ConfigurationItemType, Widget } from "@mob/shielder-metadata";
import { z } from "zod";

import { ConfigValues } from "../../api/types/config-values";
import { conditionalParameterValidationMessage } from "../utils/conditional-parameter-validation-message";
import { TYPES } from "../utils/data-type-zod-types";
import { conditionallyAllowedValues, isAnyConditionValid, validate } from "../utils/validators";

type GenerateConfigurationResponseSchemaParams = {
  strip: boolean;
};
export function generateConfigurationResponseSchema(
  items: ConfigurationItemType[],
  { strip }: GenerateConfigurationResponseSchemaParams = { strip: false },
) {
  const obj = z.object(
    Object.fromEntries(
      items.flatMap(item => {
        let itemSchema = TYPES[item.data.type];

        if (item.invisible) {
          itemSchema = itemSchema.optional();
        }

        return [[item.name, itemSchema]];
      }),
    ),
  );

  let schema: z.ZodTypeAny = strip ? obj : obj.strict();

  for (const item of items) {
    const { name, widget, invisible } = item;

    if (invisible) {
      schema = schema.transform((form: ConfigValues, ctx) => {
        const shouldBeVisible = !isAnyConditionValid(form, invisible);

        if (strip && !shouldBeVisible && name in form) {
          const formWithoutItem = { ...form };
          delete formWithoutItem[name];
          return formWithoutItem;
        }
        if (shouldBeVisible ? !(name in form) : name in form) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: conditionalParameterValidationMessage(name, shouldBeVisible),
            path: [name],
          });
          return z.NEVER;
        }
        return form;
      });
    }

    if (widget === Widget.Checkbox && item.validations) {
      schema = validate(schema, name, conditionallyAllowedValues(item.validations.allowedValue));
    }
  }

  return schema;
}
