import { AxiosError } from "axios";
import { ZodError } from "zod";

import { ValidationErrors } from "./validation-errors";

export type MutationError<TInput> = AxiosError<unknown, TInput> | ZodError | ValidationErrors;

export function isValidationErrors<TInput>(error: MutationError<TInput> | null | undefined): error is ValidationErrors {
  return error instanceof ValidationErrors;
}
