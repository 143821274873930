import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { ApiEntity } from "../../../_common/api/api-entity";
import { getMany } from "../../../_common/api/methods/get-many";
import { queryKeys } from "../../../_common/api/query-keys/query-keys";
import { useRestClient } from "../../../_common/api/rest-client/rest-client-context";
import { onPremShieldersRootPath } from "../on-prem-shielders-root-path";
import { OnPremShielderI18nToastKey } from "./_support/on-prem-shielder-i18n-toast-key";

export const ApiGetOnPremShieldersListItem = z.object({
  fileName: z.string().min(1),
});
export type ApiGetOnPremShieldersListItem = z.input<typeof ApiGetOnPremShieldersListItem>;

function getOnPremShieldersListItemTransformer<T extends z.infer<typeof ApiGetOnPremShieldersListItem>>(
  onPremShielder: T,
) {
  return onPremShielder;
}

const GetOnPremShieldersListItem = ApiGetOnPremShieldersListItem.transform(getOnPremShieldersListItemTransformer);
type GetOnPremShieldersListItem = z.infer<typeof GetOnPremShieldersListItem>;

export const useGetOnPremShieldersList = () => {
  const restClient = useRestClient();

  return useQuery({
    queryKey: queryKeys[onPremShieldersRootPath]().list(),
    queryFn: async () =>
      getMany<GetOnPremShieldersListItem, ApiGetOnPremShieldersListItem, Record<string, never>>(
        restClient,
        ApiEntity.OnPremShielder,
        {},
        GetOnPremShieldersListItem,
      ),
    meta: { i18nToastKey: OnPremShielderI18nToastKey.GET_MANY },
    select: data => data?.data,
  });
};
