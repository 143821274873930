import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { ApiEntity } from "../../../../../_common/api/api-entity";
import { getOne } from "../../../../../_common/api/methods/get-one";
import { queryKeys } from "../../../../../_common/api/query-keys/query-keys";
import { useRestClient } from "../../../../../_common/api/rest-client/rest-client-context";
import { DateTime, ID } from "../../../../../_common/types";
import { appShieldingRootPath } from "../../../app-shielding-root-path";
import { ConfigurationI18nToastKey } from "./_support/configuration-i18n-toast-key";
import { GeneralConfigValues } from "./types/config-values";

export function apiGetConfiguration<TConfigValues extends GeneralConfigValues>(configValuesSchema: TConfigValues) {
  return z.object({
    configValues: configValuesSchema,
    configUpdatedAt: DateTime,
    testMode: z.boolean(),
  });
}
export type ApiGetConfiguration = z.input<ReturnType<typeof apiGetConfiguration>>;

function getConfigurationTransformer<T extends z.infer<ReturnType<typeof apiGetConfiguration>>>({
  testMode,
  configValues,
  ...rest
}: T) {
  return {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    configValues: { ...configValues, debugMode: testMode },
    ...rest,
  };
}

function getConfiguration<TConfigValues extends GeneralConfigValues>(configValuesSchema: TConfigValues) {
  return apiGetConfiguration(configValuesSchema).transform(getConfigurationTransformer);
}
export type GetConfiguration = z.infer<ReturnType<typeof getConfiguration>>;

interface UseGetConfiguration {
  projectId: ID;
  projectName: string | undefined;
  configurationId: ID | undefined;
  disabled: boolean | undefined;
  configurationResponseSchema: GeneralConfigValues | undefined;
}
export function useGetConfiguration({
  projectId,
  projectName,
  configurationId,
  disabled,
  configurationResponseSchema,
}: UseGetConfiguration) {
  const restClient = useRestClient();

  return useQuery({
    queryKey: queryKeys[appShieldingRootPath].projects().item(projectId).configuration(),
    queryFn: () =>
      getOne(restClient, ApiEntity.Configuration, configurationId, getConfiguration(configurationResponseSchema!)),
    enabled: Boolean(configurationId && configurationResponseSchema) && !disabled,
    meta: {
      resourceName: projectName,
      i18nToastKey: ConfigurationI18nToastKey.GET_CONFIG,
    },
  });
}
