import { createTheme as createMuiTheme, Theme, ThemeOptions } from "@mui/material/styles";

import { accordionTheme } from "../accordion/accordion.theme";
import { buttonTheme } from "../button/button.theme";
import { checkboxTheme } from "../checkbox/checkbox.theme";
import { chipTheme } from "../chip/chip.theme";
import { dialogTheme } from "../dialog/dialog.theme";
import { iconButtonTheme } from "../icon-button/icon-button.theme";
import { inputBaseTheme } from "../input/input-base.theme";
import { inputLabelTheme } from "../input/input-label.theme";
import { listTheme } from "../list/list.theme";
import { listItemButtonTheme } from "../list/list-item-button.theme";
import { listItemTheme } from "../list/listItem.theme";
import { menuTheme } from "../menu/menu.theme";
import { menuItemTheme } from "../menu/menu-item.theme";
import { radioButtonTheme } from "../radio-button/radio-button.theme";
import { selectTheme } from "../select/select.theme";
import { switchTheme } from "../switch/switch.theme";
import { tooltipTheme } from "../tooltip/tooltip.theme";

export const createTheme = (theme: ThemeOptions): Theme => {
  const themeOverrides: Theme = createMuiTheme(theme);

  const componentOverrides: ThemeOptions = {
    components: {
      ...accordionTheme(themeOverrides),
      ...buttonTheme(themeOverrides),
      ...checkboxTheme(themeOverrides),
      ...chipTheme(themeOverrides),
      ...dialogTheme(themeOverrides),
      ...iconButtonTheme(themeOverrides),
      ...inputBaseTheme(themeOverrides),
      ...inputLabelTheme(themeOverrides),
      ...listTheme(),
      ...listItemTheme(themeOverrides),
      ...listItemButtonTheme(themeOverrides),
      ...menuTheme(themeOverrides),
      ...menuItemTheme(themeOverrides),
      ...radioButtonTheme(themeOverrides),
      ...selectTheme(themeOverrides),
      ...switchTheme(),
      ...tooltipTheme(themeOverrides),
    },
  };

  return createMuiTheme({
    ...themeOverrides,
    ...componentOverrides,
  });
};
