import { useRef } from "react";

import { Paginator } from "./paginator";

export function useGetPageCount(
  loading?: boolean,
  itemCount?: number,
  rowsPerPage = Paginator.DEFAULT_ROWS_COUNT_PER_PAGE,
) {
  const pageCount = Math.ceil((itemCount || 0) / rowsPerPage);
  const pageCountRef = useRef(pageCount);
  if (!loading) pageCountRef.current = pageCount;
  return pageCountRef.current;
}
