import { CheckCircle as CheckCircleIcon, ContentPaste as ContentPasteIcon } from "@mui/icons-material";
import { Button, ButtonProps } from "@mui/material";
import { MouseEvent, useState } from "react";
import { makeStyles } from "tss-react/mui";

import { useTranslate } from "../../i18n/hooks/use-translate.hook";

type CopyToClipboardButtonProps = {
  text: string;
} & ButtonProps;

export const CopyToClipboardButton = ({ text, onClick, ...props }: CopyToClipboardButtonProps) => {
  const t = useTranslate();
  const { classes } = useStyles();
  const [copied, setCopied] = useState(false);
  const handleCopyApiKey = (event: MouseEvent<HTMLButtonElement>) => {
    onClick && onClick(event);

    void navigator.clipboard.writeText(text).then(() => {
      setCopied(true);

      setTimeout(() => {
        setCopied(false);
      }, 1000);
    });
  };
  return (
    <Button
      className={classes.copyButton}
      startIcon={copied ? <CheckCircleIcon /> : <ContentPasteIcon />}
      aria-label={copied ? t("component.api-access.copied-button") : t("component.api-access.copy-button")}
      onClick={handleCopyApiKey}
      variant="outlined"
      size="small"
      color="primary"
      {...props}
    ></Button>
  );
};

const useStyles = makeStyles()(theme => ({
  copyButton: {
    minWidth: theme.spacing(5),
    boxShadow: "none",

    "&:hover, &:focus, &:active": {
      boxShadow: "none",
      backgroundColor: theme.palette.grey[200],
    },

    "& .MuiButton-startIcon": {
      marginRight: 0,
    },
  },
}));
