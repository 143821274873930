import { useCallback, useRef, useState } from "react";

import { ConfirmationDialog, ConfirmationDialogProps } from "./confirmation-dialog";

type AsyncConfirmationDialogProps<T> = Omit<ConfirmationDialogProps<T>, "onConfirm"> & {
  onConfirm?: () => Promise<T | void>;
  onConfirmSuccess?: () => void;
};

export function AsyncConfirmationDialog<T>({
  children,
  onConfirm,
  onConfirmSuccess,
  ...confirmationDialogProps
}: AsyncConfirmationDialogProps<T>) {
  const [submitting, setSubmitting] = useState(false);
  const successfulSubmitRef = useRef(false);

  const clickConfirmHandler = useCallback(() => {
    setSubmitting(true);
    onConfirm!()
      .then(() => {
        successfulSubmitRef.current = true;
      })
      .catch(() => {})
      .finally(() => setSubmitting(false));
  }, [onConfirm]);

  const handleExited = () => {
    if (successfulSubmitRef.current && onConfirmSuccess) {
      onConfirmSuccess();
      successfulSubmitRef.current = false;
    }
  };

  return (
    <ConfirmationDialog
      {...confirmationDialogProps}
      onConfirm={onConfirm ? clickConfirmHandler : undefined}
      onExited={handleExited}
      submitting={submitting}
    >
      {children}
    </ConfirmationDialog>
  );
}
