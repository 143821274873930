export enum GeneralFeatures {
  SideBar = "sidebar",
}

export enum GeneralActions {
  CollapseSidebar = "collapse-sidebar",
  ExpandSidebar = "expand-sidebar",
  ViewAppShielding = "view-app-shielding",
  ViewAppShieldingTool = "view-app-shielding-tool",
  ViewSettings = "view-settings",
  OpenUserProfileMenu = "open-user-profile-menu",
  Logout = "logout",
}
