import { ConfigurationItem, Widget } from "@mob/shielder-metadata";
import {
  InsertDriveFile as InsertDriveFileIcon,
  RemoveCircleOutline as RemoveCircleOutlineIcon,
  Upload as UploadIcon,
} from "@mui/icons-material";
import { alpha, Box, IconButton, Typography } from "@mui/material";
import { useCallback, useId, useMemo } from "react";
import { useController } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { makeStyles } from "tss-react/mui";

import { DateTimeFormat, useDateTime } from "../../../../../../../../_common/i18n/hooks/use-date-time.hook";
import { useTranslate } from "../../../../../../../../_common/i18n/hooks/use-translate.hook";
import { FileDropzone } from "../../../../../../../../_common/ui/file-dropzone/file-dropzone";
import { mixins } from "../../../../../../../../_common/ui/theme/mixins";
import { useNumberFormat } from "../../../../../../../../_common/utils/number-format";
import { FileCertificate } from "../../../../api/types/file-certificate";
import { ariaLabelledById } from "../../../../utils/aria-labelled-by-id";
import { itemId } from "../../../../utils/item-id";

type FileInputCurrentValue = FileCertificate | null;
interface CertificateItemProps {
  name: string;
  disabled?: boolean;
  isEditMode: boolean;
  isUpdating: boolean;
  isRequired: boolean;
  allowedFileExtensions?: Extract<ConfigurationItem, { widget: Widget.CertificateUploader }>["allowedFileExtensions"];
}

export function CertificateItem({
  name,
  disabled,
  isUpdating,
  isEditMode,
  isRequired,
  allowedFileExtensions,
}: CertificateItemProps) {
  const t = useTranslate();
  const numberFormat = useNumberFormat();
  const dateFormat = useDateTime();
  const uid = useId();
  const intl = useIntl();
  const { classes } = useStyles({ isEditMode, isRequired });
  const {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    field: { onChange, value, ...field },
    fieldState: { error },
    formState: { defaultValues },
  } = useController({ name });
  const handleSelectFile = useCallback((files: File[]) => onChange(files[0]), [onChange]);
  const currentValue = defaultValues?.[name] as FileInputCurrentValue | undefined;
  const allowedExtensions = useMemo(
    () => allowedFileExtensions && { "application/octet-stream": allowedFileExtensions?.map(ext => `.${ext}`) },
    [allowedFileExtensions],
  );

  const dropzoneAriaLabel = intl.formatMessage(
    { id: "component.configuration-item.drag-and-drop.message" },
    { label: t("component.configuration-item.drag-and-drop.label-message") },
  );

  return (
    <Box className={classes.wrapper}>
      {currentValue && (
        <div
          className={classes.certificate}
          role="region"
          aria-label={t("component.configuration-form.item.certificate.certificate-details.aria-label")}
        >
          <InsertDriveFileIcon />
          <ul>
            <li>
              <Typography component="span">{t("component.configuration-item.file.certificate.subject")}</Typography>
              <Typography component="span">{currentValue.subject}</Typography>
            </li>
            <li>
              <Typography component="span">{t("component.configuration-item.file.certificate.issuer")}</Typography>
              <Typography component="span">{currentValue.issuer}</Typography>
            </li>
            <li>
              <Typography component="span">{t("component.configuration-item.file.certificate.from")}</Typography>
              <Typography component="span">
                {dateFormat(currentValue.from, DateTimeFormat.YYYYMMDDhmLocalTimeFormat)}
              </Typography>
            </li>
            <li>
              <Typography component="span">{t("component.configuration-item.file.certificate.to")}</Typography>
              <Typography component="span">
                {dateFormat(currentValue.to, DateTimeFormat.YYYYMMDDhmLocalTimeFormat)}
              </Typography>
            </li>
          </ul>
        </div>
      )}
      {isEditMode && (
        <>
          <FileDropzone
            id={itemId(name)}
            aria-label={dropzoneAriaLabel}
            accept={allowedExtensions}
            onChange={handleSelectFile}
            mainText={
              <FormattedMessage
                id="component.configuration-item.drag-and-drop.message"
                values={{
                  label: <label htmlFor={uid}>{t("component.configuration-item.drag-and-drop.label-message")} </label>,
                }}
              />
            }
            dropText={t("component.configuration-item.drag-and-drop.hover-message")}
            textIcon={UploadIcon}
            disabled={disabled || isUpdating}
            // in this case we do not care about input value. It is set to empty string to allow users to upload same file twice
            inputProps={{ ...field, id: uid, value: "" }}
            aria-labelledby={ariaLabelledById(name)}
            errorMessage={error?.message}
          />

          {value instanceof File && (
            <div
              className={classes.selectedFile}
              role="region"
              aria-label={t("component.configuration-form.item.certificate.new-certificate.aria-label")}
            >
              <div>
                <InsertDriveFileIcon />
              </div>
              <div className={classes.selectedFileContent}>
                <div className={classes.selectedFileInfo}>
                  <Typography component="span" className={classes.selectedFileName}>
                    {value.name}
                  </Typography>
                  <Typography component="span">
                    {t("component.configuration-item.file.size", {
                      size: numberFormat(value.size),
                    })}
                  </Typography>
                </div>
                <IconButton
                  onClick={() => onChange(currentValue)}
                  aria-label={t("component.configuration-item.certificate.delete-file.aria-label", {
                    name: value.name,
                  })}
                >
                  <RemoveCircleOutlineIcon />
                </IconButton>
              </div>
            </div>
          )}
        </>
      )}
    </Box>
  );
}

const useStyles = makeStyles<{ isEditMode: boolean; isRequired: boolean }>()((theme, { isEditMode, isRequired }) => ({
  wrapper: {
    marginTop: isEditMode && isRequired ? theme.spacing(1.5) : undefined,
  },
  certificate: {
    display: "flex",
    alignItems: "flex-start",
    gap: "8px",
    marginBottom: isEditMode ? theme.spacing(3.5) : undefined,
    backgroundColor: alpha("#000", 0.08),
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(2.5, 2),
    "& > ul": {
      listStyle: "none",
      margin: 0,
      padding: 0,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      "& span:first-of-type": {
        fontWeight: 700,
        marginRight: theme.spacing(1),
      },
      "& span:last-of-type": {
        fontWeight: 400,
      },
    },
  },
  selectedFile: {
    display: "grid",
    borderRadius: theme.spacing(0.5),
    width: "100%",
    border: `1px solid ${alpha(theme.palette.text.primary, 0.57)}`,
    overflow: "hidden",
    marginTop: theme.spacing(2.5),
    gridTemplateColumns: "72px 1fr",
    "& > div:first-of-type": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: alpha("#000", 0.08),
    },
  },
  selectedFileContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    "& button": {
      marginRight: theme.spacing(2),
    },
  },
  selectedFileInfo: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(1.5),
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(24),
    "& span:first-of-type": {
      fontWeight: 600,
    },
  },
  selectedFileName: {
    ...mixins.lineClamp(1),
  },
}));
