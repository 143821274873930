import { Box } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { appShieldingRootPath } from "../../../../../app-shielding-root-path";
import { AlertType } from "../../../../shielders/types/alert-type";
import { ShielderStatus } from "../../../../shielders/types/shielder-status";
import { UnsupportedOrDeletedShielderVersionAlert } from "../../../../shielders/ui/unsupported-or-deleted-shielder-version-alert";
import { ShieldingsList } from "../../../../shieldings/features/shieldings-list";
import { GetProject } from "../../../api/get-project";

interface ProjectDetailsContentProps {
  project: GetProject | undefined;
}

export function ProjectDetailsContent({ project }: ProjectDetailsContentProps) {
  const { classes } = useStyles();

  const isUnsupportedShielder = project?.shielderStatus === ShielderStatus.Unsupported;
  const isDeletedShielder = project?.shielderStatus === ShielderStatus.Deleted;

  return (
    <Box data-testid="Configurations" className={classes.root}>
      {(isUnsupportedShielder || isDeletedShielder) && (
        <UnsupportedOrDeletedShielderVersionAlert
          project={project}
          type={AlertType.ProjectDetails}
          unsupportedShielder={isUnsupportedShielder}
          deletedShielder={isDeletedShielder}
          selectVersionPath={`/${appShieldingRootPath}/projects/${project.id}/select-version`}
        />
      )}
      <ShieldingsList project={project} />
    </Box>
  );
}

const useStyles = makeStyles()(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    marginLeft: theme.spacing(1.125),
    width: "100%",
    gap: theme.spacing(2.5),
    flexGrow: 1,
  },
}));
