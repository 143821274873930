import { ShielderMetadata } from "@mob/shielder-metadata";
import type { ConfigurationItem } from "@mob/shielder-metadata/src/types/shielder-metadata/metadata";

import { ConfigValues } from "../../api/types/config-values";

export type FeatureValues = Record<ConfigurationItem["name"], boolean>;

export const generateMetadataFeatureValues =
  (metadata: ShielderMetadata): ((configValues: ConfigValues) => FeatureValues) =>
  (configValues: ConfigValues) =>
    metadata
      .flatMap(section => section.blocks)
      .reduce((featureValues, block) => {
        const feature = block.items[0].name;
        featureValues[feature] = Boolean(configValues?.[feature]);
        return featureValues;
      }, {} as FeatureValues);
