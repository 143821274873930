import { Theme } from "@mui/material/styles";

export const tooltipTheme = (theme: Theme) => ({
  MuiTooltip: {
    styleOverrides: {
      arrow: {
        fontSize: theme.typography.pxToRem(10),
        color: theme.palette.grey[800],
      },
      popperArrow: {
        '&[x-placement*="left"] $arrow': {
          right: "0",
        },
        '&[x-placement*="right"] $arrow': {
          left: "0",
        },
        '&[x-placement="bottom-end"] $arrow': {
          left: "calc(100% - 20px) !important",
        },
        '&[x-placement="bottom-start"] $arrow': {
          left: "4px !important",
        },
        '&[x-placement="top-end"] $arrow': {
          left: "calc(100% - 20px) !important",
        },
        '&[x-placement="top-start"] $arrow': {
          left: "4px !important",
        },
      },
      tooltip: {
        backgroundColor: theme.palette.grey[800],
        fontSize: theme.typography.pxToRem(12),
      },
    },
  },
});
