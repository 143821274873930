import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { ApiEntity } from "../../../../../_common/api/api-entity";
import { getOne } from "../../../../../_common/api/methods/get-one";
import { queryKeys } from "../../../../../_common/api/query-keys/query-keys";
import { useRestClient } from "../../../../../_common/api/rest-client/rest-client-context";
import { ID } from "../../../../../_common/types";
import { appShieldingRootPath } from "../../../app-shielding-root-path";
import { GetProject } from "../../projects/api/get-project";
import { ShieldingI18nToastKey } from "./_support/shielding-i18n-toast-key";
import { ApiGetShieldingsListItem, getShieldingsListItemTransformer } from "./get-shieldings-list-item";
import { ShieldingsActions } from "./types";

export const ApiGetShielding = ApiGetShieldingsListItem.extend({
  shieldingLogs: z.string(),
});
export type ApiGetShielding = z.input<typeof ApiGetShielding>;

function getShieldingTransformer<T extends z.infer<typeof ApiGetShielding>>(value: T, ctx: z.RefinementCtx) {
  return getShieldingsListItemTransformer(value, ctx);
}

export const GetShielding = ApiGetShielding.transform(getShieldingTransformer);
export type GetShielding = z.infer<typeof GetShielding>;

interface UseGetShielding {
  project: GetProject | undefined;
  shieldingId: ID;
}
export function useGetShielding({ shieldingId, project }: UseGetShielding) {
  const restClient = useRestClient();

  return useQuery({
    queryKey: queryKeys[appShieldingRootPath].projects().item(project?.id).shieldings().item(shieldingId).details(),
    queryFn: () =>
      getOne(restClient, ApiEntity.Shielding, undefined, GetShielding, {
        action: ShieldingsActions.Details,
        uuid: shieldingId,
      }),
    enabled: Boolean(project),
    meta: {
      i18nToastKey: ShieldingI18nToastKey.GET_SHIELDING,
      resourceName: project?.projectName,
    },
  });
}
