import { SkeletonProps } from "@mui/material";

import { TextSkeleton } from "./text-skeleton";

interface FixedWidthSkeletonProps extends SkeletonProps {
  relativeWidth?: number;
}

export const FixedWidthSkeleton = ({ relativeWidth = 1, ...props }: FixedWidthSkeletonProps) => (
  <TextSkeleton width={`${relativeWidth * 100}px`} {...props} />
);
