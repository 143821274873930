import { Box, Button, Divider, Typography } from "@mui/material";
import { useState } from "react";
import { makeStyles } from "tss-react/mui";

import { useTranslate } from "../../../../../../../_common/i18n/hooks/use-translate.hook";
import { isParagraph } from "../../../../../../../_common/utils/is-paragraph";

const DESCRIPTION_CUT_LENGTH = 160;

interface ConfigurationBlockDescriptionProps {
  label?: string;
  text: string;
  sectionTitle: string;
  first?: boolean;
}

export function ConfigurationBlockDescription({
  label,
  text,
  sectionTitle,
  first = true,
}: ConfigurationBlockDescriptionProps) {
  const applyShowMode = text.length > DESCRIPTION_CUT_LENGTH + 3 + 9; // 3 is for "..." and 9 is for " See more"
  const [showMore, setShowMore] = useState(false);
  const textToShow = !applyShowMode || showMore ? text : `${text.substring(0, DESCRIPTION_CUT_LENGTH).trim()}...`;
  const t = useTranslate();
  const { classes } = useStyles();

  return (
    <Box display="inline">
      {!first ? <Divider sx={{ my: 3 }} /> : null}
      {label ? (
        <Typography component="h3" className={classes.label}>
          {label}
        </Typography>
      ) : null}
      <Typography
        dangerouslySetInnerHTML={{ __html: textToShow }}
        component={isParagraph(textToShow) ? "div" : "p"}
        className={classes.text}
      />
      {applyShowMode && (
        <>
          {` `}
          <Button
            variant="text"
            color="primary"
            component="a"
            className={classes.seeMore}
            onClick={() => setShowMore(b => !b)}
            aria-label={t(
              showMore
                ? "component.configuration-block-description.see-less-about"
                : "component.configuration-block-description.see-more-about",
              { sectionTitle: label ?? sectionTitle },
            )}
            aria-expanded={showMore}
          >
            {showMore ? t("actions.see-less") : t("actions.see-more")}
          </Button>
        </>
      )}
    </Box>
  );
}

const useStyles = makeStyles()(theme => ({
  label: {
    color: theme.palette.text.primary,
    fontSize: "0.875rem",
    fontWeight: 500,
    lineHeight: 1.715,
    marginBottom: theme.spacing(0.5),
  },
  text: {
    color: theme.palette.text.primary,
    fontSize: "0.875rem",
    lineHeight: 1.425,
    fontWeight: 400,
    display: "inline",
    "& a": {
      color: theme.palette.primary.main,
    },
    "& *:last-child": {
      display: "inline",
    },
    "& code:not(last-child)": {
      display: "block",
    },
  },
  seeMore: {
    color: theme.palette.primary.main,
    fontSize: "0.750rem",
    lineHeight: 1.335,
    fontWeight: 500,
    textDecoration: "underline",
    padding: 0,
    minWidth: "auto",
    "&:hover": {
      textDecoration: "underline",
      backgroundColor: "transparent",
    },
  },
}));
