import { createContext, useContext } from "react";

const noop = () => {};

export interface AuthActionsContext {
  login: () => void;
  logout: () => void;
  authenticationConfirmed: () => void;
  authenticationRejected: () => void;
}

// Initial state, only to satisfy TypeScript,
// it will be overwritten on the first call to AuthProvider
export const AuthActionsContext = createContext<AuthActionsContext>({
  login: noop,
  logout: noop,
  authenticationConfirmed: noop,
  authenticationRejected: noop,
});

export const useAuthActions = () => useContext(AuthActionsContext);
