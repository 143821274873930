import { Switch as MuiSwitch, SwitchProps as MuiSwitchProps } from "@mui/material";
import { forwardRef } from "react";

import { useFormFieldContext } from "../form-field/form-field-context";

type SwitchProps = MuiSwitchProps;

export const Switch = forwardRef<HTMLButtonElement, SwitchProps>(
  ({ "aria-describedby": ariaDescribedByProp, id: idProp, ...props }, ref) => {
    const fieldContext = useFormFieldContext();
    const ariaDescribedBy = fieldContext?.helperTextId || ariaDescribedByProp;
    const id = fieldContext?.id || idProp;
    return (
      <MuiSwitch
        color="primary"
        disableRipple
        id={id}
        inputProps={{
          "aria-describedby": ariaDescribedBy,
        }}
        ref={ref}
        {...props}
      />
    );
  },
);

Switch.displayName = "Switch";
