import { Box, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { ID } from "../../../../../../../../_common/types";
import { TextSkeleton } from "../../../../../../../../_common/ui/skeleton/text-skeleton";
import { ColumnCellProps } from "../../../../../../../../_common/ui/table/data-table";
import { RowLink } from "../../../../../../../../_common/ui/table/data-table/row-link";
import { DebugModeTag } from "../../../../../../_common/ui/debug-mode-tag";

type ProjectCellProps = ColumnCellProps<
  {
    id: ID;
    projectName: string;
    projectDescription: string;
    debugMode: boolean;
  },
  unknown
>;
export const ProjectCell = ({ id, debugMode, isLoading, projectDescription, projectName }: ProjectCellProps) => {
  const { classes } = useStyles();

  return (
    <>
      {isLoading ? (
        <>
          <TextSkeleton className={classes.firstSkeleton} height="24px" width="50%" />
          <TextSkeleton height="24px" />
        </>
      ) : (
        <>
          <Box display="flex" alignItems="center" gap={1}>
            <RowLink to={id.toString()}>{projectName}</RowLink>
            <DebugModeTag show={debugMode} />
          </Box>

          <Typography className={classes.description}>{projectDescription}</Typography>
        </>
      )}
    </>
  );
};

const useStyles = makeStyles()(theme => ({
  description: {
    ...theme.mixins.lineClamp(2),
  },
  firstSkeleton: {
    marginBottom: theme.spacing(1.5),
  },
}));
