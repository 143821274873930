import { datadogRum } from "@datadog/browser-rum";

import { AppShieldingActions, AppShieldingFeatures } from "./app-shielding-actions";
import { GeneralActions, GeneralFeatures } from "./general-actions";
import { OnPremShielderActions, OnPremShielderFeatures } from "./on-prem-actions";
import { SettingsActions, SettingsFeatures } from "./settings-actions";

export function addAction(
  name: AppShieldingActions | OnPremShielderActions | SettingsActions | GeneralActions,
  feature: AppShieldingFeatures | OnPremShielderFeatures | SettingsFeatures | GeneralFeatures,
  context?: object,
) {
  datadogRum.addAction(name, {
    feature,
    ...context,
  });
}
