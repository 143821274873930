import { Api as ApiIcon } from "@mui/icons-material";
import { useTheme } from "@mui/material";

import { useTranslate } from "../../../../../_common/i18n/hooks/use-translate.hook";
import { Tag } from "../../../../../_common/ui/tag/tag";
import { ShieldingSource } from "../types";

interface ShieldingSourceTagProps {
  source: ShieldingSource | undefined;
}

export function ShieldingSourceTag({ source }: ShieldingSourceTagProps) {
  const t = useTranslate();
  const theme = useTheme();
  return source === ShieldingSource.Api ? (
    <Tag text={t("word.api")} icon={ApiIcon} color={theme.palette.text.primary} data-testid="shielding-api-source" />
  ) : null;
}
