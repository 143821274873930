import { HTMLProps } from "react";

import { useLinkStyles } from "./use-link-styles";

export const Anchor = ({ children, ...anchorProps }: HTMLProps<HTMLAnchorElement>) => {
  const { classes } = useLinkStyles();

  return (
    <a className={classes.root} {...anchorProps}>
      {children}
    </a>
  );
};
