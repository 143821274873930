import { Box } from "@mui/material";

import { TextSkeleton } from "../../../../../../../../_common/ui/skeleton/text-skeleton";
import { ColumnCellProps } from "../../../../../../../../_common/ui/table/data-table";
import { ShielderStatus } from "../../../../../shielders/types/shielder-status";
import { ShielderStatusLabel } from "../../../../../shielders/ui/shielder-status-label";
import { ShielderVersionLabel } from "../../../../../shielders/ui/shielder-version-label";
import { GetShieldingsListItem } from "../../../../api/get-shieldings-list-item";

type VersionCellProps = ColumnCellProps<GetShieldingsListItem>;
export const VersionCell = ({ isLoading, shielderPlatform, shielderStatus, shielderVersion }: VersionCellProps) => {
  return isLoading ? (
    <TextSkeleton height="24px" />
  ) : (
    <Box display="flex" flexDirection="column">
      <ShielderVersionLabel platform={shielderPlatform} version={shielderVersion} hidePlatformName />
      {shielderStatus !== ShielderStatus.Latest && <ShielderStatusLabel status={shielderStatus} />}
    </Box>
  );
};
