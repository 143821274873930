import axios from "axios";

import { ENV } from "../../env-vars";
import { Tokens } from "./tokens";

const GRANT_TYPE = "authorization_code";

const REDIRECT_URI = `${window.location.protocol}//${window.location.host}/${ENV.COGNITO_CALLBACK_PATH}`;

const searchParams = new URLSearchParams({
  client_id: ENV.COGNITO_CLIENT_ID,
  grant_type: GRANT_TYPE,
  response_type: "code",
  redirect_uri: REDIRECT_URI,
});

export const enum CognitoAuthMethod {
  Login = "login",
  Logout = "logout",
}
export const redirectToCognito = (authMethod: CognitoAuthMethod) => {
  window.location.assign(`${ENV.COGNITO_HOST}/${authMethod}?${searchParams.toString()}`);
};

export async function exchangeCodeForToken(code: string) {
  const searchParams = new URLSearchParams({
    client_id: ENV.COGNITO_CLIENT_ID,
    code,
    grant_type: GRANT_TYPE,
    redirect_uri: REDIRECT_URI,
  });
  const response = await axios.post<unknown>(`${ENV.COGNITO_HOST}/oauth2/token`, searchParams.toString());
  return Tokens.parse(response.data);
}
