import dayjs from "dayjs";

import { createNewIntl } from "./create-new-intl";
import en from "./dictionary/en";
import { locale } from "./locale";

export const i18n = {
  intl: createNewIntl(locale, en),
};
dayjs.locale("en");
