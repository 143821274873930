import { ReactNode } from "react";
import { cssTransition, toast, TypeOptions } from "react-toastify";

import { ENV } from "../../env-vars";
import { ToastContent } from "./toast-content";

interface ShowToastOptions {
  buttons?: ReactNode;
  autoClose?: boolean;
  toastId?: string;
}

const none = cssTransition({
  enter: "Toastify__e2e-enter",
  exit: "Toastify__e2e-exit",
});

export const showToast = (
  type: TypeOptions,
  message: ReactNode,
  { buttons, autoClose, toastId }: ShowToastOptions = {},
) =>
  toast(<ToastContent type={type} message={message} buttons={buttons} />, {
    autoClose:
      ENV.NO_TOAST_AUTOCLOSE_AND_TRANSITION || autoClose === false || (autoClose === undefined && type === "error")
        ? false
        : undefined,
    type,
    transition: ENV.NO_TOAST_AUTOCLOSE_AND_TRANSITION ? none : undefined,
    toastId,
  });

// autoClose === true => undefined (use default autoClose time)
// autoClose === false => false
// autoClose === undefined => type === "error" ? false : undefined
