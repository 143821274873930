import { Theme, ThemeOptions } from "@mui/material";

export const chipTheme = (theme: Theme): ThemeOptions["components"] => ({
  MuiChip: {
    styleOverrides: {
      root: {
        fontSize: theme.typography.pxToRem(14),
      },
      sizeSmall: {
        fontSize: theme.typography.pxToRem(12),
      },
    },
  },
});
