import { AxiosInstance, AxiosResponse } from "axios";
import { ZodType, ZodTypeDef } from "zod";

export function getRequest<TItem, TApiItem, Def extends ZodTypeDef = ZodTypeDef>(
  restClient: AxiosInstance,
  url: string,
  responseTypeAndTransformer: ZodType<TItem, Def, TApiItem>,
) {
  return restClient
    .get<unknown, AxiosResponse<unknown, undefined>, undefined>(url)
    .then(response => responseTypeAndTransformer.parse(response.data))
    .catch(e => {
      throw e;
    });
}
