import { alpha, Theme, ThemeOptions } from "@mui/material";

import { DEFAULT_SHADOW } from "../theme/theme";

const boxShadow = "0px 0px 0px 2px #FFF inset";

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    default: true;
  }
  interface ButtonClasses {
    /** Styles applied to the root element if `variant="text"` and `color="default"`. */
    textDefault: string;
    /** Styles applied to the root element if `variant="outlined"` and `color="default"`. */
    outlinedDefault: string;
    /** Styles applied to the root element if `variant="contained"` and `color="default"`. */
    containedDefault: string;
    /** Styles applied to the root element if `color="default"`. */
    colorDefault: string;
  }
}

export const buttonTheme = (theme: Theme): ThemeOptions["components"] => ({
  MuiButton: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        padding: theme.spacing(1.25, 1.75),
        transition: "none",
        lineHeight: theme.typography.pxToRem(24),
        "&:hover, &:focus": {
          textDecoration: "none",
        },
        "&:focus": {
          outline: `solid 3px ${theme.palette.primary.main}`,
          "&:not(:focus-visible)": {
            outline: "none",
          },
        },
        "&.Mui-disabled": {
          color: alpha(theme.palette.grey[800], 0.5),
        },
        "&.Mui-rootLink": {
          padding: 10,
        },
      },
      sizeSmall: {
        padding: theme.spacing(1, 1.375),
        lineHeight: theme.typography.pxToRem(20),
        fontSize: `${theme.typography.pxToRem(12)}!important`,
        fontWeight: 500,
      },
      sizeLarge: {
        padding: theme.spacing(1.5, 2.5),
        lineHeight: theme.typography.pxToRem(28),
        fontSize: `${theme.typography.pxToRem(16)}!important`,
        fontWeight: 500,
      },
      containedDefault: {
        backgroundColor: theme.palette.default?.light,
        boxShadow: DEFAULT_SHADOW,
      },
      containedPrimary: {
        boxShadow: DEFAULT_SHADOW,
        "&:hover": {
          boxShadow: "none",
        },
        "&:focus": {
          boxShadow,
          "&:not(:focus-visible)": {
            boxShadow: DEFAULT_SHADOW,
          },
        },
        "&.Mui-disabled": {
          backgroundColor: alpha(theme.palette.primary.main, 0.3),
        },
      },
      containedError: {
        boxShadow: DEFAULT_SHADOW,
        "&:focus": {
          boxShadow,
          "&:not(:focus-visible)": {
            boxShadow: "none",
          },
        },
        "&.Mui-disabled": {
          backgroundColor: alpha(theme.palette.error.main, 0.3),
        },
      },
      containedWarning: {
        boxShadow: DEFAULT_SHADOW,
        "&:focus": {
          boxShadow,
          "&:not(:focus-visible)": {
            boxShadow: "none",
          },
        },
        "&.Mui-disabled": {
          backgroundColor: alpha(theme.palette.warning.main, 0.3),
        },
      },
      outlined: {
        "&:hover": {
          background: alpha(theme.palette.common.black, 0.05),
        },
        "&:focus": {
          borderColor: alpha(theme.palette.common.black, 0.05),
          boxShadow: "none",
          "&:not(:focus-visible)": {
            borderColor: "none",
          },
        },
      },
      containedSizeSmall: {
        fontSize: theme.typography.pxToRem(14),
      },
      containedSizeLarge: {
        fontSize: theme.typography.pxToRem(14),
      },
      outlinedPrimary: {
        border: `solid 1px ${alpha(theme.palette.primary.main, 0.9)}`,
        "&:hover": {
          background: alpha(theme.palette.primary.main, 0.05),
        },
        "&:focus": {
          borderColor: alpha(theme.palette.primary.main, 0.05),
          boxShadow: "none",
          "&:not(:focus-visible)": {
            borderColor: alpha(theme.palette.primary.main, 0.9),
          },
        },
      },
      outlinedSecondary: {
        color: theme.palette.grey[800],
        border: `solid 1px ${alpha(theme.palette.grey[800], 0.6)}`,
        "&:focus": {
          borderColor: alpha(theme.palette.primary.main, 0.05),
          boxShadow: "none",
          "&:not(:focus-visible)": {
            borderColor: alpha(theme.palette.grey[800], 0.6),
          },
        },
      },
      outlinedError: {
        border: `solid 1px ${alpha(theme.palette.error.main, 0.9)}`,
        "&:hover": {
          background: alpha(theme.palette.error.main, 0.05),
        },
        "&:focus": {
          borderColor: alpha(theme.palette.error.main, 0.05),
          boxShadow: "none",
          "&:not(:focus-visible)": {
            borderColor: alpha(theme.palette.error.main, 0.9),
          },
        },
      },
      outlinedWarning: {
        border: `solid 1px ${alpha(theme.palette.warning.main, 0.9)}`,
        "&:hover": {
          background: alpha(theme.palette.warning.main, 0.05),
        },
        "&:focus": {
          borderColor: alpha(theme.palette.warning.main, 0.05),
          boxShadow: "none",
          "&:not(:focus-visible)": {
            borderColor: alpha(theme.palette.warning.main, 0.9),
          },
        },
      },
      text: {
        textDecoration: "underline",
        "&:hover": {
          background: "transparent",
          textDecoration: "none",
        },
        "&:active, &:active:focus": {
          boxShadow: "none",
          outline: "none",
          background: alpha(theme.palette.primary.main, 0.18),
        },
        "&:focus": {
          textDecoration: "underline",
          "&:not(:focus-visible)": {
            borderColor: "none",
          },
        },
      },
      textSizeSmall: {
        fontSize: theme.typography.pxToRem(14),
      },
      textSizeLarge: {
        fontSize: theme.typography.pxToRem(14),
      },
    },
  },
});
