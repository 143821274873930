import { z, ZodError } from "zod";

const LOCATION_KEY = "auth-back-location";

export const pushLocation = () => {
  const { pathname, search, hash } = window.location;

  sessionStorage.setItem(LOCATION_KEY, JSON.stringify(StoredLocation.parse({ pathname, search, hash })));
};

const StoredLocation = z.object({
  pathname: z.string(),
  search: z.string(),
  hash: z.string(),
});
export const popLocation = () => {
  try {
    const storedLocation = sessionStorage.getItem(LOCATION_KEY);
    sessionStorage.removeItem(LOCATION_KEY);
    if (storedLocation) {
      return StoredLocation.parse(JSON.parse(storedLocation));
    }
  } catch (e: unknown) {
    if (!(e instanceof SyntaxError) && !(e instanceof ZodError)) throw e;
  }
  return null;
};
