import { Route, Routes } from "react-router-dom";

import { useTranslate } from "../../_common/i18n/hooks/use-translate.hook";
import { NotFound } from "../../_common/ui/not-found/not-found";
import { OnPremShieldersPage } from "./on-prem-shielders-page";
import { onPremShieldersRootPath } from "./on-prem-shielders-root-path";

export const OnPremShieldersRouter = () => {
  const t = useTranslate();
  const notFound = (
    <NotFound
      rootPath={`/${onPremShieldersRootPath}`}
      rootButtonLabel={t(`component.empty-state.not-found.button.${onPremShieldersRootPath}`)}
    />
  );

  return (
    <Routes>
      <Route index element={<OnPremShieldersPage />} />
      <Route path="not-found" element={notFound} />
      <Route path="*" element={notFound} />
    </Routes>
  );
};
