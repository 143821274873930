import { hashKey } from "@tanstack/react-query";

import { QueryKey } from "../../../../../../_common/api/query-keys/query-key.type";
import { settingsRootPath } from "../../../../settings-root-path";

export const clientCredentialsRootQueryKey = "clientCredentials";

const CREATE = "create";
const ITEM = "item";
const DETAILS = "details";
const DELETE = "DELETE";

export const clientCredentialsQueryKeysFactory = () => {
  const all = () => [settingsRootPath, clientCredentialsRootQueryKey] as const;

  return {
    all,
    create: () => [...clientCredentialsQueryKeysFactory().all(), CREATE] as const,
    item: () => {
      const clientCredentialsItem = [...clientCredentialsQueryKeysFactory().all(), ITEM] as const;

      return {
        everything: () => clientCredentialsItem,
        details: () => [...clientCredentialsItem, DETAILS] as const,
        delete: () => [...clientCredentialsItem, DELETE] as const,
      } as const;
    },
  };
};

// Any 404 errors on client credentials are considered as BE bug => 500
export const considerClientCredentialsQueryKey404As500 = (key: QueryKey) => {
  const all = clientCredentialsQueryKeysFactory().all();

  return hashKey(all) === hashKey(key.slice(0, all.length));
};

export const invalidationClientCredentialsKeysOn404 = (_key: QueryKey) => ({});
