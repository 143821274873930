import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { ErrorBoundary } from "react-error-boundary";
import { RouterProvider } from "react-router-dom";

import { QueryClientProvider } from "../_common/api/query-client-provider";
import { RestClientProvider } from "../_common/api/rest-client/rest-client-provider";
import { AuthProvider } from "../_common/auth/auth-provider/auth-provider";
import { logError } from "../_common/error-logging/log-error";
import { I18nProvider } from "../_common/i18n/i18n-provider";
import { DatadogProvider } from "../_common/ui/datadog/datadog-provider";
import { ErrorPage } from "../_common/ui/error-boundary/error-page";
import { GlobalErrorPage } from "../_common/ui/error-boundary/global-error-page";
import { createTheme } from "../_common/ui/theme/create-theme";
import { theme } from "../_common/ui/theme/theme";
import { globalRouter } from "./global-router";

const styleCache = createCache({ key: "mui", prepend: true });

export const App = () => {
  return (
    <ErrorBoundary FallbackComponent={GlobalErrorPage} onError={logError}>
      <StyledEngineProvider injectFirst>
        <CacheProvider value={styleCache}>
          <ThemeProvider theme={createTheme(theme)}>
            <CssBaseline />
            <I18nProvider>
              <ErrorBoundary FallbackComponent={ErrorPage} onError={logError}>
                <AuthProvider>
                  <DatadogProvider>
                    <RestClientProvider>
                      <QueryClientProvider>
                        <RouterProvider router={globalRouter} />
                      </QueryClientProvider>
                    </RestClientProvider>
                  </DatadogProvider>
                </AuthProvider>
              </ErrorBoundary>
            </I18nProvider>
          </ThemeProvider>
        </CacheProvider>
      </StyledEngineProvider>
    </ErrorBoundary>
  );
};
