import { ClassNameMap } from "@mui/material";
import { forwardRef, ReactNode } from "react";
import { tss } from "tss-react/mui";

import { useFormFieldContext } from "./form-field-context";
import type { LabelPlacement } from "./types";

interface FormFieldLabelProps {
  id?: string;
  htmlFor?: string;
  children: ReactNode;
  placement?: LabelPlacement;
  helperText?: string;
  classes?: Partial<ClassNameMap<"root">>;
}

export const FormFieldLabel = forwardRef<HTMLLabelElement, FormFieldLabelProps>(
  ({ children, classes, htmlFor: htmlForProp, placement: placementProp = "top", ...props }, ref) => {
    const context = useFormFieldContext();
    const placement = context?.placement || placementProp;
    const { cx, classes: internalClasses } = useStyles({ placement });
    const htmlFor = context?.id || htmlForProp;

    return (
      <label htmlFor={htmlFor} ref={ref} className={cx(classes?.root, internalClasses.root)} {...props}>
        {children}
      </label>
    );
  },
);

FormFieldLabel.displayName = "FormFieldLabel";

const useStyles = tss.withParams<{ placement: LabelPlacement }>().create(({ theme, placement }) => ({
  root: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    lineHeight: 1.4,
    marginTop: placement === "right" ? "none" : undefined,
    wordBreak: "break-word",
  },
}));
