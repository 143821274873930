import { ConfigurationItem as ConfigurationItemType } from "@mob/shielder-metadata";
import { alpha, Box, CardContent, Collapse, Tooltip, Typography, useTheme } from "@mui/material";
import { useCallback, useState } from "react";
import { useFormState, useWatch } from "react-hook-form";
import { makeStyles } from "tss-react/mui";

import { useTranslate } from "../../../../../../../_common/i18n/hooks/use-translate.hook";
import { DeprecatedChip } from "../../../../../../../_common/ui/chip/deprecated";
import { RequiredChip } from "../../../../../../../_common/ui/chip/required";
import { StyledScreenReadersOnly } from "../../../../../../../_common/ui/screen-readers-only";
import { isParagraph } from "../../../../../../../_common/utils/is-paragraph";
import { DebugModeIcon } from "../../../ui/debug-mode-icon";
import { HelpButton } from "../../../ui/help-button";
import { NewItemLabel } from "../../../ui/new-item-label";
import { helperTextId } from "../../../utils/helper-text-id";
import { ConfigurationItemVisibility } from "./configuration-item-visibility";
import { ItemLayout } from "./items/item-layout";
import { ItemSelector } from "./items/item-selector";

interface LoadedConfigurationItemProps {
  first: boolean;
  isEditMode: boolean;
  isUpdating: boolean;
  item: ConfigurationItemType;
  newItem: boolean;
  validationDependency: string[] | undefined;
}
export const LoadedConfigurationItem = ({
  first,
  item,
  isEditMode,
  isUpdating,
  newItem,
  validationDependency,
}: LoadedConfigurationItemProps) => {
  const t = useTranslate();
  const theme = useTheme();
  const isDebugMode = useWatch({ name: "debugMode", exact: true }) as boolean;

  const { errors } = useFormState({ name: item.name, exact: true });
  const isError = Boolean(errors[item.name]);

  const [showDescription, setShowDescription] = useState(false);
  const toggleDescription = useCallback(() => setShowDescription(b => !b), []);

  const { classes } = useStyles({
    first,
    isDebugMode: (item.debugModeOnly ?? false) && isDebugMode,
    isEditMode,
  });

  const itemRequired = "validations" in item && item.validations && "required" in item.validations;
  const configurationItem = (
    <ItemLayout name={item.name}>
      <CardContent className={classes.cardContent}>
        <Box display="flex" justifyContent="space-between" alignItems="flex-start" gap={2.5}>
          <Box width="100%">
            {isEditMode && newItem && <NewItemLabel marginBottom={theme.spacing(2)} />}
            {item.label && (first || !isEditMode) && (
              <>
                {(item.deprecated || (itemRequired && isEditMode)) && (
                  <Box mb={isEditMode ? 1 : 0.5} display="flex" gap={1}>
                    {item.deprecated && <DeprecatedChip />}
                    {isEditMode && itemRequired && <RequiredChip error={isError} />}
                  </Box>
                )}
                <Typography component="h3" className={isEditMode ? classes.editModeLabel : classes.viewModeLabel}>
                  {item.label}
                  {!first && item.description && (
                    <HelpButton
                      aria-controls={helperTextId(item.name)}
                      itemLabel={item.label}
                      aria-expanded={showDescription}
                      onClick={toggleDescription}
                    />
                  )}
                </Typography>
              </>
            )}
            {!first && isEditMode && (
              <Box mb={0.5} display="flex" gap={1}>
                {item.deprecated && <DeprecatedChip />}
                {itemRequired && <RequiredChip error={isError} />}
              </Box>
            )}
            <ItemSelector
              first={first}
              item={item}
              disabled={isEditMode && item.debugModeOnly && !isDebugMode}
              isEditMode={isEditMode}
              isUpdating={isUpdating}
              toggleDescription={item.description ? toggleDescription : undefined}
              isDescriptionVisible={showDescription}
              validationDependency={validationDependency}
            />
          </Box>
          {item.debugModeOnly && isEditMode ? (
            <Tooltip
              title={
                <Typography className={classes.tooltip}>{t("common.configuration.debug-mode.icon.tooltip")}</Typography>
              }
            >
              <Box
                className={classes.debugModeIcon}
                tabIndex={isDebugMode ? 0 : undefined}
                aria-labelledby={`debug-icon-${item.name}`}
              >
                <DebugModeIcon debugMode={isDebugMode} smallIcon={!first} />
                <StyledScreenReadersOnly id={`debug-icon-${item.name}`}>
                  {t("common.configuration.debug-mode.icon.tooltip")}
                </StyledScreenReadersOnly>
              </Box>
            </Tooltip>
          ) : null}
        </Box>
        {!first && item.description && (
          <Collapse in={showDescription}>
            {showDescription && ( // this conditional is for the proper "aria-live" announcement
              <Typography
                component={isParagraph(item.description) ? "div" : "p"}
                aria-live="assertive"
                id={helperTextId(item.label)}
                color="textPrimary"
                className={classes.description}
                dangerouslySetInnerHTML={{ __html: item.description }}
              />
            )}
          </Collapse>
        )}
      </CardContent>
    </ItemLayout>
  );

  return item.invisible ? (
    <ConfigurationItemVisibility invisible={item.invisible} name={item.name}>
      {configurationItem}
    </ConfigurationItemVisibility>
  ) : (
    configurationItem
  );
};

type StyleProps = { first: boolean; isDebugMode: boolean; isEditMode: boolean };
const useStyles = makeStyles<StyleProps>()((theme, { first, isDebugMode, isEditMode }) => {
  let cardContentBackgroundColor;

  if (first && isDebugMode && isEditMode) {
    cardContentBackgroundColor = alpha(theme.palette.warning.light, 0.1);
  } else if (isDebugMode && isEditMode) {
    cardContentBackgroundColor = alpha(theme.palette.warning.light, 0.05);
  } else if (isEditMode) {
    cardContentBackgroundColor = theme.palette.grey[50];
  } else {
    cardContentBackgroundColor = "white";
  }

  const wordBreak = "break-word";

  return {
    cardContent: {
      padding: theme.spacing(2.5) + " !important",
      boxSizing: "border-box",
      minHeight: "auto",
      flexGrow: 1,
      borderBottom: `1px solid ${alpha(theme.palette.common.black, 0.1)}`,
      backgroundColor: cardContentBackgroundColor,
    },
    description: {
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 400,
      marginTop: theme.spacing(1),
      wordBreak,
      "& a": {
        color: theme.palette.primary.main,
      },
    },
    tooltip: {
      fontSize: "0.750rem",
      fontWeight: 400,
      lineHeight: "1rem",
    },
    editModeLabel: {
      fontWeight: 600,
      fontSize: theme.typography.pxToRem(16),
      marginBottom: theme.spacing(1),
      wordBreak,
    },
    viewModeLabel: {
      display: "flex",
      alignItems: "center",
      fontWeight: 600,
      textTransform: "uppercase",
      fontSize: theme.typography.pxToRem(12),
      wordBreak,
      marginBottom: theme.spacing(1),
    },
    debugModeIcon: {
      cursor: "pointer",
    },
  };
});
