import { ID } from "../../../../../../_common/types";
import { Platform } from "../../../shielders/types/platform";
import { ShielderStatus } from "../../../shielders/types/shielder-status";
import { Version } from "../../../shielders/types/version";

export interface CreateProjectInput {
  projectName: string;
  projectDescription: string;

  shielderId: ID;
  shielderPlatform: Platform;
  shielderVersion: Version;
  shielderStatus: ShielderStatus;
}

export function createProjectInputTransformer(input: CreateProjectInput): ApiCreateProjectInput {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { shielderStatus, shielderId, ...apiInput } = input;

  return {
    shielderId: +shielderId,
    ...apiInput,
  };
}

export interface ApiCreateProjectInput {
  projectName: string;
  projectDescription: string;

  shielderId: number;
  shielderPlatform: Platform;
  shielderVersion: Version;
}
