import { z } from "zod";

import { ConfigStatus } from "../../../configurations/api/types/config-status";
import { ApiCreateProject, createProjectTransformer } from "../create-project/create-project";

export const ApiDuplicateProject = ApiCreateProject.extend({
  configId: z.number(),
  configStatus: z.nativeEnum(ConfigStatus),
});

export type ApiDuplicateProject = z.input<typeof ApiDuplicateProject>;

function duplicateProjectTransformer<T extends z.infer<typeof ApiDuplicateProject>>({ configId, ...value }: T) {
  return createProjectTransformer({
    configId: configId.toString(),
    ...value,
  });
}

export const DuplicateProject = ApiDuplicateProject.transform(duplicateProjectTransformer);
export type DuplicateProject = z.infer<typeof DuplicateProject>;
