import { z } from "zod";

// This const it's being imported in the mock server
export const ApiCreateShielding = z.object({
  uuid: z.string(),
});
// This type it's being imported in the mock server
export type ApiCreateShielding = z.input<typeof ApiCreateShielding>;

function createShieldingTransformer<T extends z.infer<typeof ApiCreateShielding>>({ uuid }: T) {
  return {
    id: uuid,
  };
}

export const CreateShielding = ApiCreateShielding.transform(createShieldingTransformer);
export type CreateShielding = z.infer<typeof CreateShielding>;
