import { zodResolver } from "@hookform/resolvers/zod";
import { Security as SecurityIcon, Upload as UploadIcon } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useId } from "react";
import { useController, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { makeStyles } from "tss-react/mui";
import { z } from "zod";

import { addAction, AppShieldingActions, AppShieldingFeatures } from "../../../../../../../_common/datadog";
import { ENV } from "../../../../../../../_common/env-vars";
import { useTranslate } from "../../../../../../../_common/i18n/hooks/use-translate.hook";
import { i18n } from "../../../../../../../_common/i18n/i18n";
import { FileDropzone } from "../../../../../../../_common/ui/file-dropzone/file-dropzone";
import { ConfigStatus } from "../../../../configurations/api/types/config-status";
import { GetProject } from "../../../../projects/api/get-project";
import { ShielderStatus } from "../../../../shielders/types/shielder-status";
import { useCreateShielding } from "../../../api/create-shielding/use-create-shielding";
import { allowedShieldingExtensions } from "../../../utils/allowed-shielding-extensions";

interface ShieldingDropZoneProps {
  project: GetProject | undefined;
  isLoading?: boolean;
  isDisabled?: boolean;
  showEmptyState?: boolean;
}

const MAX_SHIELDING_NAME_LENGTH = 245;
const MAX_GB_SIZE = 4;
const MAX_SHIELDING_SIZE = !ENV.TEST ? MAX_GB_SIZE * 1024 * 1024 * 1024 : MAX_GB_SIZE * 1024;

type FormFields = {
  shieldingFile: File;
};

const formSchema = z.object({
  shieldingFile: z
    .custom<File>()
    .refine(
      file => (file?.name.length || 0) <= MAX_SHIELDING_NAME_LENGTH,
      i18n.intl.formatMessage(
        { id: "component.file-drop-zone.length-error-message" },
        { count: MAX_SHIELDING_NAME_LENGTH },
      ),
    )
    .refine(
      file => {
        return (file?.size || 0) <= MAX_SHIELDING_SIZE;
      },
      i18n.intl.formatMessage({ id: "component.file-drop-zone.size-error-message" }, { size: MAX_GB_SIZE, unit: "GB" }),
    )
    .refine(
      file => {
        return !/\s/g.test(file.name.trim());
      },
      i18n.intl.formatMessage({ id: "component.file-drop-zone.space-error-message" }),
    ),
});

export function ShieldingDropZone({ project, isLoading, isDisabled, showEmptyState }: ShieldingDropZoneProps) {
  const t = useTranslate();
  const uid = useId();
  const intl = useIntl();
  const { classes } = useStyles();
  const { mutate: createShielding, isPending: isMutating, isError: isMutatingError } = useCreateShielding(project?.id);

  const { control, handleSubmit } = useForm<FormFields>({
    resolver: zodResolver(formSchema),
    defaultValues: { shieldingFile: undefined },
  });
  const {
    field: { onChange, ...field },
    fieldState: { error: fieldError },
  } = useController({ name: "shieldingFile", control });

  const allowedExtensions = project
    ? {
        "application/octet-stream": allowedShieldingExtensions(project.shielderPlatform),
      }
    : undefined;
  const noConfiguration = project?.configStatus === ConfigStatus.None;
  const isUnsupportedShielder = project?.shielderStatus === ShielderStatus.Unsupported;
  const isDeletedShielder = project?.shielderStatus === ShielderStatus.Deleted;

  const handleSelectFile = (acceptedFiles: File[]) => {
    onChange(acceptedFiles[0]);
    void handleSubmit(({ shieldingFile }: FormFields) => {
      if (shieldingFile && project) {
        addAction(AppShieldingActions.UploadShielding, AppShieldingFeatures.ShieldingsList, {
          shielderVersion: project.shielderVersion,
          shielderPlatform: project.shielderPlatform,
        });

        return createShielding({
          projectId: project.id,
          configId: project.configId,
          shielderId: project.shielderId,
          shielderVersion: project.shielderVersion,
          shielderPlatform: project.shielderPlatform,
          file: shieldingFile,
        });
      }
    })();
  };

  const ariaLabel = noConfiguration
    ? t("component.shielding-drop-zone.drag-and-drop.no-configuration.message")
    : intl.formatMessage(
        { id: "component.shielding-drop-zone.drag-and-drop.message" },
        { label: t("component.shielding-drop-zone.drag-and-drop.label-message") },
      );

  return (
    <Box className={classes.container}>
      <FileDropzone
        aria-label={ariaLabel}
        onChange={handleSelectFile}
        size="large"
        mainText={
          noConfiguration ? (
            t("component.shielding-drop-zone.drag-and-drop.no-configuration.message")
          ) : (
            <FormattedMessage
              id="component.shielding-drop-zone.drag-and-drop.message"
              values={{
                label: <label htmlFor={uid}>{t("component.shielding-drop-zone.drag-and-drop.label-message")}</label>,
              }}
            />
          )
        }
        textIcon={showEmptyState ? undefined : UploadIcon}
        accept={noConfiguration ? undefined : allowedExtensions}
        inputProps={{ ...field, id: uid, value: "" }}
        topIcon={showEmptyState ? SecurityIcon : undefined}
        title={showEmptyState ? t("component.shielding-drop-zone.drag-and-drop.title") : undefined}
        dropText={t("component.shielding-drop-zone.drag-and-drop.on-drop-message")}
        errorMessage={isMutatingError ? t("component.file-drop-zone.file-upload-failed") : fieldError?.message}
        isLoading={isLoading || !project}
        isUpdating={isMutating}
        disabled={isDisabled || noConfiguration || isUnsupportedShielder || isDeletedShielder}
        maxLength={MAX_SHIELDING_NAME_LENGTH}
      />
    </Box>
  );
}

const useStyles = makeStyles()(theme => ({
  container: {
    padding: theme.spacing(2.5),
  },
}));
