import {
  DEFAULT_SORT_COLUMN_PARAM_NAME,
  DEFAULT_SORT_ORDER_PARAM_NAME,
} from "../../../../../../_common/ui/table/data-table/default-sort-param-names";
import { DEFAULT_PARAM_NAME as DEFAULT_PAGE_PARAM_NAME } from "../../../../../../_common/ui/table/paginator/default-param-name";
import { ProjectsListApiSortColumn } from "./types/projects-list-api-sort-column.enum";

export const PROJECTS_LIST_PAGE_PARAM_NAME = DEFAULT_PAGE_PARAM_NAME;
export const PROJECTS_LIST_SORT_COLUMN_PARAM_NAME = DEFAULT_SORT_COLUMN_PARAM_NAME;
export const PROJECTS_LIST_SORT_ORDER_PARAM_NAME = DEFAULT_SORT_ORDER_PARAM_NAME;
export const PROJECTS_LIST_DEFAULT_SORT_COLUMN = ProjectsListApiSortColumn.LastActivity;
