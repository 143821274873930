import { zodResolver } from "@hookform/resolvers/zod";
import { Save as SaveIcon } from "@mui/icons-material";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useParams } from "react-router-dom";
import { shallowEqualObjects } from "shallow-equal";

import { queryKeys } from "../../../../../../_common/api/query-keys/query-keys";
import { addAction, AppShieldingActions, AppShieldingFeatures } from "../../../../../../_common/datadog";
import { OnSubmitCallback, SideForm } from "../../../../../../_common/ui/side-form/side-form";
import { appShieldingRootPath } from "../../../../app-shielding-root-path";
import { useGetProject } from "../../api/get-project";
import { useUpdateProject } from "../../api/update-project/use-update-project";
import { EditProjectSummaryFormSchema } from "../../project-schemas/edit-project-summary-form-schema";
import { EditProjectSummaryFormContent } from "./components/edit-project-summary-form-content";

const getDefaultData = ({ projectName = "", projectDescription = "" } = {}) => ({
  projectName,
  projectDescription,
});

export const EditProjectSummaryPanel = () => {
  const params = new URLSearchParams(useLocation().search);
  const projectId = useParams<"projectId">().projectId! || params.get("id")!;

  const queryClient = useQueryClient();

  const formProps = useForm<EditProjectSummaryFormSchema>({
    defaultValues: getDefaultData(
      queryClient.getQueryData(queryKeys[appShieldingRootPath].projects().item(projectId).details()),
    ),
    resolver: zodResolver(EditProjectSummaryFormSchema),
  });

  const { isLoading, data: oldProject, isError } = useGetProject(projectId);

  useEffect(() => {
    formProps.reset(getDefaultData(oldProject));
  }, [formProps, oldProject]);

  const { mutateAsync: updateProjectAsync, error: mutationError } = useUpdateProject(projectId, oldProject);

  const onSubmit = useCallback<OnSubmitCallback<EditProjectSummaryFormSchema>>(
    input => {
      if (shallowEqualObjects(input, getDefaultData(oldProject))) {
        return Promise.resolve<unknown>({});
      }

      addAction(AppShieldingActions.EditProject, AppShieldingFeatures.EditProject);

      return updateProjectAsync({
        id: projectId,
        projectName: input.projectName,
        projectDescription: input.projectDescription ?? "",
        projectUpdatedAt: oldProject!.projectUpdatedAt,
      });
    },
    [oldProject, updateProjectAsync, projectId],
  );

  return (
    <SideForm
      formProps={formProps}
      isLoading={isLoading || isError}
      mutationErrors={mutationError}
      onSubmit={onSubmit}
      submitButtonProps={{ startIcon: <SaveIcon /> }}
      cancelNavigationPath=".."
      successNavigationPath=".."
      translationComponentName="edit-project-summary"
    >
      <EditProjectSummaryFormContent loading={isLoading || isError} />
    </SideForm>
  );
};
