import { Chip, ChipProps } from "@mui/material";

import { useTranslate } from "../../i18n/hooks/use-translate.hook";

interface RequiredChipProps {
  error?: boolean;
  size?: ChipProps["size"];
}
export const RequiredChip = ({ error, size = "small" }: RequiredChipProps) => {
  const t = useTranslate();

  return <Chip color={error ? "error" : "warning"} size={size} label={t("word.required")} />;
};
