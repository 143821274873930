import { ArrowDropDown as ArrowDropDownIcon, ArrowDropUp as ArrowDropUpIcon } from "@mui/icons-material";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

import { SortOrder } from "../../../api/methods/get-many-with-pagination";
import { useHasTranslationKey } from "../../../i18n/hooks/use-has-translation-key";
import { useTranslate } from "../../../i18n/hooks/use-translate.hook";
import { Sorting } from "./types";
import { useTableSort } from "./use-table-sort.hook";

export function SortingIcon<T extends string, P extends string>({
  sorting,
  sortColumn,
  sortOrder,
}: ReturnType<typeof useTableSort> & { sorting: Sorting<T, P> }) {
  const t = useTranslate();
  const hasTranslation = useHasTranslationKey();
  const displayBoth = sortColumn !== sorting.apiColumnName;
  const displayUp = sortColumn === sorting.apiColumnName && sortOrder === SortOrder.Asc;
  const displayDown = sortColumn === sorting.apiColumnName && sortOrder === SortOrder.Desc;
  const key = `component.project-list.table.column-aria-label.${sorting.urlParamName}`;
  const ariaLabel = hasTranslation(key) ? t(key) : undefined;

  return (
    <Box display="flex" alignItems="center" aria-label={ariaLabel}>
      <Box height={20} position="relative" width={20}>
        <Box height={10} width={20}>
          {(displayBoth || displayUp) && <StyledSortUpIcon sx={{ bottom: 3 }} />}
        </Box>
        <Box height={10} width={20}>
          {(displayBoth || displayDown) && <StyledSortDownIcon sx={{ top: 3 }} />}
        </Box>
      </Box>
    </Box>
  );
}

const StyledSortUpIcon = styled(ArrowDropUpIcon)(({ theme }) => ({
  color: theme.palette.text.primary,
  height: 20,
  position: "absolute",
}));

const StyledSortDownIcon = StyledSortUpIcon.withComponent(ArrowDropDownIcon);
