import type { ConfigurationBlock as ConfigurationBlockType } from "@mob/shielder-metadata";
import { useMemo } from "react";

import { ValidationDependencies } from "../../../configuration-schema/generators/get-validation-dependencies";
import { ConfigurationBlock } from "../block/configuration-block";
import { ConfigurationBlockDescription } from "../block/configuration-block-description";
import { ConfigurationSectionUi } from "./configuration-section-ui";

interface ConfigurationSectionProps {
  name: string;
  title: string;
  blocks: ConfigurationBlockType[];
  isEditMode: boolean;
  isLoading: boolean;
  isUpdating: boolean;
  first?: boolean;
  newItems: string[] | undefined;
  validationDependencies: ValidationDependencies;
}

export function ConfigurationSection({
  name,
  title,
  blocks,
  isEditMode,
  isLoading,
  isUpdating,
  newItems,
  validationDependencies,
}: ConfigurationSectionProps) {
  const descriptions = useMemo(
    () =>
      blocks
        .filter(block => block.items[0].description)
        .map((block, index) => (
          <ConfigurationBlockDescription
            key={block.name}
            first={index === 0}
            text={block.items[0].description!}
            label={blocks.length > 1 ? block.items[0].label : undefined}
            sectionTitle={title}
          />
        )),
    [blocks, title],
  );
  const content = blocks.map(block => (
    <ConfigurationBlock
      key={block.name}
      name={block.name}
      isEditMode={isEditMode}
      items={block.items}
      isLoading={isLoading}
      isUpdating={isUpdating}
      newItems={newItems}
      validationDependencies={validationDependencies}
    />
  ));

  return (
    <ConfigurationSectionUi
      name={name}
      title={title}
      descriptions={descriptions.length > 0 ? descriptions : undefined}
      content={content}
    />
  );
}
