import { useMutation } from "@tanstack/react-query";

import { ApiEntity } from "../../../../../_common/api/api-entity";
import { MutationError } from "../../../../../_common/api/errors/mutation-error";
import { downloadOne } from "../../../../../_common/api/methods/download-one";
import { queryKeys } from "../../../../../_common/api/query-keys/query-keys";
import { useRestClient } from "../../../../../_common/api/rest-client/rest-client-context";
import { useTranslate } from "../../../../../_common/i18n/hooks/use-translate.hook";
import { ID } from "../../../../../_common/types";
import { appShieldingRootPath } from "../../../app-shielding-root-path";
import { Platform } from "../types/platform";
import { ShielderI18nToastKey } from "./_support/shielder-i18n-toast-key";

const platform = {
  [Platform.Android]: "Android",
  [Platform.Ios]: "iOS",
};

// Used by the mock server
export const sdkFileName = (shielderPlatform: Platform, shielderVersion: string) =>
  `App_Shielding_${platform[shielderPlatform]}_${shielderVersion}.zip`;

export const useDownloadSdk = ({
  shielderId,
  shielderPlatform,
  shielderVersion,
}: {
  shielderId: ID;
  shielderPlatform: Platform;
  shielderVersion: string;
}) => {
  const t = useTranslate();
  const restClient = useRestClient();

  return useMutation<void, MutationError<unknown>, ID>({
    mutationKey: queryKeys[appShieldingRootPath].shielders().item(shielderId).sdk(),
    mutationFn: (shielderId: ID) =>
      downloadOne(restClient, ApiEntity.Shielder, shielderId, sdkFileName(shielderPlatform, shielderVersion)),
    meta: {
      i18nToastKey: ShielderI18nToastKey.DOWNLOAD_SDK,
      resourceName: t(`component.shielder.${shielderPlatform}`, { version: shielderVersion }),
    },
  });
};
