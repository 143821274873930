import { useMutation, useQueryClient } from "@tanstack/react-query";

import { ApiEntity } from "../../../../../../_common/api/api-entity";
import { MutationError } from "../../../../../../_common/api/errors/mutation-error";
import { putOne } from "../../../../../../_common/api/methods/put-one";
import { queryKeys } from "../../../../../../_common/api/query-keys/query-keys";
import { useRestClient } from "../../../../../../_common/api/rest-client/rest-client-context";
import { appShieldingRootPath } from "../../../../app-shielding-root-path";
import { GetProject } from "../../../projects/api/get-project";
import { AccessApiKeyI18nToastKey } from "../_support/access-api-key-i18n-toast-key";
import { DeleteAccessApiKey } from "./delete-access-api-key";
import { DeleteAccessApiKeyInput, deleteAccessApiKeyInputTransformer } from "./delete-access-api-key-input";

export function useDeleteAccessApiKey(project?: GetProject) {
  const queryClient = useQueryClient();
  const restClient = useRestClient();

  return useMutation<DeleteAccessApiKey, MutationError<DeleteAccessApiKeyInput>, DeleteAccessApiKeyInput>({
    mutationKey: queryKeys[appShieldingRootPath]
      .projects()
      .item(project?.id ?? "")
      .accessApiKey()
      .revoke(),
    mutationFn: variables =>
      putOne(
        restClient,
        ApiEntity.AccessApiKey,
        variables.apiKeyId,
        deleteAccessApiKeyInputTransformer(variables.projectId),
        DeleteAccessApiKey,
        [204, 404],
      ),

    onSuccess() {
      void queryClient.invalidateQueries({ queryKey: queryKeys[appShieldingRootPath].projects().lists() });
      void queryClient.invalidateQueries({
        queryKey: queryKeys[appShieldingRootPath].projects().item(project!.id).details(),
      });
    },
    meta: { i18nToastKey: AccessApiKeyI18nToastKey.DELETE_API_KEY },
  });
}
