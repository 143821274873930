import { createContext, useContext } from "react";

type FormFieldContextProps = {
  fullWidth?: boolean;
  helperTextId?: string;
  id?: string;
  placement: "top" | "right";
  status?: "error" | "success" | "warning";
};

export const FormFieldContext = createContext<Partial<FormFieldContextProps>>({});

export const useFormFieldContext = (): Partial<FormFieldContextProps> => {
  return useContext(FormFieldContext);
};
