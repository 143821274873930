import { InvalidationKeys } from "../../../../_common/api/query-keys/invalidation-keys.type";
import { QueryKey } from "../../../../_common/api/query-keys/query-key.type";
import {
  clientCredentialsQueryKeysFactory,
  clientCredentialsRootQueryKey,
  considerClientCredentialsQueryKey404As500,
  invalidationClientCredentialsKeysOn404,
} from "../../features/client-credentials/api/_support/client-credentials-query-keys-factory";

export const settingsQueryKeys = {
  [clientCredentialsRootQueryKey]: clientCredentialsQueryKeysFactory,
} as const;

const considerMap = {
  [clientCredentialsRootQueryKey]: considerClientCredentialsQueryKey404As500,
};

export const considerSettingsQueryKey404As500 = (key: QueryKey) => considerMap[key[1] as keyof typeof considerMap](key);

const invalidationMap = {
  [clientCredentialsRootQueryKey]: invalidationClientCredentialsKeysOn404,
};

export const invalidationSettingsKeysOn404 = (key: QueryKey): InvalidationKeys =>
  invalidationMap[key[1] as keyof typeof invalidationMap](key);
