import { z } from "zod";

// Baked-in variables
export const IS_PROD = import.meta.env.PROD;
export const IS_DEV = import.meta.env.DEV;

// Received via window.env variables

const defaultTransformer =
  <T extends string | undefined>(name: string, defaultValue: T) =>
  (value: string | undefined) =>
    [undefined, "", `%${name}%`, `%VITE_${name}%`].includes(value) ? defaultValue : value!;

const requiredValidator = (name: string) => (value: string) => value !== `%${name}%`;

const booleanTransformer = (value: string | undefined) => (<(string | undefined)[]>["1", "t", "true"]).includes(value);

const NOT_PROVIDED = "not provided";

const WindowEnvVars = z.object({
  API_BASE: z.string().min(1).refine(requiredValidator("API_BASE"), NOT_PROVIDED),
  API_DOC_URL: z.string().optional().transform(defaultTransformer("API_DOC_URL", undefined)),
  COGNITO_HOST: z.string().min(1).refine(requiredValidator("COGNITO_HOST"), NOT_PROVIDED),
  COGNITO_CLIENT_ID: z.string().min(1).refine(requiredValidator("COGNITO_CLIENT_ID"), NOT_PROVIDED),
  COGNITO_CALLBACK_PATH: z.string().optional().transform(defaultTransformer("COGNITO_CALLBACK_PATH", "callback")),
  DATADOG_APPLICATION_ID: z.string().optional().transform(defaultTransformer("DATADOG_APPLICATION_ID", "")),
  DATADOG_CLIENT_TOKEN: z.string().optional().transform(defaultTransformer("DATADOG_APPLICATION_ID", "")),
  ENVIRONMENT: z.string().optional().transform(defaultTransformer("ENVIRONMENT", "localhost")),
  NO_API_RETRY: z.string().optional().transform(booleanTransformer),
  NO_TOAST_AUTOCLOSE_AND_TRANSITION: z.string().optional().transform(booleanTransformer),
  TEST: z.string().optional().transform(booleanTransformer),
});

const { NO_API_RETRY, ...ENV } = WindowEnvVars.parse(window.env);
export { ENV };
export const API_RETRY = !NO_API_RETRY;
