import { ID } from "../../../../../../_common/types";

export interface DeleteAccessApiKeyInput {
  projectId: ID;
  apiKeyId: ID;
}

interface ApiDeleteAccessApiKeyInput {
  projectId: number;
  actionType: "DELETE";
}

export const deleteAccessApiKeyInputTransformer = (projectId: ID): ApiDeleteAccessApiKeyInput => {
  return {
    projectId: +projectId,
    actionType: "DELETE",
  };
};
