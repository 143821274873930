import { Typography, TypographyProps } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const CodeBlock = ({ className, ...props }: TypographyProps) => {
  const { cx, classes } = useStyles();

  return <Typography {...props} className={cx(classes.root, className)} />;
};

const useStyles = makeStyles()(theme => ({
  root: {
    backgroundColor: theme.palette.grey[200],
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.primary,
    fontFamily: "monospace, lucida console, system-ui, sans-serif",
    padding: `${theme.spacing(1.25)} ${theme.spacing(2)}`,
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    border: `1px solid ${theme.palette.grey[500]}`,
  },
}));
