import {
  ChevronRight as ChevronRightIcon,
  Close as CloseIcon,
  Construction as ConstructionIcon,
  Settings as SettingsIcon,
  Shield as ShieldIcon,
} from "@mui/icons-material";
import { alpha, Box, Divider, Drawer, List } from "@mui/material";
import { Dispatch, SetStateAction, useCallback } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { appShieldingRootPath } from "../../../products/app-shielding/app-shielding-root-path";
import { onPremShieldersRootPath } from "../../../products/on-prem-shielders/on-prem-shielders-root-path";
import { settingsRootPath } from "../../../products/settings/settings-root-path";
import { useAuthUserProfile } from "../../auth/auth-provider/auth-user-profile-context";
import { addAction, GeneralActions, GeneralFeatures } from "../../datadog";
import { useTranslate } from "../../i18n/hooks/use-translate.hook";
import { SIDE_BAR_GRID_CLOSED_WIDTH, SIDE_BAR_GRID_OPENED_WIDTH } from "../constants";
import { sideBarTransition, TransitionType } from "../theme/transitions";
import { LogoMenuItem } from "./logo-menu-item";
import { MenuItem } from "./menu-item";
import { UserProfile } from "./user-profile";

type SideBarProps = {
  open: boolean;
  setSideBarOpen: Dispatch<SetStateAction<boolean>>;
};

export const SideBar = ({ open, setSideBarOpen }: SideBarProps) => {
  const { classes } = useStyles({ open });
  const t = useTranslate();
  const {
    roles: { hasAppShielding, hasOnPremShielders: hasAppShieldingTools, hasSettings },
  } = useAuthUserProfile();

  const handleToggleDrawer = useCallback(() => {
    return setSideBarOpen(open => {
      addAction(open ? GeneralActions.CollapseSidebar : GeneralActions.ExpandSidebar, GeneralFeatures.SideBar);

      return !open;
    });
  }, [setSideBarOpen]);

  return (
    <>
      <div className={classes.ghost}></div>
      <Drawer
        classes={{ paper: classes.drawerPaper }}
        open={open}
        elevation={0}
        variant="permanent"
        onClose={handleToggleDrawer}
        PaperProps={{ "data-testid": "side-bar" }}
      >
        <List sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <LogoMenuItem open={open} />
          <Divider />
          {hasAppShielding && (
            <MenuItem
              open={open}
              icon={<ShieldIcon />}
              label={t("component.sidebar.app-shielding")}
              buttonProps={{
                component: Link,
                to: "/" + appShieldingRootPath,
                onClick: () => {
                  addAction(GeneralActions.ViewAppShielding, GeneralFeatures.SideBar);
                },
              }}
            />
          )}
          {hasAppShieldingTools && (
            <MenuItem
              open={open}
              icon={<ConstructionIcon />}
              label={t("component.sidebar.app-shielding-tool")}
              buttonProps={{
                component: Link,
                to: "/" + onPremShieldersRootPath,
                onClick: () => {
                  addAction(GeneralActions.ViewAppShieldingTool, GeneralFeatures.SideBar);
                },
              }}
            />
          )}
          {hasSettings && (
            <MenuItem
              open={open}
              icon={<SettingsIcon />}
              label={t("component.sidebar.settings")}
              buttonProps={{
                component: Link,
                to: "/" + settingsRootPath,
                onClick: () => {
                  addAction(GeneralActions.ViewSettings, GeneralFeatures.SideBar);
                },
              }}
            />
          )}
          <Box flex={1} />
          <UserProfile sideBarOpen={open} />
          <Divider />
          <MenuItem
            open={open}
            icon={open ? <CloseIcon /> : <ChevronRightIcon />}
            label={open ? t("word.close") : ""}
            buttonProps={{
              onClick: handleToggleDrawer,
              ...(!open && { "aria-label": t("word.open") }),
            }}
          />
        </List>
      </Drawer>
    </>
  );
};

const useStyles = makeStyles<{ open: boolean }>()((theme, { open }) => {
  const transition = open
    ? {
        width: theme.spacing(SIDE_BAR_GRID_OPENED_WIDTH),
        transition: sideBarTransition(theme, TransitionType.Open, "width"),
      }
    : {
        width: theme.spacing(SIDE_BAR_GRID_CLOSED_WIDTH),
        transition: sideBarTransition(theme, TransitionType.Close, "width"),
      };

  return {
    drawerPaper: {
      borderRight: `1px solid ${alpha(theme.palette.common.black, 0.1)}`,
      overflow: "hidden",
      position: "fixed",
      ...transition,
    },
    ghost: {
      position: "sticky",
      height: "100%",
      flexShrink: 0,
      ...transition,
    },
  };
});
