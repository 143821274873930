import { z } from "zod";

export const ApiCreateAccessApiKey = z.object({
  apiKeyId: z.number(),
  apiKey: z.string(),
});

export type ApiCreateAccessApiKey = z.input<typeof ApiCreateAccessApiKey>;

export const CreateAccessApiKey = ApiCreateAccessApiKey.transform(({ apiKeyId, ...value }) => ({
  apiKeyId: apiKeyId.toString(),
  ...value,
}));
export type CreateAccessApiKey = z.infer<typeof CreateAccessApiKey>;
