import { z } from "zod";

export const ApiValidationErrorsSchema = z.object({
  errors: z
    .array(
      z.object({
        errorCode: z.number().int().positive(),
        errorDescription: z.string(),
        fieldName: z.string().optional(),
        handler: z.string().optional(),
      }),
    )
    .min(1),
});
export type ApiValidationErrorsSchema = z.infer<typeof ApiValidationErrorsSchema>;
