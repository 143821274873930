import { AxiosInstance } from "axios";
import { ZodType, ZodTypeDef } from "zod";

import type { ID } from "../../types";
import { searchParams } from "../../utils/search-params";
import { ApiEntity } from "../api-entity";
import { getRequest } from "./utils/get-request";

export function getOne<TItem, TApiItem, Def extends ZodTypeDef = ZodTypeDef>(
  restClient: AxiosInstance,
  apiEntity: ApiEntity,
  entityId: ID | undefined,
  itemType: ZodType<TItem, Def, TApiItem>,
  queryParams?: Record<string, string>,
) {
  const params = searchParams(queryParams ?? {});
  const url = entityId ? `${apiEntity}/${entityId}${params}` : `${apiEntity}${params}`;
  return getRequest(restClient, url, itemType);
}
