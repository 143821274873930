export enum AppShieldingFeatures {
  ProjectList = "projects-list",
  ProjectsDetails = "project-details",
  CreateProject = "create-project",
  EditProject = "edit-project",
  DuplicateProject = "duplicate-project",
  DeleteProject = "delete-project",
  Configurations = "configurations",
  ChangeShielder = "change-shielder",
  ShieldingsList = "shieldings-list",
  ShieldingsDetails = "shielding-details",
}

export enum AppShieldingActions {
  OpenCreateProject = "open-create-project",
  OpenEditProject = "open-edit-project",
  OpenDuplicateProject = "open-duplicate-project",
  OpenDeleteProject = "open-delete-project",
  ViewProjectDetails = "view-project-details",
  DownloadShielderSdk = "download-shielder-sdk",
  CopyConfigurationKeyToClipboard = "copy-configuration-key-to-clipboard",
  OpenRevokeConfigurationKey = "open-revoke-configuration-key",
  GoToApiDocumentation = "go-to-api-documentation",
  RevokeConfigurationKey = "revoke-configuration-key",
  GenerateConfigurationKey = "generate-configuration-key",
  CreateConfiguration = "create-configuration",
  ViewConfiguration = "view-configuration",
  OpenChangeShielder = "open-change-shielder",
  NavigateBack = "navigate-back",
  CreateProject = "create-project",
  EditProject = "edit-project",
  DuplicateProject = "duplicate-project",
  DeleteProject = "delete-project",
  JumpToConfigurationError = "jump-to-configuration-error",
  JumpToPreviousConfigurationError = "jump-to-previous-configuration-error",
  JumpToNextConfigurationError = "jump-to-next-configuration-error",
  EditConfiguration = "edit-configuration",
  OpenEditConfiguration = "open-edit-configuration",
  CancelEditConfiguration = "cancel-edit-configuration",
  EnableDebugMode = "enable-debug-mode",
  DisableDebugMode = "disable-debug-mode",
  ChangeShielder = "change-shielder",
  DownloadShielding = "download-shielding",
  UploadShielding = "upload-shielding",
  ViewShieldingDetails = "view-shielding-details",
}
