import { i18n } from "../i18n/i18n";
import { ApiCallResult, ApiCallType, getApiCallToastI18nKey } from "../i18n/utils/get-api-call-toast-i18n-key";
import { hasTranslationKey } from "../i18n/utils/has-translation-key";
import { Meta } from "./types";

export const getSuccessTranslationMessage = (i18nToastKey: Meta["i18nToastKey"]) => {
  const messageKey = i18nToastKey && getApiCallToastI18nKey(ApiCallType.Mutation, ApiCallResult.Success, i18nToastKey);
  if (messageKey && hasTranslationKey(messageKey)) {
    return i18n.intl.formatMessage({ id: messageKey });
  }

  return undefined;
};
