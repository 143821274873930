import { Close as CloseIcon } from "@mui/icons-material";
import { Box, DrawerProps, IconButton, Typography } from "@mui/material";
import { MouseEvent, ReactNode } from "react";
import { makeStyles } from "tss-react/mui";

import { createBoxShadow } from "../utils/create-box-shadow";

export const PanelHeader = ({ heading, closeHandler }: PanelHeadingProps) => {
  const { classes } = useStyles();
  return (
    <Box className={classes.root}>
      <Typography component="h2" className={classes.heading}>
        {heading}
      </Typography>

      <div>
        <IconButton
          data-testid="panel-header-close-button"
          size="small"
          onClick={closeHandler ? e => closeHandler(e, "headerCloseClick") : undefined}
        >
          <CloseIcon />
        </IconButton>
      </div>
    </Box>
  );
};

const useStyles = makeStyles()(theme => ({
  root: {
    padding: theme.spacing(2.5),
    boxShadow: createBoxShadow(theme.palette.common.black),
    maxHeight: 64,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  heading: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(16),
  },
}));

export type PanelCloseCallback = (
  e: MouseEvent<HTMLButtonElement>,
  actionSource: Parameters<NonNullable<DrawerProps["onClose"]>>[1] | "headerCloseClick" | "footerCloseClick",
) => void;

interface PanelHeadingProps {
  heading?: ReactNode;
  closeHandler: PanelCloseCallback;
}
