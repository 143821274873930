import { ConfigurationItem as ConfigurationItemType } from "@mob/shielder-metadata";

import { CardSection } from "../../../../../../../_common/ui/card/card-section";
import { TextSkeleton } from "../../../../../../../_common/ui/skeleton/text-skeleton";
import { ItemLayout } from "./items/item-layout";
import { LoadedConfigurationItem } from "./loaded-configuration-item";

interface ConfigurationItemProps {
  first: boolean;
  isEditMode: boolean;
  isLoading: boolean;
  isUpdating: boolean;
  item: ConfigurationItemType;
  newItem: boolean;
  validationDependency: string[] | undefined;
}
export const ConfigurationItem = ({
  first,
  item,
  isEditMode,
  isLoading,
  isUpdating,
  newItem,
  validationDependency,
}: ConfigurationItemProps) =>
  isLoading ? (
    <ItemLayout name={item.name}>
      <CardSection title={item.label} description={<TextSkeleton />} borderBottom />
    </ItemLayout>
  ) : (
    <LoadedConfigurationItem
      first={first}
      isEditMode={isEditMode}
      isUpdating={isUpdating}
      item={item}
      newItem={newItem}
      validationDependency={validationDependency}
    />
  );
