import { ConfigurationItem as ConfigurationItemType } from "@mob/shielder-metadata";

import { considerItemAsNew } from "../utils/consider-item-as-new";

export function getNewConfigurationItemsFromNewShielder(
  oldItems: ConfigurationItemType[],
  newItems: ConfigurationItemType[],
) {
  return newItems && oldItems
    ? newItems
        .filter(item =>
          considerItemAsNew(
            item,
            oldItems.find(oldItem => oldItem.name === item.name),
          ),
        )
        .map(item => item.name)
    : [];
}
