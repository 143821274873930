import { AxiosInstance } from "axios";
import { z, ZodTypeDef } from "zod";

import { searchParams } from "../../utils/search-params";
import { ApiEntity } from "../api-entity";
import { ListData, listData } from "./get-many";
import { getRequest } from "./utils/get-request";

export enum SortOrder {
  Asc = "ASC",
  Desc = "DESC",
}

export type SortingAndPaginationParams<EntitySortColumn extends string | number> = {
  page: number;
  itemsPerPage?: number;
  order: SortOrder;
  orderBy: EntitySortColumn;
};

export function listDataWithPagination<TItem extends z.ZodTypeAny>(item: TItem) {
  return listData(item).extend({
    pagination: z.object({
      page: z.number().int().nonnegative(),
      total: z.number().int().nonnegative(),
    }),
  });
}

export interface ListDataWithPagination<TApiItem> extends ListData<TApiItem> {
  pagination: {
    page: number;
    total: number;
  };
}

export function getManyWithPagination<
  TItem,
  TApiItem,
  EntitySortColumn extends string | number,
  Def extends ZodTypeDef = ZodTypeDef,
>(
  restClient: AxiosInstance,
  apiEntity: ApiEntity,
  sortingAndPaginationParams: SortingAndPaginationParams<EntitySortColumn>,
  itemType: z.ZodType<TItem, Def, TApiItem>,
) {
  return getRequest(
    restClient,
    `${apiEntity}${searchParams(sortingAndPaginationParams)}`,
    listDataWithPagination(itemType),
  );
}
