import { Button, ButtonProps } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { ENV } from "../../../../../_common/env-vars";
import { useTranslate } from "../../../../../_common/i18n/hooks/use-translate.hook";

interface ApiAccessDocumentationButtonProps {
  size?: ButtonProps["size"];
  onClick: () => void;
}
export const ApiAccessDocumentationButton = ({ size, onClick }: ApiAccessDocumentationButtonProps) => {
  const t = useTranslate();
  const { classes } = useStyles();

  return (
    ENV.API_DOC_URL && (
      <Button
        className={classes.link}
        variant="text"
        size={size}
        color="primary"
        href={ENV.API_DOC_URL}
        target="_blank"
        onClick={onClick}
      >
        {t("component.api-documentation.button")}
      </Button>
    )
  );
};

const useStyles = makeStyles()(theme => ({
  link: {
    fontWeight: 400,
    color: theme.palette.primary.main + "!important",
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
  },
}));
