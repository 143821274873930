import { TextSkeleton } from "../../../../../../../../_common/ui/skeleton/text-skeleton";
import { ColumnCellProps } from "../../../../../../../../_common/ui/table/data-table";
import { useGetShieldingProgress } from "../../../../api/get-shielding-progress/use-get-shielding-progress";
import { GetShieldingsListItem } from "../../../../api/get-shieldings-list-item";
import { ShieldingStatusTag } from "../../../shielding-status-tag";
import { ShieldingsListContext } from "../../shieldings-list-context.type";

type StartedCellProps = ColumnCellProps<GetShieldingsListItem, ShieldingsListContext>;

export const StatusCell = ({ isLoading: isLoadingList, context: project, ...shielding }: StartedCellProps) => {
  const { shieldingStatus } = shielding;
  const { data } = useGetShieldingProgress({ shielding, project });

  return isLoadingList ? (
    <TextSkeleton height="24px" />
  ) : (
    <ShieldingStatusTag status={shieldingStatus} progress={data?.progressInPercent} />
  );
};
