import { ConfigurationItem as ConfigurationItemType } from "@mob/shielder-metadata";

import { isNotFalsy } from "../../../../../../_common/utils/is-not-falsy";
import { ConfigValues } from "../../api/types/config-values";
import { considerItemAsNew } from "../utils/consider-item-as-new";

interface GenerateFilterOutOldConfigValuesFunctionParams {
  oldItems: ConfigurationItemType[];
  newItems: ConfigurationItemType[];
}
export const generateFilterOutOldConfigValuesFunction =
  ({ oldItems, newItems }: GenerateFilterOutOldConfigValuesFunctionParams) =>
  (configValues: ConfigValues) =>
    configValues
      ? (Object.fromEntries(
          Object.entries(configValues)
            .map(([name, value]) =>
              // debugMode is artificial parameter that doesn't belong to configValues but we added it only for form
              // management because other form parameters depend on it. So, just keep it as is
              name !== "debugMode" &&
              considerItemAsNew(
                newItems.find(item => item.name === name),
                oldItems.find(item => item.name === name),
              )
                ? null
                : [name, value],
            )
            .filter(isNotFalsy),
        ) as ConfigValues)
      : undefined;
