import { useMutation } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import { ApiEntity } from "../../../../../_common/api/api-entity";
import { MutationError } from "../../../../../_common/api/errors/mutation-error";
import { downloadOne } from "../../../../../_common/api/methods/download-one";
import { queryKeys } from "../../../../../_common/api/query-keys/query-keys";
import { useRestClient } from "../../../../../_common/api/rest-client/rest-client-context";
import { ID } from "../../../../../_common/types";
import { appShieldingRootPath } from "../../../app-shielding-root-path";
import { ShieldingI18nToastKey } from "./_support/shielding-i18n-toast-key";

export const useDownloadShielding = (shieldingId: ID) => {
  const { projectId } = useParams<"projectId">();
  const restClient = useRestClient();

  return useMutation<void, MutationError<unknown>, { shieldingId: ID; fileName: string }>({
    mutationKey: queryKeys[appShieldingRootPath].projects().item(projectId).shieldings().item(shieldingId).download(),
    mutationFn: ({ shieldingId, fileName }) =>
      downloadOne(restClient, ApiEntity.Shielding, undefined, fileName, { uuid: shieldingId }),
    meta: {
      i18nToastKey: ShieldingI18nToastKey.DOWNLOAD_SHIELDING,
      skipNavigationOnNotFound: true,
    },
  });
};
