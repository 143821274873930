import { hashKey } from "@tanstack/react-query";

import { QueryKey } from "../../../../../../_common/api/query-keys/query-key.type";
import { ID } from "../../../../../../_common/types";
import { appShieldingRootPath } from "../../../../app-shielding-root-path";
import { ShielderStatus } from "../../types/shielder-status";

export const shieldersRootQueryKey = "shielders";

export const shieldersQueryKeysFactory = () => ({
  all: () => [appShieldingRootPath, shieldersRootQueryKey] as const,
  lists: () => [...shieldersQueryKeysFactory().all(), "list"] as const,
  list: (statuses: ShielderStatus[]) =>
    [...shieldersQueryKeysFactory().lists(), Object.fromEntries(statuses.map(status => [status, true]))] as const,

  items: () => [...shieldersQueryKeysFactory().all(), "items"] as const,
  item: (shielderId: ID | undefined) => {
    const shielderItem = [...shieldersQueryKeysFactory().items(), shielderId] as const;

    return {
      everything: () => shielderItem,
      details: () => [...shielderItem, "details"] as const,
      sdk: () => [...shielderItem, "sdk"] as const,
    };
  },
});

// Valid 404 error can be only on project item or its descendants
// Other 404 errors are considered as BE bug => 500
export const considerShieldersQueryKey404As500 = (key: QueryKey) => {
  const all = shieldersQueryKeysFactory().all();
  const items = shieldersQueryKeysFactory().items();

  return hashKey(all) === hashKey(key.slice(0, all.length)) && hashKey(items) !== hashKey(key.slice(0, items.length));
};

export const invalidationShieldersKeysOn404 = (key: QueryKey) => ({
  invalidateKey: shieldersQueryKeysFactory().lists(),
  removeKey: key.slice(0, shieldersQueryKeysFactory().item("any").everything().length),
});
