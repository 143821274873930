import { z } from "zod";

import { DateTime } from "../../../../../../_common/types";

export const ApiCreateProject = z.object({
  projectId: z.number(),

  projectCreatedAt: DateTime,
  projectUpdatedAt: DateTime,
});
export type ApiCreateProject = z.input<typeof ApiCreateProject>;

export function createProjectTransformer<T extends z.infer<typeof ApiCreateProject>>({ projectId, ...value }: T) {
  return {
    id: projectId.toString(),
    ...value,
  };
}

export const CreateProject = ApiCreateProject.transform(createProjectTransformer);
export type CreateProject = z.infer<typeof CreateProject>;
