import { noRoles } from "./no-roles";
import { UserProfile } from "./user-profile";

export const noUserProfile: UserProfile = {
  id: "",
  accountId: "",
  name: "",
  email: "",
  company: "",
  roles: noRoles,
};
