import { SvgIconComponent } from "@mui/icons-material";
import { alpha, Box, CircularProgress, Typography } from "@mui/material";
import { PaletteColor } from "@mui/material/styles";
import React from "react";
import { makeStyles } from "tss-react/mui";

type Colors = "primary" | "secondary" | "error" | "warning" | "info" | "success";
type ThemeColor = `${Colors}.${keyof PaletteColor}`;

export interface Margins {
  margin?: React.CSSProperties["margin"];
  marginTop?: React.CSSProperties["marginTop"];
  marginBottom?: React.CSSProperties["marginBottom"];
  marginLeft?: React.CSSProperties["marginLeft"];
  marginRight?: React.CSSProperties["marginRight"];
}

type TagColor = ThemeColor | Exclude<React.CSSProperties["color"], undefined>;

interface TagProps extends Margins {
  icon?: SvgIconComponent;
  text: string;
  color: TagColor;
  fontWeight?: React.CSSProperties["fontWeight"];
  isLoading?: boolean;
  "data-testid"?: string;
}

export function Tag({
  icon: Icon,
  text,
  color,
  fontWeight = 500,
  isLoading,
  ["data-testid"]: dataTestId,
  ...margins
}: TagProps) {
  const { classes } = useStyles({ fontWeight, color, ...margins });

  return (
    <Box className={classes.wrapper} data-testid={dataTestId}>
      {!isLoading ? Icon && <Icon className={classes.icon} /> : <CircularProgress size={16} />}
      <Typography noWrap className={classes.text}>
        {text}
      </Typography>
    </Box>
  );
}

const useStyles = makeStyles<
  {
    fontWeight?: React.CSSProperties["fontWeight"];
    color: TagColor;
  } & Margins
>()((theme, { fontWeight, color, ...margins }) => {
  const [colorType, paletteColor] = color.split(".") as [Colors, keyof PaletteColor];
  const actualColor = paletteColor ? theme.palette[colorType][paletteColor] : color;

  return {
    icon: {
      lineHeight: "16px",
      fontSize: theme.typography.pxToRem(18),
      cursor: "default!important",
    },
    text: {
      lineHeight: "16px",
      fontSize: theme.typography.pxToRem(12),
      marginLeft: 5,
      fontWeight,
    },
    wrapper: {
      padding: "0 8px 0 4px",
      display: "flex",
      alignItems: "center",
      width: "fit-content",
      borderRadius: "99px",
      height: "24px",
      color: actualColor,
      backgroundColor: alpha(actualColor, 0.1),
      ...margins,
      "& .MuiCircularProgress-root": {
        color: actualColor,
      },
    },
    content: {
      display: "flex",
      alignItems: "center",
      backgroundColor: "white",
      padding: `${theme.spacing(1.2)}!important`,
    },
  };
});
