import { Box, Typography } from "@mui/material";

import { useId } from "../../../../../_common/hooks/use-id.hook";
import { useTranslate } from "../../../../../_common/i18n/hooks/use-translate.hook";
import { StyledScreenReadersOnly } from "../../../../../_common/ui/screen-readers-only";
import { Platform } from "../types/platform";
import { Version } from "../types/version";
import { PlatformIcon } from "./platform-icon";

export interface ShielderVersionLabelProps {
  platform: Platform;
  version: Version;
  hidePlatformName?: boolean;
}

export const ShielderVersionLabel = ({ platform, version, hidePlatformName }: ShielderVersionLabelProps) => {
  const t = useTranslate();
  const id = useId();

  return (
    <Box display="flex" alignItems="center" mr={1} aria-labelledby={id}>
      <StyledScreenReadersOnly id={id}>
        {t(`enum.platform.aria-label.version.${platform}`, { version })}
      </StyledScreenReadersOnly>

      <PlatformIcon platform={platform} />

      <Box marginLeft={0.6} component="span">
        <Typography>
          {!hidePlatformName && <span>{t(`enum.platform.${platform}`)} </span>}
          <span aria-hidden={true}>{`v${version}`}</span>
        </Typography>
      </Box>
    </Box>
  );
};
