import { Box } from "@mui/material";

import { useTranslate } from "../../../../../../../_common/i18n/hooks/use-translate.hook";
import { FormInput } from "../../../../../../../_common/ui/form-input/form-input";
import {
  MAX_PROJECT_DESCRIPTION_LENGTH,
  MAX_PROJECT_NAME_LENGTH,
} from "../../../project-schemas/edit-project-summary-form-schema";

interface EditProjectSummaryFormProps {
  loading?: boolean;
}

export const EditProjectSummaryFormContent = ({ loading }: EditProjectSummaryFormProps) => {
  const t = useTranslate();

  return (
    <>
      <Box mb={2.5}>
        <FormInput
          id="name-input"
          isLoading={loading}
          skeletonProps={{ width: "79px", "data-testid": `name-input-skeleton-loader` }}
          label={t(`component.project.formInput.name.label`)}
          name="projectName"
          maxLength={MAX_PROJECT_NAME_LENGTH}
        />
      </Box>
      <Box mb={2.5}>
        <FormInput
          id="description-input"
          isLoading={loading}
          skeletonProps={{ width: "165px", "data-testid": `description-input-skeleton-loader` }}
          label={t(`component.project.formInput.description.label`)}
          multiline
          minRows={3}
          name="projectDescription"
          maxLength={MAX_PROJECT_DESCRIPTION_LENGTH}
        />
      </Box>
    </>
  );
};
