import { AxiosError } from "axios";

import { ApiCallResult, ApiCallType, getApiCallToastI18nKey } from "../../i18n/utils/get-api-call-toast-i18n-key";
import { hasTranslationKey } from "../../i18n/utils/has-translation-key";
import { I18nToastKey } from "../types";
import { NonValidationErrors } from "./non-validation-error";

const getSingleErrorI18nKey = (
  type: ApiCallType,
  i18nToastKey: I18nToastKey | undefined,
  errorCode: number | undefined,
) => {
  let key = i18nToastKey && getApiCallToastI18nKey(type, errorCode ?? ApiCallResult.UnknownError, i18nToastKey);
  if (!key || !hasTranslationKey(key)) {
    key = getApiCallToastI18nKey(type, errorCode ?? ApiCallResult.UnknownError);
    if (errorCode && !hasTranslationKey(key)) {
      key = getApiCallToastI18nKey(type, ApiCallResult.UnknownError);
    }
  }

  return key;
};

export const getErrorI18nKeys = (type: ApiCallType, i18nToastKey: I18nToastKey | undefined, error: unknown) =>
  (error instanceof NonValidationErrors
    ? error.codes
    : [error instanceof AxiosError ? error.response?.status : undefined]
  ).map(code => getSingleErrorI18nKey(type, i18nToastKey, code));
