import { useMutation } from "@tanstack/react-query";

import { ApiEntity } from "../../../_common/api/api-entity";
import { MutationError } from "../../../_common/api/errors/mutation-error";
import { downloadOne } from "../../../_common/api/methods/download-one";
import { queryKeys } from "../../../_common/api/query-keys/query-keys";
import { useRestClient } from "../../../_common/api/rest-client/rest-client-context";
import { useTranslate } from "../../../_common/i18n/hooks/use-translate.hook";
import { onPremShieldersRootPath } from "../on-prem-shielders-root-path";
import { OnPremShielderI18nToastKey } from "./_support/on-prem-shielder-i18n-toast-key";

export const useDownloadOnPremShielder = (fileName: string) => {
  const t = useTranslate();
  const restClient = useRestClient();

  return useMutation<void, MutationError<unknown>, string>({
    mutationKey: queryKeys[onPremShieldersRootPath]().item(fileName).download(),
    mutationFn: file => downloadOne(restClient, ApiEntity.OnPremShielder, undefined, file, { file }),
    meta: {
      i18nToastKey: OnPremShielderI18nToastKey.DOWNLOAD_ONE,
      resourceName: t(`component.on-prem-shielders.file`, { fileName }),
    },
  });
};
