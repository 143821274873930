import { Android as AndroidIcon, Apple as AppleIcon, SvgIconComponent } from "@mui/icons-material";
import { makeStyles } from "tss-react/mui";

import { Platform } from "../types/platform";

interface PlatformIconProps {
  platform: Platform;
}
export const PlatformIcon = ({ platform }: PlatformIconProps) => {
  const Icon = platformIcons[platform];
  return <Icon className={useStyles().classes.icon} />;
};

const platformIcons: Record<Platform, SvgIconComponent> = {
  [Platform.Android]: AndroidIcon,
  [Platform.Ios]: AppleIcon,
};

const useStyles = makeStyles()(theme => ({
  icon: {
    color: theme.palette.grey[600],
  },
}));
