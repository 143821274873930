import { alpha, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { InputStatus } from "../form-input/form-input";
import { InputValidationMessage } from "../form-input/input-validation-message";

interface InputHelperTextContentProps {
  inputName: string;
  status: InputStatus | undefined;
  helperText: React.ReactNode;
  afterAddon?: React.ReactNode;
}

export function InputHelperTextContent({ inputName, status, helperText, afterAddon }: InputHelperTextContentProps) {
  const { classes } = useStyles();

  return (
    <span className={classes.helperText}>
      {status ? (
        <InputValidationMessage status={status} inputName={inputName} message={helperText as string} />
      ) : (
        <Typography component="span">{helperText}</Typography>
      )}{" "}
      {afterAddon}
    </span>
  );
}

const useStyles = makeStyles()(theme => ({
  helperText: {
    flex: 1,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
    color: alpha(theme.palette.text.primary, 0.51),
    display: "flex",
    justifyContent: "space-between",
  },
}));
