import { Box, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link, LinkProps } from "react-router-dom";

type PaginationButtonProps = LinkProps & {
  disabled?: boolean;
};

export const PaginationButton = ({
  disabled,
  children,
  [`aria-label`]: ariaLabel,
  ...props
}: PaginationButtonProps) => {
  return (
    <Box height={24} width={24} marginBottom={0.7}>
      {disabled ? (
        <StyledDisabledIconButton disabled aria-disabled="true" aria-label={ariaLabel}>
          {children}
        </StyledDisabledIconButton>
      ) : (
        <Link {...props} tabIndex={-1}>
          <IconButton aria-label={ariaLabel}>{children}</IconButton>
        </Link>
      )}
    </Box>
  );
};

const StyledDisabledIconButton = styled(IconButton)(() => ({
  backgroundColor: "transparent",
  color: "rgba(0, 0, 0, 0.26)",
  cursor: "default",
  "&:hover": {
    backgroundColor: "transparent",
  },
}));
