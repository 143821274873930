import { Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { useTranslate } from "../../../../../../_common/i18n/hooks/use-translate.hook";
import { Alert } from "../../../../../../_common/ui/alert/alert";
import { DEFAULT_SHADOW } from "../../../../../../_common/ui/theme/theme";
import { PageType } from "../../page-type";
import { useErrorsNavigation } from "./use-errors-navigation";

interface ConfigurationValidationCountAlertProps {
  pageType: PageType.Edit | PageType.New;
  allItems: string[];
}

export function ConfigurationValidationCountAlert({ pageType, allItems }: ConfigurationValidationCountAlertProps) {
  const { classes } = useStyles();
  const t = useTranslate();

  const { errorsCount, jumpToErrorButtonClick, prevButtonClick, nextButtonClick } = useErrorsNavigation(allItems);

  return jumpToErrorButtonClick || prevButtonClick || nextButtonClick ? (
    <div className={classes.container}>
      <Alert
        className={classes.alert}
        type="error"
        description={
          <Typography ml={0.75} lineHeight="20px">
            {t(`component.configuration.validation-alert.${pageType}.message`, { count: errorsCount })}
          </Typography>
        }
        primaryButton={{
          label: jumpToErrorButtonClick
            ? t("component.configuration.validation-alert.jump-to-error.button")
            : t("component.configuration.validation-alert.previous-error.button"),
          disabled: !jumpToErrorButtonClick && !prevButtonClick,
          onClick: jumpToErrorButtonClick ?? prevButtonClick,
        }}
        secondaryButton={
          jumpToErrorButtonClick
            ? undefined
            : {
                label: t("component.configuration.validation-alert.next-error.button"),
                variant: "outlined",
                disabled: !nextButtonClick,
                onClick: nextButtonClick,
              }
        }
      />
    </div>
  ) : null;
}

const useStyles = makeStyles()(theme => ({
  container: {
    margin: theme.spacing(0, -1, 4, -1),
    padding: theme.spacing(2, 1, 0, 1),
    position: "sticky",
    top: theme.spacing(8),
    zIndex: theme.zIndex.appBar,
    background: theme.palette.grey["100"],
  },
  alert: {
    boxShadow: DEFAULT_SHADOW,
  },
}));
