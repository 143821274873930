import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { ApiEntity } from "../../../../../../_common/api/api-entity";
import { MutationError } from "../../../../../../_common/api/errors/mutation-error";
import { CustomAction } from "../../../../../../_common/api/methods/custom-action";
import { postOne } from "../../../../../../_common/api/methods/post-one";
import { queryKeys } from "../../../../../../_common/api/query-keys/query-keys";
import { useRestClient } from "../../../../../../_common/api/rest-client/rest-client-context";
import { useTranslate } from "../../../../../../_common/i18n/hooks/use-translate.hook";
import { ID } from "../../../../../../_common/types";
import { showToast } from "../../../../../../_common/ui/toast";
import { appShieldingRootPath } from "../../../../app-shielding-root-path";
import { ProjectI18nToastKey } from "../_support/project-i18n-toast-key";
import { GetProject } from "../get-project";
import { DuplicateProject } from "./duplicate-project";
import { DuplicateProjectInput, duplicateProjectInputTransformer } from "./duplicate-project-input";

export function useDuplicateProject(id: ID, sourceProject: GetProject | undefined) {
  const t = useTranslate();
  const { classes } = useStyles();
  const queryClient = useQueryClient();
  const restClient = useRestClient();

  return useMutation<DuplicateProject, MutationError<DuplicateProjectInput>, DuplicateProjectInput>({
    mutationKey: queryKeys[appShieldingRootPath].projects().item(id).duplicate(),
    mutationFn: variables =>
      postOne(
        restClient,
        ApiEntity.Project,
        duplicateProjectInputTransformer(id, variables),
        DuplicateProject,
        CustomAction.Copy,
        id,
      ),
    onSuccess: data => {
      void queryClient.invalidateQueries({ queryKey: queryKeys[appShieldingRootPath].projects().lists() });
      showToast("success", t(`toast.mutation.${ProjectI18nToastKey.DUPLICATE_PROJECT}.successMessage`), {
        buttons: (
          <Link to={`/${appShieldingRootPath}/projects/${data.id}`} className={classes.link}>
            {t(`toast.mutation.${ProjectI18nToastKey.DUPLICATE_PROJECT}.successLink`)}
          </Link>
        ),
      });
    },
    meta: {
      i18nToastKey: ProjectI18nToastKey.DUPLICATE_PROJECT,
      resourceName: sourceProject?.projectName,
    },
  });
}

const useStyles = makeStyles()(theme => ({
  link: {
    color: "inherit",
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
  },
}));
