import { datadogRum } from "@datadog/browser-rum";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ZodError } from "zod";

import { useDatadogContext } from "../datadog/datadog-context";
import { COOKIE_CONSENT_KEY, COOKIE_CONSENT_VERSION } from "./constants";
import { CookieConsentRecord } from "./types";

export const useCookieConsent = () => {
  const [cookieConsentRecord, setCookieConsentRecord] = useState<CookieConsentRecord | null>({
    accept: false,
    timestamp: Date.now(),
    version: COOKIE_CONSENT_VERSION,
  });
  const isDatadogInitialized = useDatadogContext();

  useEffect(() => {
    const tokensRecord = localStorage.getItem(COOKIE_CONSENT_KEY);
    if (tokensRecord) {
      try {
        const record = CookieConsentRecord.parse(JSON.parse(tokensRecord));
        setCookieConsentRecord(record);
      } catch (e) {
        if (!(e instanceof SyntaxError) && !(e instanceof ZodError)) throw e;
        setCookieConsentRecord(null);
      }
    } else {
      setCookieConsentRecord(null);
    }
  }, []);

  useEffect(() => {
    if (isDatadogInitialized && cookieConsentRecord?.accept) {
      datadogRum.setTrackingConsent("granted");
    }
  }, [cookieConsentRecord?.accept, isDatadogInitialized]);

  const setConsent = useCallback((accept: boolean) => {
    const newRecord: CookieConsentRecord = {
      accept,
      timestamp: Date.now(),
      version: COOKIE_CONSENT_VERSION,
    };

    localStorage.setItem(COOKIE_CONSENT_KEY, JSON.stringify(newRecord));
    setCookieConsentRecord(newRecord);
  }, []);

  const showConsent = useMemo(() => {
    const pastDate = new Date();
    pastDate.setMonth(pastDate.getMonth() - 6);

    return (
      cookieConsentRecord?.accept === undefined ||
      !cookieConsentRecord?.timestamp ||
      cookieConsentRecord.timestamp <= pastDate.getTime() ||
      cookieConsentRecord?.version !== COOKIE_CONSENT_VERSION
    );
  }, [cookieConsentRecord?.accept, cookieConsentRecord?.timestamp, cookieConsentRecord?.version]);

  return {
    setConsent,
    showConsent,
  };
};
