import { ContentCopy as ContentCopyIcon, Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { addAction, AppShieldingActions, AppShieldingFeatures } from "../../../../../../../../_common/datadog";
import { useTranslate } from "../../../../../../../../_common/i18n/hooks/use-translate.hook";
import { ID } from "../../../../../../../../_common/types";
import { ContextMenu } from "../../../../../../../../_common/ui/context-menu/context-menu";
import { TextSkeleton } from "../../../../../../../../_common/ui/skeleton/text-skeleton";
import { ColumnCellProps } from "../../../../../../../../_common/ui/table/data-table";
import { appShieldingRootPath } from "../../../../../../app-shielding-root-path";
import { ProjectsListContext } from "../projects-list-context.type";

type ProjectsListContextMenuCellProps = ColumnCellProps<
  {
    id: ID;
    projectName: string;
    isLoading: boolean;
  },
  ProjectsListContext
>;
export function ProjectsListContextMenuCell(props: ProjectsListContextMenuCellProps) {
  const t = useTranslate();
  const navigate = useNavigate();
  const search = useLocation().search;

  return useMemo(
    () =>
      props.isLoading ? (
        <TextSkeleton height="24px" width="20px" />
      ) : (
        <Box
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <ContextMenu
            aria-label={t("component.project-list.table.row-context-options.aria-label", { name: props.projectName })}
            options={[
              {
                icon: EditIcon,
                onClick: () => {
                  const baseSearch = search ? `${search}&id=` : `?id=`;
                  addAction(AppShieldingActions.OpenEditProject, AppShieldingFeatures.ProjectList);
                  navigate(`/${appShieldingRootPath}/projects/edit${baseSearch}${props.id}`);
                },
                label: t("word.edit"),
                value: "edit",
              },
              {
                icon: ContentCopyIcon,
                onClick: () => {
                  const baseSearch = search ? `${search}&id=` : `?id=`;
                  addAction(AppShieldingActions.OpenDuplicateProject, AppShieldingFeatures.ProjectList);
                  navigate(`/${appShieldingRootPath}/projects/duplicate${baseSearch}${props.id}`);
                },
                label: t("word.duplicate"),
                value: "duplicate",
              },
              {
                red: true,
                icon: DeleteIcon,
                onClick: () => {
                  addAction(AppShieldingActions.OpenDeleteProject, AppShieldingFeatures.ProjectList);
                  props.context.selectProject(props.id);
                },
                label: t("component.project-list-context-menu-cell.delete-project.label"),
                value: "delete",
              },
            ]}
            PaperProps={{
              "aria-label": t("component.project-list.table.row-context-options.menu.aria-label", {
                name: props.projectName,
              }),
            }}
          />
        </Box>
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [search, props.isLoading],
  );
}
