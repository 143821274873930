import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { SortOrder } from "../../../api/methods/get-many-with-pagination";
import { ID } from "../../../types";
import { searchLink } from "../../../utils/search-link";
import { DEFAULT_SORT_COLUMN_PARAM_NAME, DEFAULT_SORT_ORDER_PARAM_NAME } from "./default-sort-param-names";
import { sortOrderApiToUrl } from "./sort-order-url-api";
import { Column, SortParamsNames } from "./types";

const reverseOrder = (order: SortOrder) => (order === SortOrder.Asc ? SortOrder.Desc : SortOrder.Asc);

export function useTableSortLinks<
  TApiSortColumn extends string,
  TUrlSortColumn extends string,
  TData extends { readonly id: ID },
  TContext,
>(
  columns: Partial<Column<TApiSortColumn, TUrlSortColumn, TData, TContext>>[],
  sortColumn: TApiSortColumn,
  sortOrder: SortOrder,
  {
    sortColumnParamName = DEFAULT_SORT_COLUMN_PARAM_NAME,
    sortOrderParamName = DEFAULT_SORT_ORDER_PARAM_NAME,
  }: SortParamsNames = {},
) {
  const [searchParams] = useSearchParams();

  return useMemo(() => {
    const sortLinks = {} as Record<TApiSortColumn, { search: string }>;

    for (const { sorting } of columns) {
      if (sorting) {
        sortLinks[sorting.apiColumnName] = searchLink(searchParams, {
          page: "1",
          [sortColumnParamName]: sorting.urlParamName,
          [sortOrderParamName]: sortOrderApiToUrl(
            sorting.apiColumnName === sortColumn ? reverseOrder(sortOrder) : sorting.defaultOrder,
          ),
        });
      }
    }

    return sortLinks;
  }, [searchParams, sortColumn, sortOrder, sortColumnParamName, sortOrderParamName, columns]);
}
