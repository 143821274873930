import { ID } from "../../../../../../_common/types";

export interface CreateAccessApiKeyInput {
  projectId: ID;
  configId: ID;
}

interface ApiCreateAccessApiKeyInput {
  projectId: number;
  configId: number;
}

export const createAccessApiKeyInputTransformer = (input: CreateAccessApiKeyInput): ApiCreateAccessApiKeyInput => ({
  projectId: +input.projectId,
  configId: +input.configId,
});
