import { z } from "zod";

import { DateTimeInput, ID } from "../../../../../../_common/types";
import { objToForm } from "../../../../../../_common/utils/obj-to-form";
import { Platform } from "../../../shielders/types/platform";
import { Version } from "../../../shielders/types/version";
import { ConfigValues } from "../types/config-values";

export const replaceConfigurationInput = (certificateFields: string[]) =>
  z
    .object({
      configValues: z.any(), // it is validated in the form input already
      oldConfigValues: z.any(),

      projectId: ID,
      shielderId: ID,
      shielderPlatform: z.nativeEnum(Platform),
      shielderVersion: Version,
      isShielderChanged: z.boolean().optional(),
      configUpdatedAt: DateTimeInput.optional(),
    })
    .transform(({ configValues, oldConfigValues, ...params }) => {
      const { debugMode, ...rest } = configValues as ConfigValues;

      return objToForm({
        ...extractCertificatesAndStringifyConfigValues(
          rest,
          oldConfigValues as ConfigValues | undefined,
          certificateFields,
        ),
        testMode: debugMode as boolean,
        ...params,
      });
    });
export type ReplaceConfigurationInput = z.input<ReturnType<typeof replaceConfigurationInput>>;
// export type ApiReplaceConfigurationInput = z.infer<typeof ReplaceConfigurationInput>;

function extractCertificatesAndStringifyConfigValues(
  configValues: ConfigValues,
  oldConfigValues: ConfigValues | undefined,
  certificateFieldNames: string[],
) {
  const result: Record<string, boolean | string | File> = {};
  const config = { ...configValues };

  for (const certificateFieldName of certificateFieldNames) {
    if (config[`${certificateFieldName}Delete`]) {
      if (oldConfigValues?.[certificateFieldName]) {
        result[`${certificateFieldName}Delete`] = true;
      }
    } else {
      const certificate = config[certificateFieldName];
      if (certificate instanceof File) {
        result[certificateFieldName] = certificate;
      }
    }
  }

  for (const certificateFieldName of certificateFieldNames) {
    delete config[certificateFieldName];
    delete config[`${certificateFieldName}Delete`];
  }
  result.config = JSON.stringify(config);

  return result;
}
