import { useMutation, useQueryClient } from "@tanstack/react-query";

import { ApiEntity } from "../../../../../../_common/api/api-entity";
import { MutationError } from "../../../../../../_common/api/errors/mutation-error";
import { postOne } from "../../../../../../_common/api/methods/post-one";
import { queryKeys } from "../../../../../../_common/api/query-keys/query-keys";
import { useRestClient } from "../../../../../../_common/api/rest-client/rest-client-context";
import { appShieldingRootPath } from "../../../../app-shielding-root-path";
import { ProjectI18nToastKey } from "../_support/project-i18n-toast-key";
import { CreateProject } from "./create-project";
import { CreateProjectInput, createProjectInputTransformer } from "./create-project-input";

export function useCreateProject() {
  const queryClient = useQueryClient();
  const restClient = useRestClient();

  return useMutation<CreateProject, MutationError<CreateProjectInput>, CreateProjectInput>({
    mutationKey: queryKeys[appShieldingRootPath].projects().create(),
    mutationFn: variables =>
      postOne(restClient, ApiEntity.Project, createProjectInputTransformer(variables), CreateProject),

    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: queryKeys[appShieldingRootPath].projects().lists() });
    },
    meta: { i18nToastKey: ProjectI18nToastKey.CREATE_PROJECT },
  });
}
