import {
  appShieldingQueryKeys,
  considerAppShieldingQueryKey404As500,
  invalidationAppShieldingKeysOn404,
} from "../../../products/app-shielding/_common/api/query-keys";
import { appShieldingRootPath } from "../../../products/app-shielding/app-shielding-root-path";
import {
  considerAppShieldingToolsQueryKey404As500,
  invalidationAppShieldingToolsKeysOn404,
  onPremShieldersQueryKeysFactory,
} from "../../../products/on-prem-shielders/api/_support/on-prem-shielders-query-keys-factory";
import { onPremShieldersRootPath } from "../../../products/on-prem-shielders/on-prem-shielders-root-path";
import {
  considerSettingsQueryKey404As500,
  invalidationSettingsKeysOn404,
  settingsQueryKeys,
} from "../../../products/settings/_common/api/query-keys";
import { settingsRootPath } from "../../../products/settings/settings-root-path";
import { InvalidationKeys } from "./invalidation-keys.type";
import { QueryKey } from "./query-key.type";

export const queryKeys = {
  [appShieldingRootPath]: appShieldingQueryKeys,
  [onPremShieldersRootPath]: onPremShieldersQueryKeysFactory,
  [settingsRootPath]: settingsQueryKeys,
} as const;

const considerMap = {
  [appShieldingRootPath]: considerAppShieldingQueryKey404As500,
  [onPremShieldersRootPath]: considerAppShieldingToolsQueryKey404As500,
  [settingsRootPath]: considerSettingsQueryKey404As500,
};

export const considerQueryKey404As500 = (key: QueryKey) => considerMap[key[0] as keyof typeof considerMap](key);

const invalidationMap = {
  [appShieldingRootPath]: invalidationAppShieldingKeysOn404,
  [onPremShieldersRootPath]: invalidationAppShieldingToolsKeysOn404,
  [settingsRootPath]: invalidationSettingsKeysOn404,
};

export const invalidationKeysOn404 = (key: QueryKey): InvalidationKeys =>
  invalidationMap[key[0] as keyof typeof invalidationMap](key);
