import { Box } from "@mui/material";
import { ReactNode } from "react";

import { itemTestId } from "../../../../utils/item-test-id";

interface ItemLayoutProps {
  children: ReactNode;
  name: string;
}

export function ItemLayout({ children, name }: ItemLayoutProps) {
  return <Box data-testid={itemTestId(name)}>{children}</Box>;
}
