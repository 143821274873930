import { Chip, ChipProps } from "@mui/material";
import { tss } from "tss-react/mui";

import { useTranslate } from "../../i18n/hooks/use-translate.hook";

export const DeprecatedChip = ({ size = "small" }: { size?: ChipProps["size"] }) => {
  const { classes } = useStyles();
  const t = useTranslate();

  return <Chip className={classes.root} size={size} label={t("word.deprecated")} />;
};

const useStyles = tss.withParams().create(() => ({
  root: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
  },
}));
