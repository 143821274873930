import classes from "./global-error-page.module.css";

const SUPPORT_URL = "https://support.onespan.com/csm";

export const GlobalErrorPage = () => (
  <div className={classes.container}>
    <svg focusable="false" aria-hidden="true" viewBox="0 0 24 24" className={classes.icon}>
      <path d="M19 6v10.5l1.95 1.95c.03-.15.05-.3.05-.45V6c0-1.1-.9-2-2-2H6.5l2 2H19zM3.22 3.32 1.95 4.59 3 5.64V18c0 1.1.9 2 2 2h12.36l2.06 2.06 1.27-1.27L3.22 3.32zM15 18H5V7.64L15.36 18H15z"></path>
    </svg>
    <h1 className={classes.title}>Something unexpected happened</h1>
    <p className={classes.message}>
      There was an error while loading this page. If it persists please{" "}
      <a className={classes.link} href={SUPPORT_URL} target="_blank" rel="noreferrer">
        get in touch
      </a>
      .
    </p>
    <button className={classes.button} onClick={() => window.location.reload()}>
      Reload this page
    </button>
  </div>
);
