import { Box, Card, Divider } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { DateTimeFormat, useDateTime } from "../../../../../../../_common/i18n/hooks/use-date-time.hook";
import { useTranslate } from "../../../../../../../_common/i18n/hooks/use-translate.hook";
import { ShielderLabel } from "../../../../shielders/ui/shielder-label";
import { GetShielding } from "../../../api/get-shielding";
import { GetShieldingProgress } from "../../../api/get-shielding-progress/api-get-shielding-progress";
import { ShieldingStatusTag } from "../../shielding-status-tag";
import { ShieldingDownloadSection } from "./shielding-download-section";
import { ShieldingPanelSection } from "./shielding-panel-section";

interface ShieldingHeaderPanelProps {
  isLoading?: boolean;
  shielding: GetShielding | undefined;
  shieldingProgress: GetShieldingProgress | null | undefined;
}

export function ShieldingHeaderPanel({ isLoading, shielding, shieldingProgress }: ShieldingHeaderPanelProps) {
  const t = useTranslate();
  const formatter = useDateTime();
  const progressPercentage = shieldingProgress?.progressInPercent;
  const { classes, cx } = useStyles();

  return (
    <Box component={Card} display="flex" flexShrink={0} marginBottom={2} className={classes.container}>
      <ShieldingPanelSection
        className={cx(classes.panel, classes.shieldPanel)}
        isLoading={isLoading || !shielding}
        title={t("word.shield")}
        content={
          shielding && (
            <ShielderLabel
              platform={shielding.shielderPlatform}
              version={shielding.shielderVersion}
              status={shielding.shielderStatus}
              hidePlatformName
            />
          )
        }
      />
      <Divider className={classes.divider} />
      <ShieldingPanelSection
        className={classes.panel}
        isLoading={isLoading || !shielding}
        title={t("word.shielded")}
        addon={shielding && <ShieldingStatusTag status={shielding.shieldingStatus} progress={progressPercentage} />}
        content={shielding && formatter(shielding.shieldingUpdatedAt, DateTimeFormat.YYYYMMDDhmLocalTimeFormat)}
      />
      <Divider className={classes.divider} />
      <ShieldingDownloadSection className={classes.panel} isLoading={isLoading} shielding={shielding} />
    </Box>
  );
}

const useStyles = makeStyles()(theme => ({
  container: {
    flexDirection: "row",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  panel: {
    flex: 1,
  },
  shieldPanel: {
    flexShrink: 0,
  },
  divider: {
    marginBottom: 0,
    marginRight: -1,
    borderBottomWidth: 0,
    borderRightWidth: "thin",
    width: 0,
    [theme.breakpoints.down("md")]: {
      marginBottom: -1,
      marginRight: 0,
      borderBottomWidth: "thin",
      borderRightWidth: 0,
      width: "100%",
    },
  },
}));
