import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

import { useTranslate } from "../../_common/i18n/hooks/use-translate.hook";
import { Layout } from "../../_common/ui/layout/layout";
import { ClientCredentials } from "./features/client-credentials/components/client-credentials";

export const SettingsPage = () => {
  const t = useTranslate();

  return (
    <Layout
      title={t("component.settings.layout.content.title")}
      contentBoxProps={{ flexDirection: "column", alignItems: "center" }}
    >
      <Box maxWidth={942} width="100%">
        <ClientCredentials />
      </Box>
      <Outlet />
    </Layout>
  );
};
