import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { ApiEntity } from "../../../../../_common/api/api-entity";
import { getOne } from "../../../../../_common/api/methods/get-one";
import { queryKeys } from "../../../../../_common/api/query-keys/query-keys";
import { useRestClient } from "../../../../../_common/api/rest-client/rest-client-context";
import { ClientCredentialsI18nToastKey } from "./_support/client-credentials-i18n-toast-key";

export const ApiGetClientCredentials = z.union([
  z.object({
    clientId: z.string().length(0),
    clientSecret: z.string().length(0),
  }),
  z.object({
    clientId: z.string().min(1),
    clientSecret: z.string().min(1),
  }),
]);
export type ApiGetClientCredentials = z.input<typeof ApiGetClientCredentials>;

export function getClientCredentialsTransformer(clientCredentials: ApiGetClientCredentials) {
  return clientCredentials;
}

export const GetClientCredentials = ApiGetClientCredentials.transform(getClientCredentialsTransformer);
export type GetClientCredentials = z.infer<typeof GetClientCredentials>;

export function useGetClientCredentials() {
  const restClient = useRestClient();

  return useQuery({
    queryKey: queryKeys.settings.clientCredentials().item().details(),
    queryFn: () => getOne(restClient, ApiEntity.ClientCredentials, undefined, GetClientCredentials),
    meta: { i18nToastKey: ClientCredentialsI18nToastKey.GET },
  });
}
