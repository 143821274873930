import { alpha, Theme, ThemeOptions } from "@mui/material/styles";

export const radioButtonTheme = (theme: Theme): ThemeOptions["components"] => {
  const BACKGROUND_COLOR = theme.palette.mode === "dark" ? theme.palette.grey[900] : theme.palette.common.white;
  const COLOR = theme.palette.mode === "dark" ? theme.palette.grey[400] : theme.palette.grey[900];
  return {
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: 0,
          margin: 0,
          borderRadius: "50%",
          background: BACKGROUND_COLOR,
          "& .MuiIconButton-label": {
            position: "relative",
          },
        },
        colorPrimary: {
          color: COLOR,
          "&:hover, &.Mui-focusVisible": {
            color: theme.palette.primary.main,
            boxShadow: `0 0 0 3px ${alpha(theme.palette.primary.main, 0.6)}`,
            background: BACKGROUND_COLOR,
          },
          "&.Mui-disabled": {
            background: BACKGROUND_COLOR,
          },
          "&:hover": {
            "&.Mui-focusVisible": {
              boxShadow: `0 0 0 4px ${alpha(theme.palette.primary.main, 0.6)}`,
            },
          },
          "&.Mui-checked": {
            color: COLOR,
            background: BACKGROUND_COLOR,
            "&:hover, &.Mui-focusVisible": {
              background: BACKGROUND_COLOR,
            },
          },
        },
      },
    },
  };
};
