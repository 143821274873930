import { SvgIconComponent } from "@mui/icons-material";
import { alpha, Box, Typography } from "@mui/material";
import { InputHTMLAttributes, useEffect, useState } from "react";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import { makeStyles } from "tss-react/mui";

import { useTranslate } from "../../i18n/hooks/use-translate.hook";
import { InputValidationMessage } from "../form-input/input-validation-message";
import { TextSkeleton } from "../skeleton/text-skeleton";
import { MainDropzoneTypography } from "./main-dropzone-typography";
import { UploadingState } from "./uploading-state";

interface FileDropzoneProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "accept" | "onChange" | "title" | "size"> {
  onChange: DropzoneOptions["onDrop"];
  mainText: React.ReactNode;
  textIcon?: SvgIconComponent;
  accept?: DropzoneOptions["accept"];
  inputProps?: Record<string, unknown>;
  topIcon?: SvgIconComponent;
  title?: string;
  dropText?: string;
  errorMessage?: string;
  isLoading?: boolean;
  isUpdating?: boolean;
  size?: "normal" | "large";
  maxLength?: number;
}

export function FileDropzone({
  onChange,
  mainText,
  textIcon: TextIcon,
  accept,
  inputProps,
  topIcon: TopIcon,
  title,
  dropText,
  errorMessage,
  isLoading,
  isUpdating,
  className,
  disabled,
  size = "normal",
  ...props
}: FileDropzoneProps) {
  const t = useTranslate();
  const [isInvalidFileType, setIsInvalidTypeFile] = useState(false);

  const { getRootProps, getInputProps, isDragActive, inputRef, fileRejections } = useDropzone({
    onDrop: onChange,
    accept,
    disabled: disabled || isUpdating,
    maxFiles: 1,
    noClick: true,
  });

  const isError = Boolean(errorMessage);

  const { classes, cx } = useStyles({
    isDragActive,
    isError,
    isLoading,
    disabled,
    isInvalidFileType,
    size,
  });

  const allowedExtensions = accept ? Object.values(accept).flat().join(", ") : undefined;

  useEffect(() => {
    if (!fileRejections.length) {
      setIsInvalidTypeFile(false);
    } else {
      setIsInvalidTypeFile(true);
      const timer = setTimeout(() => {
        setIsInvalidTypeFile(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [fileRejections.length]);

  const placeholder = (
    <Box display="flex" flexDirection="column" alignItems="center">
      {TopIcon && <TopIcon className={classes.topIcon} />}
      <Typography className={classes.dropzoneTitle}>{title}</Typography>
      <Box className={classes.mainTextContainer}>
        {TextIcon && <TextIcon className={classes.textIcon} />}
        <MainDropzoneTypography>{mainText}</MainDropzoneTypography>
      </Box>
      {allowedExtensions && (
        <MainDropzoneTypography>
          {t("component.drop-zone.accepts", { extensions: allowedExtensions })}
        </MainDropzoneTypography>
      )}
    </Box>
  );

  const error = isError && (
    <Box mt={0.75}>
      <InputValidationMessage inputName="file-drop-zone" message={errorMessage} status={isError && "error"} />
    </Box>
  );

  const invalidFileTypeMessage = (
    <MainDropzoneTypography>{t("component.drop-zone.invalid-file-type")}</MainDropzoneTypography>
  );

  const dropMessage = <MainDropzoneTypography>{dropText}</MainDropzoneTypography>;

  return (
    <>
      <Box
        component="section"
        {...getRootProps({
          className: cx(classes.dropzone, className),
          ...props,
        })}
        role="region"
        aria-busy={isLoading}
      >
        {isLoading ? (
          <Box display="flex" flexDirection="column" gap={1.25} alignItems="center">
            <TextSkeleton width="324px" height="24px" data-testid="dropzone-line-one-skeleton-loader" />
            <TextSkeleton width="179px" height="20px" data-testid="dropzone-line-two-skeleton-loader" />
          </Box>
        ) : isUpdating ? (
          <UploadingState />
        ) : (
          <>
            <input
              {...getInputProps({
                ...inputProps,
                disabled,
                multiple: false,
                ref: inputRef,
                accept: allowedExtensions,
              })}
            />
            {isDragActive ? dropMessage || placeholder : isInvalidFileType ? invalidFileTypeMessage : placeholder}
          </>
        )}
      </Box>
      {error}
    </>
  );
}

const useStyles = makeStyles<{
  isDragActive: boolean;
  isError: boolean;
  isLoading?: boolean;
  disabled: boolean | undefined;
  isInvalidFileType: boolean;
  size: "normal" | "large";
}>()((theme, { isDragActive, isError, isLoading, disabled, isInvalidFileType, size }) => {
  const borderColor =
    isError || isInvalidFileType
      ? theme.palette.error.main
      : isDragActive
        ? theme.palette.primary.main
        : alpha(theme.palette.text.primary, 0.57);

  const backgroundColor = disabled ? theme.palette.grey[200] : isDragActive ? theme.palette.primary.light : "unset";

  const color = isDragActive
    ? theme.palette.primary.main
    : disabled
      ? alpha(theme.palette.text.primary, 0.57)
      : isInvalidFileType
        ? theme.palette.error.main
        : theme.palette.text.primary;

  const focusColor = isInvalidFileType ? theme.palette.error.main : theme.palette.primary.main;

  return {
    dropzone: {
      color,
      minHeight: size === "large" ? theme.spacing(18) : theme.spacing(11),
      padding: theme.spacing(2.5),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: !isLoading ? `2px dashed ${borderColor}` : "2px solid transparent",
      borderRadius: theme.spacing(0.5),
      fontSize: theme.typography.pxToRem(12),
      textAlign: "center",
      backgroundColor,
      "& p": {
        color,
      },
      "&:focus": {
        border: `2px solid ${focusColor}`,
        outline: "none",
        "&:not(:focus-visible)": {
          border: `2px dashed ${borderColor}`,
        },
      },
    },
    topIcon: {
      fontSize: theme.typography.pxToRem(32),
      marginBottom: theme.spacing(0.25),
    },
    textIcon: {
      fontSize: theme.typography.pxToRem(20),
      color: theme.palette.grey[600],
    },
    mainTextContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gap: theme.spacing(0.75),
      "& p": {
        color,
      },
      "& label": {
        color: disabled ? alpha(theme.palette.primary.main, 0.63) : theme.palette.primary.main,
        cursor: disabled ? "default" : "pointer",
        textDecoration: "underline",
      },
    },
    dropzoneTitle: {
      fontWeight: 500,
      lineHeight: theme.typography.pxToRem(24),
    },
  };
});
