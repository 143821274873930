import { useMutation, useQueryClient } from "@tanstack/react-query";

import { ApiEntity } from "../../../../../../_common/api/api-entity";
import { MutationError } from "../../../../../../_common/api/errors/mutation-error";
import { patchOne } from "../../../../../../_common/api/methods/patch-one";
import { queryKeys } from "../../../../../../_common/api/query-keys/query-keys";
import { useRestClient } from "../../../../../../_common/api/rest-client/rest-client-context";
import { ID } from "../../../../../../_common/types";
import { appShieldingRootPath } from "../../../../app-shielding-root-path";
import { ProjectI18nToastKey } from "../_support/project-i18n-toast-key";
import { GetProject } from "../get-project";
import { UpdateProject } from "./update-project";
import { UpdateProjectInput, updateProjectInputTransformer } from "./update-project-input";

export function useUpdateProject(id: ID, oldProject: GetProject | undefined) {
  const queryClient = useQueryClient();
  const restClient = useRestClient();

  return useMutation<UpdateProject, MutationError<UpdateProjectInput>, UpdateProjectInput>({
    mutationKey: queryKeys[appShieldingRootPath].projects().item(id).update(),
    mutationFn: ({ id, ...data }) =>
      patchOne(restClient, ApiEntity.Project, id, updateProjectInputTransformer(data, oldProject!), UpdateProject),
    onSuccess: (updatedProps, variables) => {
      queryClient.setQueryData<GetProject>(queryKeys[appShieldingRootPath].projects().item(id).details(), {
        ...oldProject!,
        ...variables,
        ...updatedProps,
      });
      void queryClient.invalidateQueries({ queryKey: queryKeys[appShieldingRootPath].projects().lists() });
    },
    meta: {
      i18nToastKey: ProjectI18nToastKey.UPDATE_PROJECT,
      resourceName: oldProject?.projectName,
    },
  });
}
