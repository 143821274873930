import { AddCircle as AddCircleIcon, Save as SaveIcon } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { ReactElement, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { useTranslate } from "../../../../../_common/i18n/hooks/use-translate.hook";
import { ApplicationBarPrimaryButton } from "../../../../../_common/ui/application-bar/application-bar-primary-button";
import { ConfirmationDialog } from "../../../../../_common/ui/confirmation-dialog/confirmation-dialog";
import { ConfigurationFormProvider } from "../configuration-form-provider";
import { PageType } from "../page-type";

const startIcon: Record<Exclude<PageType, PageType.Show>, ReactElement> = {
  [PageType.New]: <AddCircleIcon />,
  [PageType.Edit]: <SaveIcon />,
};
const buttonLabel: Record<Exclude<PageType, PageType.Show>, string> = {
  [PageType.New]: "word.create",
  [PageType.Edit]: "word.save",
};

const buttonAriaLabel: Record<Exclude<PageType, PageType.Show>, string> = {
  [PageType.New]: "common.create-configuration",
  [PageType.Edit]: "common.save-configuration",
};

interface SubmitButtonProps {
  pageType: PageType.Edit | PageType.New;
}

const SubmitButton = ({ pageType }: SubmitButtonProps) => {
  const t = useTranslate();
  const isDebugMode = (useWatch({ name: "debugMode", exact: true }) as boolean) ?? false;
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const isNeedConfirmation = pageType === PageType.New && isDebugMode;

  const {
    trigger,
    formState: { isSubmitting },
  } = useFormContext();

  return (
    <>
      <ApplicationBarPrimaryButton
        form={ConfigurationFormProvider.FORM_ID}
        type={isNeedConfirmation ? "button" : "submit"}
        startIcon={startIcon[pageType]}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick={
          isNeedConfirmation
            ? async () => {
                if (await trigger()) {
                  setIsConfirmationOpen(true);
                }
              }
            : undefined
        }
        disabled={isSubmitting}
        aria-label={t(buttonAriaLabel[pageType])}
      >
        {t(buttonLabel[pageType])}
      </ApplicationBarPrimaryButton>
      <ConfirmationDialog
        reverseButtonOrder
        confirmButtonProps={{
          startIcon: <AddCircleIcon />,
          type: "submit",
          form: ConfigurationFormProvider.FORM_ID,
        }}
        onCancel={() => setIsConfirmationOpen(false)}
        title={t("component.create-configuration-dialog.debug-mode.title")}
        confirmButtonLabel={t("word.create")}
        open={isConfirmationOpen}
      >
        <Box width={440}>
          <Typography color="textPrimary">{t("component.create-configuration-dialog.debug-mode.text")}</Typography>
        </Box>
      </ConfirmationDialog>
    </>
  );
};

interface ConfigurationSubmitButtonProps extends SubmitButtonProps {
  disabled: boolean;
}
export const ConfigurationSubmitButton = ({ disabled, pageType }: ConfigurationSubmitButtonProps) => {
  const t = useTranslate();

  return disabled ? (
    <ApplicationBarPrimaryButton
      disabled={true}
      startIcon={startIcon[pageType]}
      aria-label={t(buttonAriaLabel[pageType])}
    >
      {t(buttonLabel[pageType])}
    </ApplicationBarPrimaryButton>
  ) : (
    <SubmitButton pageType={pageType} />
  );
};
