import { Box, BoxProps } from "@mui/material";
import { ReactNode, useState } from "react";
import { makeStyles } from "tss-react/mui";

import { ApplicationBar, ApplicationBarProps } from "../application-bar/application-bar";
import { LAYOUT_ID, TOP_BAR_GRID_HEIGHT } from "../constants";
import { CookieConsent } from "../cookie-consent/cookie-consent";
import { SideBar } from "../side-bar/side-bar";

interface LayoutProps extends Omit<ApplicationBarProps, "sideBarOpen"> {
  appBarContent?: ReactNode;
  contentBoxProps?: BoxProps;
}

export const Layout = ({ appBarContent, children, contentBoxProps, ...appBarProps }: LayoutProps) => {
  const { classes } = useStyles();
  const [isSideBarOpen, setSideBarOpen] = useState<boolean>(false);

  return (
    <Box display="flex" flex="1" marginTop={TOP_BAR_GRID_HEIGHT} id={LAYOUT_ID}>
      <SideBar open={isSideBarOpen} setSideBarOpen={setSideBarOpen} />
      <main className={classes.main}>
        <ApplicationBar {...appBarProps} sideBarOpen={isSideBarOpen}>
          {appBarContent}
        </ApplicationBar>
        <CookieConsent />
        <Box display="flex" flex="1" padding={2.5} className={classes.container} {...contentBoxProps}>
          {children}
        </Box>
      </main>
    </Box>
  );
};

const useStyles = makeStyles()(theme => ({
  main: {
    flex: 1,
    marginTop: theme.spacing(-8),
    paddingTop: theme.spacing(8),
  },
  container: {
    height: `calc(100vh - ${theme.spacing(TOP_BAR_GRID_HEIGHT)})`,
    position: "relative",
  },
}));
