import { useWatch } from "react-hook-form";

import { DebugModeTag } from "../../../_common/ui/debug-mode-tag";

interface ConfigurationDebugModeTagProps {
  isEditMode: boolean;
}

export const ConfigurationDebugModeTag = ({ isEditMode }: ConfigurationDebugModeTagProps) => {
  const isDebugMode = (useWatch({ name: "debugMode", exact: true }) as boolean) ?? false;
  return <DebugModeTag show={!isEditMode && isDebugMode} />;
};
