import { hashKey } from "@tanstack/react-query";

import { QueryKey } from "../../../../../../_common/api/query-keys/query-key.type";
import { ID } from "../../../../../../_common/types";
import { SortingParams } from "../../../../../../_common/ui/table/data-table";
import { appShieldingRootPath } from "../../../../app-shielding-root-path";
import { ProjectsListApiSortColumn } from "../../features/projects-list/types/projects-list-api-sort-column.enum";

export interface ProjectsListParam {
  itemsPerPage: number;
  page: number;
  sortingParams: SortingParams<ProjectsListApiSortColumn>;
}

export const projectsRootQueryKey = "projects";

const CREATE = "create";
const LISTS = "lists";
const ITEMS = "items";
const DETAILS = "details";
const CONFIGURATION = "configuration";
const DUPLICATE = "duplicate";
const ACCESS_API_KEY = "accessApiKey";
const GENERATE_API_KEY = "generateApiKey";
const REVOKE_API_KEY = "revokeApiKey";
const DELETE = "DELETE";
const UPDATE = "UPDATE";
const SHIELDINGS = "shieldings";
const PROGRESS = "progress";
const DOWNLOAD = "download";

export const projectsQueryKeysFactory = () => {
  return {
    all: () => [appShieldingRootPath, projectsRootQueryKey] as const,
    create: () => [...projectsQueryKeysFactory().all(), CREATE] as const,
    lists: () => [...projectsQueryKeysFactory().all(), LISTS] as const,
    list: (params: ProjectsListParam) => [...projectsQueryKeysFactory().lists(), params] as const,
    items: () => [...projectsQueryKeysFactory().all(), ITEMS] as const,
    item: (projectId: ID | undefined) => {
      const projectItem = [...projectsQueryKeysFactory().items(), projectId ?? ""] as const;

      return {
        everything: () => projectItem,
        details: () => [...projectItem, DETAILS] as const,
        configuration: () => [...projectItem, CONFIGURATION] as const,
        duplicate: () => [...projectItem, DUPLICATE] as const,
        accessApiKey: () => {
          const accessApiKey = [
            ...projectsQueryKeysFactory()
              .item(projectId ?? "")
              .everything(),
            ACCESS_API_KEY,
          ] as const;

          return {
            all: () => accessApiKey,
            item: (apiKey: string | undefined) => [...accessApiKey, apiKey] as const,
            generate: () => [...projectItem, GENERATE_API_KEY] as const,
            revoke: () => [...projectItem, REVOKE_API_KEY] as const,
          };
        },
        delete: () => [...projectItem, DELETE] as const,
        update: () => [...projectItem, UPDATE] as const,
        shieldings: () => {
          const shieldings = [
            ...projectsQueryKeysFactory()
              .item(projectId ?? "")
              .everything(),
            SHIELDINGS,
          ] as const;

          return {
            all: () => shieldings,
            create: () => [...shieldings, CREATE] as const,
            lists: () => [...shieldings, LISTS] as const,
            items: () => [...shieldings, ITEMS] as const,
            item: (shieldingId: ID | undefined) => {
              const shieldingItem = [
                ...projectsQueryKeysFactory()
                  .item(projectId ?? "")
                  .shieldings()
                  .items(),
                shieldingId ?? "",
              ] as const;

              return {
                everything: () => shieldingItem,
                details: () => [...shieldingItem, DETAILS] as const,
                progress: () => [...shieldingItem, PROGRESS] as const,
                download: () => [...shieldingItem, DOWNLOAD] as const,
              };
            },
          };
        },
      } as const;
    },
  };
};

// Valid 404 error can be only on project item or its descendants
// Other 404 errors are considered as BE bug => 500
export const considerProjectsQueryKey404As500 = (key: QueryKey) => {
  const all = projectsQueryKeysFactory().all();
  const items = projectsQueryKeysFactory().items();

  return (
    key.at(-1) === CREATE ||
    (hashKey(all) === hashKey(key.slice(0, all.length)) && hashKey(items) !== hashKey(key.slice(0, items.length)))
  );
};

export const invalidationProjectsKeysOn404 = (key: QueryKey) => ({
  invalidateKey: projectsQueryKeysFactory().lists(),
  removeKey: key.slice(0, projectsQueryKeysFactory().item("any").everything().length),
});
