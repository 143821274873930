import { useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { ApiEntity } from "../../../../../../_common/api/api-entity";
import { getOne } from "../../../../../../_common/api/methods/get-one";
import { queryKeys } from "../../../../../../_common/api/query-keys/query-keys";
import { useRestClient } from "../../../../../../_common/api/rest-client/rest-client-context";
import { ID } from "../../../../../../_common/types";
import { appShieldingRootPath } from "../../../../app-shielding-root-path";
import { isShieldingInProgress } from "../../utils/is-shielding-in-progress";
import { ShieldingI18nToastKey } from "../_support/shielding-i18n-toast-key";
import { GetShielding } from "../get-shielding";
import { GetShieldingsListItem } from "../get-shieldings-list-item";
import { ShieldingsActions } from "../types";
import { useShowToastOnShieldingSuccess } from "../use-show-toast-on-shielding-success";
import { GetShieldingProgress } from "./api-get-shielding-progress";

const PING_INTERVAL = 5000;

interface UseGetShieldingProgress {
  shielding: GetShieldingsListItem | GetShielding | undefined;
  project: { id: ID; projectName: string } | undefined;
}
export function useGetShieldingProgress({ shielding, project }: UseGetShieldingProgress) {
  const queryClient = useQueryClient();
  const restClient = useRestClient();

  const query = useQuery({
    queryKey: queryKeys[appShieldingRootPath].projects().item(project?.id).shieldings().item(shielding?.id).progress(),
    queryFn: () =>
      getOne(restClient, ApiEntity.Shielding, undefined, GetShieldingProgress, {
        action: ShieldingsActions.Progress,
        uuid: shielding!.id,
      })
        .then(data => {
          if (isShieldingInProgress(shielding!.shieldingStatus)) {
            // prettier-ignore
            if (shielding!.shieldingStatus !== data.shieldingStatus) {
              // if status has changed, invalidate the shielding details and shielding list to update status, package file etc.
              void queryClient.invalidateQueries({queryKey:queryKeys[appShieldingRootPath].projects().item(project?.id).shieldings().lists()});
              void queryClient.invalidateQueries({queryKey:queryKeys[appShieldingRootPath].projects().item(project?.id).shieldings().item(shielding?.id).details()});
            } else {
              // if status has not changed, set the logs on the shielding list item
              queryClient.setQueryData<GetShieldingsListItem & Pick<GetShieldingProgress, "shieldingLogs">>(
                queryKeys[appShieldingRootPath].projects().item(project?.id).shieldings().item(shielding?.id).details(),
                oldData =>
                  oldData && oldData?.shieldingLogs !== data.shieldingLogs
                    ? { ...oldData, shieldingLogs: data.shieldingLogs }
                    : undefined,
              );
            }
          }
          return data;
        })
        .catch((error: unknown) => {
          if (error instanceof AxiosError && error.response?.status === 404) throw error;
          else return null;
        }),
    meta: { i18nToastKey: ShieldingI18nToastKey.GET_SHIELDING, resourceName: project?.projectName },
    enabled: isShieldingInProgress(shielding?.shieldingStatus),
    refetchInterval: query =>
      isShieldingInProgress(query.state.data?.shieldingStatus ?? shielding?.shieldingStatus) && PING_INTERVAL,
  });

  useShowToastOnShieldingSuccess({
    initialShieldingStatus: shielding?.shieldingStatus,
    currentShieldingStatus: query.data?.shieldingStatus,
  });

  return query;
}
