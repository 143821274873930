import { Duration, Theme } from "@mui/material/styles";
import { CSSProperties } from "react";

export const enum TransitionType {
  Open = "enteringScreen",
  Close = "leavingScreen",
}

export const sideBarTransition = (
  theme: Theme,
  transitionType: keyof Pick<Duration, "enteringScreen" | "leavingScreen">,
  props: string | string[],
): CSSProperties["transition"] =>
  theme.transitions.create(props, {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration[transitionType],
  });
