import { Box, Typography } from "@mui/material";

import { useTranslate } from "../../../../../_common/i18n/hooks/use-translate.hook";
import { Alert } from "../../../../../_common/ui/alert/alert";

interface NewShielderItemsAlertProps {
  newShielderVersion: string | undefined;
  newItems: string[] | undefined;
}

export function NewShielderItemsAlert({ newShielderVersion, newItems }: NewShielderItemsAlertProps) {
  const t = useTranslate();

  return newItems?.length && newShielderVersion ? (
    <Box marginBottom="30px">
      <Alert
        type="info"
        description={
          <Typography ml={0.75} lineHeight="20px">
            {t("common.configuration.new-parameters.alert", { version: `v${newShielderVersion}` })}
          </Typography>
        }
      />
    </Box>
  ) : null;
}
