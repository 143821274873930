import { getMetadataItems, ShielderMetadata, Widget } from "@mob/shielder-metadata";
import { useMemo } from "react";

import { isNotFalsy } from "../../../../../_common/utils/is-not-falsy";
import { generateConfigurationFormSchema } from "./generators/generate-configuration-form-schema";
import { generateConfigurationResponseSchema } from "./generators/generate-configuration-response-schema";
import { generateFilterOutOldConfigValuesFunction } from "./generators/generate-filter-out-old-config-values-function";
import { generateMetadataFeatureValues } from "./generators/generate-metadata-feature-values";
import { generateNewDefaultConfiguration } from "./generators/generate-new-default-configuration";
import { generateResetDebugOnlyFieldsFunction } from "./generators/generate-reset-debug-only-fields-function";
import { getNewConfigurationItemsFromNewShielder } from "./generators/get-new-configuration-items-from-new-shielder";
import { getValidationDependencies } from "./generators/get-validation-dependencies";

export function useConfigurationSchema(
  newShielderMetadata: ShielderMetadata | undefined,
  oldShielderMetadata: ShielderMetadata | undefined,
) {
  const newItems = useMemo(
    () => (newShielderMetadata ? getMetadataItems(newShielderMetadata) : undefined),
    [newShielderMetadata],
  );
  const oldItems = useMemo(
    () => (oldShielderMetadata ? getMetadataItems(oldShielderMetadata) : undefined),
    [oldShielderMetadata],
  );

  return useMemo(
    () =>
      newItems && oldItems && newShielderMetadata && oldShielderMetadata
        ? {
            featureValues: generateMetadataFeatureValues(newShielderMetadata),
            allItems: newItems.map(item => item.name),
            certificateFields: newItems
              .map(item => (item.widget === Widget.CertificateUploader ? item.name : null))
              .filter(isNotFalsy),
            configurationFormSchema: generateConfigurationFormSchema(newItems),
            configurationResponseNewSchema: generateConfigurationResponseSchema(newItems),
            configurationResponseOldSchema: generateConfigurationResponseSchema(oldItems),
            debuggable: newItems.some(item => item.debugModeOnly),
            filterOutOldConfigValues: generateFilterOutOldConfigValuesFunction({ newItems, oldItems }),
            newDefaultConfiguration: generateNewDefaultConfiguration(newItems),
            newItems: getNewConfigurationItemsFromNewShielder(oldItems, newItems),
            resetDebugOnlyFields: generateResetDebugOnlyFieldsFunction(newItems),
            validationDependencies: getValidationDependencies(newItems),
          }
        : {},
    [newItems, newShielderMetadata, oldItems, oldShielderMetadata],
  );
}
