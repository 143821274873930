import { CheckBox as MuiCheckboxIcon, CheckBoxOutlineBlank, IndeterminateCheckBox } from "@mui/icons-material";
import { alpha, Checkbox as MuiCheckbox, CheckboxProps as MuiCheckboxProps, SvgIconProps } from "@mui/material";
import { forwardRef } from "react";
import { tss } from "tss-react/mui";

import { useFormFieldContext } from "../form-field/form-field-context";
import { InputStatus } from "../form-input/form-input";

interface CheckboxProps extends MuiCheckboxProps {
  status?: InputStatus;
  iconProps?: SvgIconProps;
}

export const Checkbox = forwardRef<HTMLButtonElement, CheckboxProps>(
  (
    { "aria-describedby": ariaDescribedByProp, classes = {}, iconProps, id: idProp, status: statusProp, ...props },
    ref,
  ) => {
    const fieldContext = useFormFieldContext();
    const ariaDescribedBy = fieldContext?.helperTextId || ariaDescribedByProp;
    const status = fieldContext?.status || statusProp || undefined;
    const id = fieldContext?.id || idProp;
    const { classes: internalClasses, cx } = useStyles({ status: status || "primary" });
    const { root: rootClass, ...otherClasses } = classes;

    return (
      <MuiCheckbox
        color="primary"
        classes={{
          root: cx(rootClass, internalClasses.root),
          ...otherClasses,
        }}
        disableRipple
        checkedIcon={<MuiCheckboxIcon viewBox="3 3 18 18" {...iconProps} />}
        icon={<CheckBoxOutlineBlank viewBox="3 3 18 18" {...iconProps} />}
        id={id}
        indeterminateIcon={<IndeterminateCheckBox viewBox="3 3 18 18" {...iconProps} />}
        inputProps={{
          "aria-describedby": ariaDescribedBy,
        }}
        ref={ref}
        size="small"
        {...props}
      />
    );
  },
);

Checkbox.displayName = "Checkbox";

const useStyles = tss.withParams<{ status: InputStatus | "primary" }>().create(({ theme, status }) => {
  const REGULAR_COLOR = theme.palette.mode === "dark" ? theme.palette.grey[400] : theme.palette.grey[900];
  return {
    root: {
      color: status !== "primary" ? theme.palette[status].main : REGULAR_COLOR,
      "&:hover, &.Mui-focusVisible": {
        color: theme.palette[status].main,
        boxShadow: `0 0 0 3px ${alpha(theme.palette[status].main, 0.6)}`,
      },
      "&:hover": {
        "&.Mui-focusVisible": {
          boxShadow: `0 0 0 4px ${alpha(theme.palette[status].main, 0.6)}`,
        },
      },
      "&.Mui-checked": {
        color: status !== "primary" ? theme.palette[status].main : REGULAR_COLOR,
        "&:hover, &.Mui-focusVisible": {
          color: theme.palette[status].main,
        },
      },
      "&.MuiCheckbox-indeterminate": {
        "&:hover, &.Mui-focusVisible": {
          color: theme.palette[status].main,
        },
      },
    },
  };
});
