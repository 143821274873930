import { alpha, Box, CardContent } from "@mui/material";
import { PropsWithChildren, ReactNode } from "react";
import { makeStyles } from "tss-react/mui";

import { TypographyOrComponent } from "../typography-or-component/typography-or-component";

interface CardSectionProps extends PropsWithChildren<unknown> {
  className?: string;
  title?: ReactNode;
  description?: ReactNode;
  borderTop?: boolean;
  borderBottom?: boolean;
  borderRight?: boolean;
  borderLeft?: boolean;
  testId?: string;
  subtitle?: ReactNode;
  component?: React.ReactNode;
  "aria-label"?: string;
}

export const CardSection = (props: CardSectionProps) => {
  const { title, children, description, testId, borderTop, borderBottom, borderLeft, borderRight, subtitle, ...rest } =
    props;
  const { classes } = useStyles({ borderTop, borderBottom, borderLeft, borderRight });

  return (
    <CardContent className={classes.cardContent} role="region" data-testid={testId} {...rest}>
      <Box className={classes.titleContainer}>
        {title && (
          <TypographyOrComponent
            content={title}
            typographyProps={{ className: classes.title, variant: "subtitle1", component: "h3", fontWeight: "bold" }}
          />
        )}
        {subtitle}
      </Box>
      {description && (
        <TypographyOrComponent
          content={description}
          typographyProps={{
            className: classes.description,
          }}
        />
      )}
      {children && <Box className={classes.container}>{children}</Box>}
    </CardContent>
  );
};

const createBorder = (color: string, isActive?: boolean) => {
  return isActive ? `1px solid ${alpha(color, 0.1)}` : undefined;
};

type StyleProps = Pick<CardSectionProps, "borderBottom" | "borderLeft" | "borderRight" | "borderTop">;
const useStyles = makeStyles<StyleProps>()((theme, { borderTop, borderBottom, borderLeft, borderRight }) => ({
  cardContent: {
    borderTop: createBorder(theme.palette.common.black, borderTop),
    borderBottom: createBorder(theme.palette.common.black, borderBottom),
    borderLeft: createBorder(theme.palette.common.black, borderLeft),
    borderRight: createBorder(theme.palette.common.black, borderRight),
    flexGrow: 1,
    minHeight: theme.spacing(10.5),
    boxSizing: "border-box",
    padding: theme.spacing(2.5),
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: theme.spacing(0.5, 0),
  },
  title: {
    fontWeight: 600,
    textTransform: "uppercase",
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(20),
  },
  description: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
    wordBreak: "break-word",
  },
  container: {
    "& > *:first-of-type": {
      marginTop: theme.spacing(2.5),
      display: "inline-flex",
    },
  },
}));
