import { Typography } from "@mui/material";

import { DateTimeFormat, useDateTime } from "../../../../../../../../_common/i18n/hooks/use-date-time.hook";
import { TextSkeleton } from "../../../../../../../../_common/ui/skeleton/text-skeleton";
import { ColumnCellProps } from "../../../../../../../../_common/ui/table/data-table";
import { GetShieldingsListItem } from "../../../../api/get-shieldings-list-item";

type StartedCellProps = ColumnCellProps<GetShieldingsListItem>;

export const StartedCell = ({ isLoading, shieldingStartedAt }: StartedCellProps) => {
  const dateFormat = useDateTime();

  return isLoading ? (
    <TextSkeleton height="24px" />
  ) : (
    <Typography>{dateFormat(shieldingStartedAt, DateTimeFormat.YYYYMMDDhmLocalTimeFormat)}</Typography>
  );
};
