import { Typography } from "@mui/material";

import { DateTimeFormat, useDateTime } from "../../../../../../../../_common/i18n/hooks/use-date-time.hook";
import { TextSkeleton } from "../../../../../../../../_common/ui/skeleton/text-skeleton";
import { ColumnCellProps } from "../../../../../../../../_common/ui/table/data-table";

type LastUpdatedCellProps = ColumnCellProps<
  {
    projectUpdatedAt: Date;
  },
  unknown
>;
export const LastUpdatedCell = (props: LastUpdatedCellProps) => {
  const dateFormat = useDateTime();

  return props.isLoading ? (
    <TextSkeleton height="24px" />
  ) : (
    <Typography>{dateFormat(props.projectUpdatedAt, DateTimeFormat.YYYYMMDDhmLocalTimeFormat)}</Typography>
  );
};
