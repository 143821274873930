import { z } from "zod";

import { DateTime } from "../../../../../../_common/types";
import { GeneralConfigValues } from "../types/config-values";

export function apiReplaceConfiguration<TConfigValues extends GeneralConfigValues>(configValuesSchema: TConfigValues) {
  return z.object({
    configValues: configValuesSchema,
    configUpdatedAt: DateTime,
    testMode: z.boolean(),
  });
}
export type ApiReplaceConfiguration = z.input<ReturnType<typeof apiReplaceConfiguration>>;

function replaceConfigurationTransformer<T extends z.infer<ReturnType<typeof apiReplaceConfiguration>>>({
  testMode,
  configValues,
  ...rest
}: T) {
  return {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    configValues: { ...configValues, debugMode: testMode },
    ...rest,
  };
}

export function replaceConfiguration<TConfigValues extends GeneralConfigValues>(configValuesSchema: TConfigValues) {
  return apiReplaceConfiguration(configValuesSchema).transform(replaceConfigurationTransformer);
}
export type ReplaceConfiguration = z.infer<ReturnType<typeof replaceConfiguration>>;
