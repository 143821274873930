import { Button, ButtonProps } from "@mui/material";
import { MouseEvent } from "react";
import { makeStyles } from "tss-react/mui";

export const RowButton = ({ children, onClick, className, ...props }: ButtonProps) => {
  const { classes, cx } = useStyles();

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <Button className={cx(classes.root, className)} color="primary" variant="text" onClick={handleClick} {...props}>
      {children}
    </Button>
  );
};

const useStyles = makeStyles()(theme => ({
  root: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 600,
    textDecoration: "none",
    padding: 0,
    "&:active, &.Mui-focusVisible, &:focus, &.Mui-focusVisible:active": {
      textDecoration: "none",
    },
  },
}));
