import { alpha, ClassNameMap, Typography } from "@mui/material";
import { forwardRef, HTMLAttributes, ReactNode } from "react";
import { tss } from "tss-react/mui";

import { StatusIcon } from "../status-icon/status-icon";
import { useFormFieldContext } from "./form-field-context";

interface FormFieldHelperTextProps extends HTMLAttributes<HTMLDivElement> {
  status?: "error" | "success" | "warning";
  children: ReactNode;
  classes?: Partial<ClassNameMap<"root" | "statusIcon" | "helperText">>;
  id?: string;
  afterAddon?: ReactNode;
}

export const FormFieldHelperText = forwardRef<HTMLDivElement, FormFieldHelperTextProps>(
  ({ children, classes, className, id: idProp, status: statusProp, afterAddon, ...props }, ref) => {
    const fieldContext = useFormFieldContext();
    const status = fieldContext?.status || statusProp;
    const id = fieldContext?.helperTextId || idProp;
    const { cx, classes: internalClasses } = useStyles({ status });

    return (
      <div className={cx(classes?.root, className, internalClasses.root)} ref={ref} {...props}>
        <Typography
          id={id}
          component="div"
          className={cx(classes?.helperText, internalClasses.helperText)}
          aria-live={fieldContext?.status ? "polite" : undefined}
          aria-atomic={fieldContext?.status ? true : undefined}
        >
          {status ? (
            <StatusIcon
              className={cx(classes?.statusIcon, internalClasses.statusIcon)}
              status={status}
              fontSize="small"
            />
          ) : null}
          <div>{children}</div>
        </Typography>
        {afterAddon}
      </div>
    );
  },
);

FormFieldHelperText.displayName = "FormFieldHelperText";

const useStyles = tss.withParams<{ status: FormFieldHelperTextProps["status"] }>().create(({ theme, status }) => ({
  root: {
    display: "flex",
    alignItems: "flex-start",
    marginTop: theme.spacing(0.5),
    lineHeight: theme.typography.pxToRem(20),
  },
  statusIcon: {
    fontSize: theme.typography.pxToRem(20),
    marginRight: theme.spacing(0.7),
  },
  helperText: {
    width: "100%",
    margin: 0,
    color: status ? theme.palette[status].main : alpha(theme.palette.text.primary, 0.51),
    display: "flex",
    alignItems: "center",
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
  },
}));
