import { SvgIcon, SvgIconProps } from "@mui/material";
import { forwardRef } from "react";

export const WarningAmberIcon = forwardRef<SVGSVGElement, Omit<SvgIconProps, "children">>(
  function WarningAmberIcon(props, ref) {
    return (
      <SvgIcon {...props} ref={ref} data-testid="WarningAmberIcon">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.47 20.5038C2.93 20.5038 1.97 18.8338 2.74 17.5038L10.27 4.49378C11.04 3.16378 12.96 3.16378 13.73 4.49378L21.26 17.5038C22.03 18.8338 21.07 20.5038 19.53 20.5038H4.47ZM19.53 18.5038L12 5.49378L4.47 18.5038H19.53ZM11 10.5038V12.5038C11 13.0538 11.45 13.5038 12 13.5038C12.55 13.5038 13 13.0538 13 12.5038V10.5038C13 9.95378 12.55 9.50378 12 9.50378C11.45 9.50378 11 9.95378 11 10.5038ZM13 17.5038V15.5038H11V17.5038H13Z"
        />
      </SvgIcon>
    );
  },
);
