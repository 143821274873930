import { toast } from "react-toastify";

import { RetryToastButton, showToast } from "../ui/toast";

export class ErrorToast {
  #retries: Record<string, { doRetry: boolean; retries: Set<() => Promise<unknown>> }> = {};

  #addRetry(toastId: string, retry: () => Promise<unknown>) {
    this.#retries[toastId] ??= { doRetry: false, retries: new Set() };
    this.#retries[toastId].retries.add(retry);
  }

  setDoRetry(toastId: string) {
    this.#retries[toastId].doRetry = true;
    return new Promise(res => res(true));
  }

  retryAllIfRequested(toastId: string) {
    const retry = this.#retries[toastId];
    if (retry) {
      if (retry.doRetry) retry.retries.forEach(retry => void retry());
      retry.doRetry = false;
      retry.retries.clear();
    }
  }

  unsubscribe = toast.onChange(payload => {
    if (payload.status === "removed" && payload.type === "error") {
      this.retryAllIfRequested(payload.id.toString());
    }
  });

  show(message: string, retry: (() => Promise<unknown>) | undefined, toastId: string) {
    if (retry && toastId) this.#addRetry(toastId, retry);

    const buttons = retry ? { buttons: <RetryToastButton onClick={() => this.setDoRetry(toastId)} /> } : {};

    showToast("error", message, { ...buttons, toastId });
  }
}
export const errorToast = new ErrorToast();
