import {
  CheckCircleOutlined as SuccessIcon,
  ErrorOutline as ErrorIcon,
  InfoOutlined as InfoIcon,
  WarningAmberOutlined as WarningIcon,
} from "@mui/icons-material";
import { SvgIconProps as MuiSvgInputProps } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { forwardRef } from "react";

export type Status = "info" | "error" | "success" | "warning";

interface StatusIconProps extends MuiSvgInputProps {
  status: Status;
}

const icons = {
  info: InfoIcon,
  success: SuccessIcon,
  warning: WarningIcon,
  error: ErrorIcon,
};

export const StatusIcon = forwardRef<SVGSVGElement, StatusIconProps>(({ status, ...props }, ref) => {
  const theme = useTheme();
  const IconTag = icons[status];

  return status ? <IconTag htmlColor={theme.palette[status].main} ref={ref} {...props} /> : null;
});

StatusIcon.displayName = "StatusIcon";
