import { ThemeOptions } from "@mui/material";
import { Theme } from "@mui/material/styles";

export const accordionTheme = (theme: Theme): ThemeOptions["components"] => ({
  MuiAccordion: {
    styleOverrides: {
      root: {
        "&::before": {
          height: 0,
        },
        "& .MuiAccordionSummary-root": {
          padding: theme.spacing(2.5),
          minHeight: 64,
          "& .MuiAccordionSummary-content": {
            margin: 0,
          },
          "&.Mui-expanded": {
            margin: 0,
          },
        },
        "& .MuiAccordionDetails-root": {
          padding: theme.spacing(2.5),
        },
      },
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        "&:focus": {
          borderRadius: theme.shape.borderRadius,
          outline: `solid 3px ${theme.palette.primary.main}`,
          backgroundColor: "initial",
          "&:not(:focus-visible)": {
            outline: "none",
          },
          "&:active": {
            outline: "none",
          },
        },
      },
    },
  },
});
