import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom";

import { RouterErrorBoundary } from "../_common/ui/error-boundary/router-error-boundary";
import { GlobalLayout } from "../_common/ui/layout/global-layout";
import { AppRouter } from "./app-router";

export const globalRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<GlobalLayout />} errorElement={<RouterErrorBoundary />}>
      <Route path="*" element={<AppRouter />} />
    </Route>,
  ),
);
