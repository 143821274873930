import { useEffect, useMemo } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

const SESSION_STORAGE_KEY = "navigationState";

type PathnameSearchParams = Record<string, string>;

/**
 * Place this hook on pages that need to restore their search params from the session storage
 *
 * @param [paramsToPersist] - Search params that need to be saved
 * @return - If it finished restoring search params from the session storage
 */
export const usePersistedNavigationParameters = (...paramsToPersist: string[]) => {
  const pathname = useLocation().pathname;
  const [searchParams, setSearchParams] = useSearchParams();
  const navigationState = useMemo(getNavigationState, []);

  useEffect(() => {
    const savedUrlParams = navigationState[pathname];

    if (!searchParams.size && savedUrlParams) {
      setSearchParams(new URLSearchParams(savedUrlParams), { replace: true });
    }
  }, [pathname, navigationState, searchParams.size, setSearchParams]);

  useEffect(() => {
    const persistedSearchParams = new URLSearchParams();

    paramsToPersist.forEach(param => {
      const persistedParamsValue = searchParams.get(param);

      if (persistedParamsValue !== null) {
        persistedSearchParams.set(param, persistedParamsValue);
      }
    });

    navigationState[pathname] = persistedSearchParams.toString();
    window.sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(navigationState));
  }, [navigationState, paramsToPersist, pathname, searchParams]);
};

const getNavigationState = () => {
  const storage = sessionStorage.getItem(SESSION_STORAGE_KEY);
  return (storage ? JSON.parse(storage) : {}) as PathnameSearchParams;
};
