import {
  CheckCircle as CheckCircleIcon,
  Close as CloseIcon,
  Error as ErrorIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  Info as InfoIcon,
  InfoOutlined as InfoOutlinedIcon,
  WarningRounded as WarningRoundedIcon,
} from "@mui/icons-material";
import { Alert as MuiAlert, Box, IconButton } from "@mui/material";
import { ReactNode } from "react";
import { makeStyles } from "tss-react/mui";

import { useTranslate } from "../../i18n/hooks/use-translate.hook";
import { TypographyOrComponent } from "../typography-or-component/typography-or-component";
import { AlertButtonBar, AlertButtonBarProps } from "./alert-button-bar";

export const Alert = (props: AlertProps) => {
  const t = useTranslate();
  const { classes, cx } = useStyles({ type: props.type });
  return (
    <MuiAlert
      className={cx(classes.alert, props.className)}
      data-testid={props["data-testid"]}
      iconMapping={{
        success: <CheckCircleIcon />,
        info: !props.type ? <InfoOutlinedIcon /> : <InfoIcon />,
        error: <ErrorIcon />,
        warning: <WarningRoundedIcon />,
      }}
      variant="outlined"
      severity={props.type || "info"}
    >
      <Box>
        <Box className={classes.textContent}>
          {props.title && (
            <Box>
              <TypographyOrComponent content={props.title} typographyProps={{ className: classes.title }} />
            </Box>
          )}

          {props.description && !props.collapsed && (
            <Box>
              <TypographyOrComponent content={props.description} typographyProps={{ className: classes.description }} />
            </Box>
          )}
        </Box>

        <AlertButtonBar
          primaryButton={props.primaryButton}
          secondaryButton={props.secondaryButton}
          type={props.type}
          onClose={props.onClose}
        />
      </Box>
      {props.onCollapse && (
        <Box className={classes.closeButton}>
          <IconButton onClick={props.onCollapse} aria-label={props.collapsed ? t("word.expand") : t("word.collapse")}>
            {props.collapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          </IconButton>
        </Box>
      )}
      {props.onClose && (
        <Box className={classes.closeButton}>
          <IconButton onClick={props.onClose} aria-label={t("word.close")}>
            <CloseIcon />
          </IconButton>
        </Box>
      )}
    </MuiAlert>
  );
};

interface AlertProps extends AlertButtonBarProps {
  ["data-testid"]?: string;
  className?: string;
  title?: ReactNode;
  description?: ReactNode;
  collapsed?: boolean;
}

const useStyles = makeStyles<Pick<AlertButtonBarProps, "type">>()((theme, { type }) => ({
  alert: {
    borderRadius: theme.spacing(0.5),
    border: `1px solid ${!type || type === "info" ? theme.palette.grey[700] : theme.palette[type].main}`,
    backgroundColor: theme.palette.common.white,
    boxSizing: "border-box",
    padding: theme.spacing(2.5),
    "& > *": {
      padding: 0,
    },
    "& .MuiAlert-message": {
      width: "100%",
      justifyContent: "space-between",
      padding: 0,
      display: "flex",
      color: theme.palette.text.primary,
      overflow: "inherit",
      "& > div:first-of-type": {
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        "& > div": {
          height: "100%",
        },
      },
    },
    "& > .MuiAlert-icon": {
      padding: 0,
      "& > .MuiSvgIcon-root": {
        color: !type || type === "info" ? theme.palette.grey[500] : theme.palette[type].main,
      },
    },
  },
  title: {
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(24),
  },
  description: {
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(24),
  },
  closeButton: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    height: "100%",
    "& button": {
      marginTop: theme.spacing(-0.4),
    },
  },
  textContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    "& > div": {
      display: "flex",
      alignItems: "center",
      height: "100%",
    },
    "& a": {
      color: theme.palette.primary.main,
    },
  },
}));
