import { ConfigurationItem, Widget } from "@mob/shielder-metadata";
import { Box } from "@mui/material";
import { ChangeEvent, useCallback } from "react";
import { ControllerRenderProps } from "react-hook-form";
import { makeStyles } from "tss-react/mui";

import { InputItem, InputItemProps } from "./input-item";
import { validationHelperText } from "./utils/validation-helper-text";

interface NumberInputItemProps extends Omit<InputItemProps, "validations" | "multiline" | "placeholder"> {
  validations?: Extract<ConfigurationItem, { widget: Widget.NumberInput }>["validations"];
}

export function NumberInputItem({ helperText, validations, ...inputItemProps }: NumberInputItemProps) {
  const fullHelperText = [
    helperText,
    validationHelperText(validations?.minValue, "min-value"),
    validationHelperText(validations?.maxValue, "max-value"),
  ]
    .filter(Boolean)
    .join(". ");
  const { classes } = useStyles();
  const changeDecorator = useCallback(
    (handler: ControllerRenderProps["onChange"]) => (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget;

      if (/^\d*$/.test(value)) {
        handler(value === "" ? "" : parseInt(value));
      }
    },
    [],
  );

  return (
    <Box className={classes.container}>
      <InputItem {...inputItemProps} helperText={fullHelperText} onChangeDecorator={changeDecorator} />
    </Box>
  );
}

const useStyles = makeStyles()(() => ({
  container: {
    "& label + div": {
      maxWidth: 192,
    },
  },
}));
