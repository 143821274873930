import { CardSection } from "../../../../../../../_common/ui/card/card-section";
import { FixedWidthSkeleton } from "../../../../../../../_common/ui/skeleton/fixed-width-skeleton";
import { FullWidthSkeleton } from "../../../../../../../_common/ui/skeleton/full-width-skeleton";

interface ShieldingPanelSectionProps {
  className?: string;
  title: string;
  addon?: React.ReactNode;
  content: React.ReactNode;
  isLoading: boolean;
}

export function ShieldingPanelSection({ title, addon, className, content, isLoading }: ShieldingPanelSectionProps) {
  return (
    <CardSection
      title={isLoading ? <FixedWidthSkeleton /> : title}
      subtitle={addon && isLoading ? <FixedWidthSkeleton relativeWidth={0.8} /> : addon}
      description={isLoading ? <FullWidthSkeleton /> : content}
      component="section"
      aria-label={title}
      className={className}
    />
  );
}
