import { ThemeOptions } from "@mui/material/styles";

export const listTheme = (): ThemeOptions["components"] => ({
  MuiList: {
    styleOverrides: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
  },
});
