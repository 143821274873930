import { z } from "zod";

const ApiDeleteAccessApiKey = z.any();
// export type ApiDeleteAccessApiKey = z.input<typeof ApiDeleteAccessApiKey>;

/**
 * This empty transform is necessary because we don't expect any data from the server (void type),
 * but we also don't want to restrict the server response to any specific type, so, for the moment
 * this is the only way to achieve this without an explicit type casting.
 */
export const DeleteAccessApiKey = ApiDeleteAccessApiKey.transform(() => {});
export type DeleteAccessApiKey = z.infer<typeof DeleteAccessApiKey>;
