import { ComponentProps, ReactNode } from "react";
import { useController, useFormContext } from "react-hook-form";

import { useHasTranslationKey } from "../../i18n/hooks/use-has-translation-key";
import { useTranslate } from "../../i18n/hooks/use-translate.hook";
import { FormField, FormFieldProps } from "../form-field/form-field";
import { FormFieldHelperText } from "../form-field/form-field-helper-text";
import { FormFieldLabel } from "../form-field/form-field-label";
import { Option } from "../select/option";
import { Select as BaseSelect, SelectProps as BaseSelectProps } from "../select/select";
import { TextSkeleton } from "../skeleton/text-skeleton";

type FormSelectProps = Omit<BaseSelectProps, "children"> &
  Omit<FormFieldProps, "children"> & {
    name: string;
    label: string;
    helperText?: ReactNode;
    isLoading?: boolean;
    skeletonProps?: ComponentProps<typeof TextSkeleton>;
    nothingSelectedLabel: ReactNode;
    options: { label: ReactNode; value: unknown }[];
  };

export const FormSelect = ({
  disabled,
  isLoading = false,
  fullWidth = false,
  id,
  helperText,
  label,
  nothingSelectedLabel,
  skeletonProps,
  value,
  options,
  ...props
}: FormSelectProps) => {
  const t = useTranslate();
  const hasTranslation = useHasTranslationKey();
  const {
    field,
    fieldState: { error },
  } = useController({ name: props.name });
  const {
    clearErrors,
    formState: { isSubmitting },
  } = useFormContext();
  const status = error ? "error" : undefined;
  const errorMessage = hasTranslation(error?.message ?? "") ? t(error?.message ?? "") : error?.message;

  return (
    <FormField data-testid={id && `${id}-dropdown`} id={id} status={status} fullWidth={fullWidth}>
      <FormFieldLabel>
        {isLoading ? <TextSkeleton {...skeletonProps} data-testid={id && `${id}-skeleton-loader`} /> : label || ""}
      </FormFieldLabel>
      <BaseSelect
        {...props}
        {...field}
        disabled={disabled || isLoading || isSubmitting}
        displayEmpty
        fullWidth
        id={id}
        renderValue={value => {
          return value ? options.find(option => option.value == value)?.label : !isLoading && nothingSelectedLabel;
        }}
        status={status}
        value={value ?? (field.value as unknown)}
        onChange={(e, child) => {
          field.onChange(e);
          clearErrors(props.name);
          props.onChange?.(e, child);
        }}
      >
        {options.map(option => {
          return (
            <Option key={createValidKey(option.value)} value={option.value as never}>
              {option.label}
            </Option>
          );
        })}
      </BaseSelect>

      <FormFieldHelperText status={status}>{errorMessage ?? helperText}</FormFieldHelperText>
    </FormField>
  );
};

function createValidKey<T>(value: T) {
  return typeof value === "number" || typeof value === "string" ? value.toString() : JSON.stringify(value);
}
