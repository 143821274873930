import { Backdrop, GlobalStyles, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { useTranslate } from "../../i18n/hooks/use-translate.hook";
import { Alert } from "../../ui/alert/alert";
import { useAuthActions } from "../auth-provider/auth-actions-context";

export const SessionExpired = () => {
  const t = useTranslate();
  const { login } = useAuthActions();
  const { classes } = useStyles();

  return (
    <Backdrop open className={classes.backdrop}>
      <div className={classes.container}>
        <Alert
          type="warning"
          title={t("component.sessionExpiration.title")}
          description={<Typography>{t("component.sessionExpiration.message")}</Typography>}
          primaryButton={{
            label: t("component.sessionExpiration.loginButton"),
            onClick: login,
          }}
        />
      </div>
      <GlobalStyles styles={{ body: { overflow: "hidden" } }} />
    </Backdrop>
  );
};

const useStyles = makeStyles()(() => ({
  backdrop: {
    position: "fixed",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    overflow: "hidden",
    zIndex: 5000,
  },
  container: {
    width: 780,
  },
}));
