import { useTranslate } from "../i18n/hooks/use-translate.hook";
import { SizeUnit } from "../types";
import { useNumberFormat } from "../utils/number-format";

export function useBytesFormatter() {
  const t = useTranslate();
  const numberFormatter = useNumberFormat();

  return (bytes: number) => {
    if (bytes === 0) return "0 B";
    const kb = 1024;
    const sizes = Object.values(SizeUnit);
    const size = Math.floor(Math.log(bytes) / Math.log(kb));
    const value = parseFloat((bytes / Math.pow(kb, size)).toFixed(1));

    return t(`common.size.unit.${sizes[size]}`, { value: numberFormatter(value) });
  };
}
