export enum SettingsFeatures {
  ClientCredentials = "client-credentials",
}

export enum SettingsActions {
  GoToApiDocumentation = "go-to-api-documentation",
  CopyClientIdToClipboard = "copy-client-id-to-clipboard",
  CopyClientSecretToClipboard = "copy-client-secret-to-clipboard",
  CreateClientCredentials = "create-client-credentials",
  OpenDeleteClientCredentials = "open-delete-client-credentials",
  DeleteClientCredentials = "delete-client-credentials",
}
