import { Theme, ThemeOptions } from "@mui/material/styles";

export const inputLabelTheme = (theme: Theme): ThemeOptions["components"] => ({
  MuiInputLabel: {
    styleOverrides: {
      root: {
        color: theme.palette.text.primary,
      },
      formControl: {
        position: "relative",
        transform: "none",
        color: "currentColor",
        "&.Mui-focused": {
          color: "inherit",
          transform: "none",
        },
      },
      shrink: {
        transform: "none",
      },
      animated: {
        transition: "none",
      },
    },
  },
});
