import { Box, CircularProgress, Typography } from "@mui/material";
import { ReactNode } from "react";

export const Spinner = ({ children }: { children: ReactNode }) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="100vh" gap={2}>
      <CircularProgress />
      <Typography>{children}</Typography>
    </Box>
  );
};
