import { AxiosInstance, AxiosResponse } from "axios";
import { ZodType, ZodTypeDef } from "zod";

import { ID } from "../../types";
import { searchParams } from "../../utils/search-params";
import { ApiEntity } from "../api-entity";
import { parseMutationErrors } from "../errors/parse-mutation-errors";
import { CustomAction } from "./custom-action";
import { parseMutationResponse } from "./utils/parse-mutation-response";

export function postOne<TItem, TApiItem, TPayload, Def extends ZodTypeDef = ZodTypeDef>(
  restClient: AxiosInstance,
  apiEntity: ApiEntity,
  data: TPayload,
  itemType: ZodType<TItem, Def, TApiItem>,
  customAction: CustomAction,
  entityId: ID,
): Promise<TItem>;

export function postOne<TItem, TApiItem, TPayload, Def extends ZodTypeDef = ZodTypeDef>(
  restClient: AxiosInstance,
  apiEntity: ApiEntity,
  data: TPayload,
  itemType: ZodType<TItem, Def, TApiItem>,
): Promise<TItem>;

export function postOne<TItem, TApiItem, TPayload, Def extends ZodTypeDef = ZodTypeDef>(
  restClient: AxiosInstance,
  apiEntity: ApiEntity,
  data: TPayload,
  itemType: ZodType<TItem, Def, TApiItem>,
  customAction?: CustomAction,
  entityId?: ID,
) {
  const customPath =
    !entityId || !customAction
      ? ""
      : customAction === CustomAction.Copy
        ? `/${entityId}/${customAction}`
        : searchParams({ uuid: entityId, action: customAction });

  return restClient
    .post<unknown, AxiosResponse<unknown, TPayload>, TPayload>(`${apiEntity}${customPath}`, data)
    .then(parseMutationResponse(itemType))
    .catch(parseMutationErrors<TPayload, TItem>);
}
