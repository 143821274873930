export const enum AuthState {
  // Either got a token from Cognito, or got a token from LocalStorage and already used it successfully, and
  // in both cases token is not expired yet
  AuthenticatedConfirmed = "confirmed",
  // Got token from LocalStorage, it didn't expire yet but we got neither successful, nor unsuccessful
  // respond from BE yet, so we don't know yet is it still valid or not
  AuthenticatedNotConfirmed = "non-confirmed",
  // Either the token was got from the Cognito and already expired,
  // or there was already successful request to BE with the token, and
  // either this token already expired, or the last request got 401,
  // so the Session Expired page is shown to the user
  ShowingSessionExpiredPage = "expired",
  // Either user clicked Log in button on Session Expired Dialog,
  // or the App is just started and there is no token in LocalStorage
  // or the first request to BE with the token from LocalStorage gave 401
  // so the App is redirecting to Login Page
  RedirectingToLoginPage = "login",
  // User clicked Logout
  RedirectingToLogoutPage = "logout",
}
