import { Route, Routes } from "react-router-dom";

import { useTranslate } from "../../_common/i18n/hooks/use-translate.hook";
import { NotFound } from "../../_common/ui/not-found/not-found";
import { SettingsPage } from "./settings-page";
import { settingsRootPath } from "./settings-root-path";

export const SettingsRouter = () => {
  const t = useTranslate();
  const notFound = (
    <NotFound
      rootPath={`/${settingsRootPath}`}
      rootButtonLabel={t(`component.empty-state.not-found.button.${settingsRootPath}`)}
    />
  );

  return (
    <Routes>
      <Route index element={<SettingsPage />} />
      <Route path="not-found" element={notFound} />
      <Route path="*" element={notFound} />
    </Routes>
  );
};
