import { FieldError } from "react-hook-form";
import { makeStyles } from "tss-react/mui";

import { InputValidationMessage } from "../../../../../_common/ui/form-input/input-validation-message";

interface ItemValidationMessageProps {
  itemName: string;
  error?: FieldError;
}
export function ItemValidationMessage({ itemName, error }: ItemValidationMessageProps) {
  const { classes } = useStyles();
  return error ? (
    <InputValidationMessage
      status="error"
      inputName={itemName}
      className={classes.errorMessage}
      message={error.message}
    />
  ) : null;
}

const useStyles = makeStyles()(theme => ({
  errorMessage: {
    marginTop: theme.spacing(0.7),
    marginBottom: 0,
    wordBreak: "break-word",
  },
}));
