import { Theme, ThemeOptions } from "@mui/material/styles";

export const listItemTheme = (theme: Theme): ThemeOptions["components"] => ({
  MuiListItem: {
    styleOverrides: {
      root: {
        "&.Mui-selected": {
          color: theme.palette.mode === "dark" ? theme.palette.common.black : theme.palette.text.primary,
          "&:hover": {
            backgroundColor: theme.palette.primary.light,
          },
        },
        "&.Mui-disabled": {
          opacity: 0.7,
        },
      },
    },
  },
});
