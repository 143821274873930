import { ThemeOptions } from "@mui/material";
import { Theme } from "@mui/material/styles";

export const inputBaseTheme = (theme: Theme): ThemeOptions["components"] => ({
  MuiInputBase: {
    styleOverrides: {
      root: {
        background: theme.palette.mode === "dark" ? theme.palette.grey[900] : theme.palette.common.white,
        boxSizing: "border-box",
        borderRadius: theme.shape.borderRadius,
        msDisplay: "inline",
        display: "inline-flex",
        fontSize: theme.typography.subtitle2.fontSize,
        height: theme.spacing(5),
        minHeight: "auto",
        padding: 0,
        alignItems: "center",
        "&.Mui-disabled": {
          color: theme.palette.grey[400],
          border: `solid 1px ${theme.palette.grey[500]}`,
          background: theme.palette.mode === "dark" ? theme.palette.grey[800] : theme.palette.grey[200],
          boxShadow: "none",
          pointerEvents: "none",
          "&:hover": {
            border: `solid 1px ${theme.palette.grey[500]}`,
            boxShadow: "none",
          },
        },
        "&:after": {
          content: `""`,
          minHeight: "inherit",
          fontSize: 0,
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: theme.typography.subtitle1.fontSize,
        },
      },
      input: {
        "&::placeholder": {
          color: "currentColor",
          fontSize: "inherit",
        },
        "&.MuiSelect-outlined.MuiSelect-outlined": {
          paddingRight: "0 !important",
        },
        borderRadius: theme.shape.borderRadius,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        boxSizing: "border-box",
        height: `calc(${theme.spacing(5)} - 2px)`,
      },
      inputAdornedEnd: {
        "& + svg": {
          margin: `3px 0 0 3px`,
        },
      },
      multiline: {
        height: "auto",
        minHeight: theme.spacing(5),
        padding: 0,
      },
      inputMultiline: {
        padding: theme.spacing(1),
        resize: "vertical",
      },
    },
  },
});
