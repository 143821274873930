import { useMutation, useQueryClient } from "@tanstack/react-query";

import { ApiEntity } from "../../../../../../_common/api/api-entity";
import { MutationError } from "../../../../../../_common/api/errors/mutation-error";
import { ValidationErrors } from "../../../../../../_common/api/errors/validation-errors";
import { postOne } from "../../../../../../_common/api/methods/post-one";
import { queryKeys } from "../../../../../../_common/api/query-keys/query-keys";
import { useRestClient } from "../../../../../../_common/api/rest-client/rest-client-context";
import { showToast } from "../../../../../../_common/ui/toast";
import { appShieldingRootPath } from "../../../../app-shielding-root-path";
import { GetProject } from "../../../projects/api/get-project";
import { AccessApiKeyI18nToastKey } from "../_support/access-api-key-i18n-toast-key";
import { CreateAccessApiKey } from "./create-access-api-key";
import { CreateAccessApiKeyInput, createAccessApiKeyInputTransformer } from "./create-access-api-key-input";

export function useCreateAccessApiKey(project?: GetProject) {
  const queryClient = useQueryClient();
  const restClient = useRestClient();

  return useMutation<CreateAccessApiKey, MutationError<CreateAccessApiKeyInput>, CreateAccessApiKeyInput>({
    mutationKey: queryKeys[appShieldingRootPath].projects().item(project!.id).accessApiKey().generate(),
    mutationFn: variables =>
      postOne(restClient, ApiEntity.AccessApiKey, createAccessApiKeyInputTransformer(variables), CreateAccessApiKey),

    onError(error) {
      if (error instanceof ValidationErrors && error.hasGeneralErrors) {
        showToast("error", error.generalErrorsMessage, { autoClose: true });
        void queryClient.invalidateQueries({
          queryKey: queryKeys[appShieldingRootPath].projects().item(project!.id).details(),
        });
      }
    },
    onSuccess() {
      void queryClient.invalidateQueries({
        queryKey: queryKeys[appShieldingRootPath].projects().item(project!.id).details(),
      });
      void queryClient.invalidateQueries({ queryKey: queryKeys[appShieldingRootPath].projects().lists() });
    },
    meta: {
      i18nToastKey: AccessApiKeyI18nToastKey.CREATE_API_KEY,
      resourceName: project!.projectName,
    },
  });
}
