import { alpha, ThemeOptions } from "@mui/material/styles";

import { mixins } from "./mixins";

declare module "@mui/material/styles" {
  interface Palette {
    default?: Palette["primary"];
  }
  interface PaletteOptions {
    default?: PaletteOptions["primary"];
  }
}

export const DEFAULT_SHADOW =
  "0px 1.5px 4.5px -0.5px rgba(0, 0, 0, 0.20), 0px 1px 1px -0.5px rgba(0, 0, 0, 0.25), 0px 0px 1px 0px rgba(0, 0, 0, 0.30)";

export const theme: ThemeOptions = {
  mixins,
  palette: {
    background: {
      default: "#f5f5f5",
    },
    error: {
      main: "#CC3333",
      dark: "#A72A2A",
      light: alpha("#CC3333", 0.09),
    },
    info: {
      main: "#2066D5",
      dark: "#1C59BB",
      light: alpha("#2066D5", 0.1),
    },
    primary: {
      main: "#0C7788",
      dark: "#096271",
      light: alpha("#0C7788", 0.09),
    },
    secondary: {
      main: "#331C86",
      dark: "#28166b",
      light: "#eae8f2",
    },
    success: {
      main: "#0C783E",
      dark: "#0B6634",
      light: alpha("#0C783E", 0.09),
    },
    warning: {
      main: "#B94D18",
      dark: "#953E13",
      light: alpha("#B94D18", 0.1),
    },
    default: {
      main: "#444444",
      dark: "#eeeeee",
      light: "#ffffff",
      contrastText: "#444444",
    },
    text: { primary: "#444" },
    grey: {
      200: "#f2f2f2",
      400: "#a1a1a1",
    },
  },
  shadows: [
    "none",
    DEFAULT_SHADOW,
    DEFAULT_SHADOW,
    DEFAULT_SHADOW,
    DEFAULT_SHADOW,
    DEFAULT_SHADOW,
    DEFAULT_SHADOW,
    DEFAULT_SHADOW,
    DEFAULT_SHADOW,
    DEFAULT_SHADOW,
    DEFAULT_SHADOW,
    DEFAULT_SHADOW,
    DEFAULT_SHADOW,
    DEFAULT_SHADOW,
    DEFAULT_SHADOW,
    DEFAULT_SHADOW,
    DEFAULT_SHADOW,
    DEFAULT_SHADOW,
    DEFAULT_SHADOW,
    DEFAULT_SHADOW,
    DEFAULT_SHADOW,
    DEFAULT_SHADOW,
    DEFAULT_SHADOW,
    DEFAULT_SHADOW,
    DEFAULT_SHADOW,
  ],
  shape: {
    borderRadius: 4,
  },
  typography: {
    fontFamily: "Montserrat,Helvetica,Arial,sans-serif",
    h1: {
      fontSize: "3rem",
      fontWeight: 400,
      "& strong": {
        fontWeight: 500,
      },
    },
    h2: {
      fontSize: "2rem",
      fontWeight: 400,
      "& strong": {
        fontWeight: 500,
      },
    },
    h3: {
      fontSize: "1.75rem",
      fontWeight: 400,
      "& strong, & b": {
        fontWeight: 500,
      },
    },
    h4: {
      fontSize: "1.5rem",
      fontWeight: 400,
      "& strong, & b": {
        fontWeight: 500,
      },
    },
    h5: {
      fontSize: "1.25rem",
      fontWeight: 400,
      "& strong, & b": {
        fontWeight: 500,
      },
    },
    h6: {
      fontSize: "1.125rem",
      fontWeight: 400,
      "& strong, & b": {
        fontWeight: 500,
      },
    },
    subtitle1: {
      fontSize: "1rem",
      fontWeight: 400,
      "& strong, & b": {
        fontWeight: 500,
      },
    },
    subtitle2: {
      fontSize: "0.875rem",
      fontWeight: 400,
      "& strong, & b": {
        fontWeight: 500,
      },
    },
    body1: {
      fontSize: "0.875rem",
      fontWeight: 400,
      "& strong, & b": {
        fontWeight: 500,
      },
    },
    body2: {
      fontSize: "0.75rem",
      fontWeight: 400,
      "& strong, & b": {
        fontWeight: 500,
      },
    },
    button: {
      fontSize: "0.875rem",
      fontWeight: 500,
      textTransform: "none",
    },
    caption: {
      fontSize: "0.75rem",
      fontWeight: 400,
      "& strong, & b": {
        fontWeight: 500,
      },
    },
  },
};
