import { alpha, Theme, ThemeOptions } from "@mui/material";

export const iconButtonTheme = (theme: Theme): ThemeOptions["components"] => ({
  MuiIconButton: {
    styleOverrides: {
      root: {
        ...buttonStyling(theme.spacing(3), theme),
      },
      sizeSmall: buttonStyling(theme.spacing(2.5), theme),
    },
  },
});

function buttonStyling(fontSize: string, theme: Theme) {
  const fontSizeNumber = getSpaceNumber(fontSize);

  return {
    padding: 0,
    height: sizeToPx(fontSizeNumber + 4),
    width: sizeToPx(fontSizeNumber + 4),
    overflow: "hidden",
    transition: "none",
    "&.MuiIconButton-colorSecondary": {
      color: theme.palette.grey[200],
    },
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.black, 0.05),
    },
    "&:focus, &:focus-within": {
      outline: `solid 3px ${theme.palette.primary.main}`,
      backgroundColor: "initial",
      "&:not(:focus-visible)": {
        outline: "none",
        backgroundColor: alpha(theme.palette.common.black, 0.05),
      },
    },
    "&:focus:hover, &:focus-within:hover": {
      backgroundColor: alpha(theme.palette.common.black, 0.05),
    },
    "&:active": {
      backgroundColor: alpha(theme.palette.common.black, 0.1),
      "&:focus:hover, &:focus-within:hover": {
        backgroundColor: alpha(theme.palette.common.black, 0.1),
      },
    },
    "& .MuiSvgIcon-root": {
      fontSize: theme.typography.pxToRem(fontSizeNumber),
    },
  };
}

const getSpaceNumber = (space: string) => {
  const [size] = space.split("p");

  return parseInt(size);
};

const sizeToPx = (size: number) => size.toString() + "px";
