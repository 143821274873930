import { alpha, Box, Typography, useTheme } from "@mui/material";
import { ReactNode } from "react";
import { makeStyles } from "tss-react/mui";

export type FormSectionProps = {
  title: string;
  description?: ReactNode;
  content: ReactNode;
  first?: boolean;
  "aria-label"?: string;
};

export function FormSection({ first, title, description, content, "aria-label": ariaLabel }: FormSectionProps) {
  const theme = useTheme();
  const { classes } = useStyles();

  return (
    <Box
      display="flex"
      height="initial"
      width="100%"
      gap={7}
      padding="32px 0"
      borderTop={first ? undefined : `1px solid ${alpha(theme.palette.common.black, 0.1)}`}
      aria-label={ariaLabel}
      role="region"
    >
      <Box flex={1} sx={{ pr: 1 }}>
        <Typography component="h2" className={classes.sectionTitle}>
          {title}
        </Typography>
        <div className={classes.sectionDescription}>{description ?? null}</div>
      </Box>

      <Box minWidth={384} flex={1}>
        {content}
      </Box>
    </Box>
  );
}

const useStyles = makeStyles()(theme => ({
  sectionTitle: {
    fontSize: "1rem",
    lineHeight: 1.5,
    fontWeight: 600,
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(0.5),
  },
  sectionDescription: {
    "& a": {
      color: theme.palette.primary.main,
    },
  },
}));
