import { InvalidationKeys } from "../../../../_common/api/query-keys/invalidation-keys.type";
import { QueryKey } from "../../../../_common/api/query-keys/query-key.type";
import {
  considerProjectsQueryKey404As500,
  invalidationProjectsKeysOn404,
  projectsQueryKeysFactory,
  projectsRootQueryKey,
} from "../../features/projects/api/_support/projects-query-keys-factory";
import {
  considerShieldersQueryKey404As500,
  invalidationShieldersKeysOn404,
  shieldersQueryKeysFactory,
  shieldersRootQueryKey,
} from "../../features/shielders/api/_support/shielders-query-keys-factory";

export const appShieldingQueryKeys = {
  [projectsRootQueryKey]: projectsQueryKeysFactory,
  [shieldersRootQueryKey]: shieldersQueryKeysFactory,
} as const;

const considerMap = {
  [projectsRootQueryKey]: considerProjectsQueryKey404As500,
  [shieldersRootQueryKey]: considerShieldersQueryKey404As500,
};

export const considerAppShieldingQueryKey404As500 = (key: QueryKey) =>
  considerMap[key[1] as keyof typeof considerMap](key);

const invalidationMap = {
  [projectsRootQueryKey]: invalidationProjectsKeysOn404,
  [shieldersRootQueryKey]: invalidationShieldersKeysOn404,
};

export const invalidationAppShieldingKeysOn404 = (key: QueryKey): InvalidationKeys =>
  invalidationMap[key[1] as keyof typeof invalidationMap](key);
