import { useTheme } from "@mui/material";

import { useTranslate } from "../../../../_common/i18n/hooks/use-translate.hook";
import { Tag } from "../../../../_common/ui/tag/tag";

interface DebugModeTagProps {
  show: boolean;
}

export const DebugModeTag = ({ show }: DebugModeTagProps) => {
  const theme = useTheme();
  const t = useTranslate();
  return show ? (
    <Tag fontWeight={500} text={t("common.configuration.debug-mode.tag")} color={theme.palette.warning.main} />
  ) : null;
};
