import { AxiosInstance, AxiosResponse } from "axios";
import { ZodType, ZodTypeDef } from "zod";

import { ID } from "../../types";
import { ApiEntity } from "../api-entity";
import { parseMutationErrors } from "../errors/parse-mutation-errors";
import { parseMutationResponse } from "./utils/parse-mutation-response";

export function patchOne<TItem, TApiItem, TPayload, Def extends ZodTypeDef = ZodTypeDef>(
  restClient: AxiosInstance,
  apiEntity: ApiEntity,
  entityId: ID,
  data: TPayload,
  itemType: ZodType<TItem, Def, TApiItem>,
) {
  return restClient
    .patch<unknown, AxiosResponse<unknown, TPayload>, TPayload>(`${apiEntity}/${entityId}`, data)
    .then(parseMutationResponse(itemType))
    .catch(parseMutationErrors<TPayload, TItem>);
}
