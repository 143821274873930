import { TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { ID } from "../../../types";
import { FullWidthSkeleton } from "../../skeleton/full-width-skeleton";
import { SortingIcon } from "./sorting-icon";
import { TableHeaderProps } from "./types";
import { useTableSort } from "./use-table-sort.hook";
import { useTableSortLinks } from "./use-table-sort-links.hook";

export function TableHeader<
  TApiSortColumn extends string,
  TUrlSortColumn extends string,
  TData extends { readonly id: ID },
  TContext,
>({
  columns,
  defaultSortColumn,
  sortingParamNames,
  isDataLoading,
  isHeadersLoading,
}: TableHeaderProps<TApiSortColumn, TUrlSortColumn, TData, TContext>) {
  const { sortColumn, sortOrder } = useTableSort<TApiSortColumn, TUrlSortColumn, TData, TContext>(
    columns,
    defaultSortColumn,
    sortingParamNames,
  );
  const sortLinks = useTableSortLinks<TApiSortColumn, TUrlSortColumn, TData, TContext>(
    columns,
    sortColumn,
    sortOrder,
    sortingParamNames,
  );
  const { classes } = useStyles();

  return (
    <TableHead>
      <TableRow className={classes.row}>
        {columns.map(({ key, sorting, title, width, ariaLabel }) => (
          <TableCell
            key={key}
            aria-label={ariaLabel}
            className={classes.cell}
            sx={{ width: width ?? "auto", minWidth: width, boxSizing: "border-box" }}
          >
            {isHeadersLoading ? (
              <div className={classes.noInteraction}>
                <FullWidthSkeleton />
              </div>
            ) : isDataLoading || !sorting ? (
              <div className={classes.noInteraction}>
                <Typography>{title}</Typography>
              </div>
            ) : (
              <Link role="button" to={sortLinks[sorting.apiColumnName]} className={classes.link}>
                <Typography sx={{ mr: 1 }}>{title}</Typography>
                <SortingIcon sorting={sorting} sortColumn={sortColumn} sortOrder={sortOrder} />
              </Link>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles()(theme => ({
  row: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
  },
  cell: {
    padding: 0,
    height: theme.spacing(8),
    border: "none",
    "& .MuiTypography-root": {
      lineHeight: theme.typography.pxToRem(24),
    },
  },
  noInteraction: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    padding: theme.spacing(0, 2.5),
  },
  link: {
    height: "100%",
    width: "100%",
    flex: 1,
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: theme.palette.text.primary,
    boxSizing: "border-box",
    padding: theme.spacing(0, 2.5),
    "&:focus, &:focus-within": {
      boxShadow: `inset 0 0 0 2px ${theme.palette.primary.main}`,
      outline: "none",
      "&:not(:focus-visible)": {
        boxShadow: "none",
      },
    },
    "&:visited": {
      color: theme.palette.text.primary,
    },
  },
}));
