import { TextSkeleton } from "../../../../../../../../_common/ui/skeleton/text-skeleton";
import { ColumnCellProps } from "../../../../../../../../_common/ui/table/data-table";
import { Platform } from "../../../../../shielders/types/platform";
import { ShielderStatus } from "../../../../../shielders/types/shielder-status";
import { Version } from "../../../../../shielders/types/version";
import { ShielderLabel } from "../../../../../shielders/ui/shielder-label";
import { ShielderVersionLabel } from "../../../../../shielders/ui/shielder-version-label";

type ShielderCellProps = ColumnCellProps<{
  shielderPlatform: Platform;
  shielderVersion: Version;
  shielderStatus: ShielderStatus;
}>;
export const ShielderCell = (props: ShielderCellProps) => {
  return props.isLoading ? (
    <TextSkeleton height="24px" />
  ) : props.shielderStatus === ShielderStatus.Latest ? (
    <ShielderVersionLabel platform={props.shielderPlatform} version={props.shielderVersion} hidePlatformName />
  ) : (
    <ShielderLabel
      platform={props.shielderPlatform}
      hidePlatformName
      version={props.shielderVersion}
      status={props.shielderStatus}
      multiline
    />
  );
};
