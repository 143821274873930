import { Box, Card, CardContent, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { useTranslate } from "../../../../../_common/i18n/hooks/use-translate.hook";
import { DebugModeIcon } from "./debug-mode-icon";

interface DebugModeParameterWarningProps {
  debugMode: boolean;
}

export const DebugModeParameterWarning = ({ debugMode }: DebugModeParameterWarningProps) => {
  const t = useTranslate();
  const { classes } = useStyles();
  return (
    <Card className={classes.card}>
      <CardContent className={classes.content} data-testid="debug-mode-parameter-warning">
        <Box display="flex" alignItems="center">
          <DebugModeIcon debugMode={debugMode} smallIcon />
          <Typography className={classes.text}>
            {t(`common.configuration.debug-mode.${debugMode.toString()}.parameter.warning`)}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

const useStyles = makeStyles()(theme => ({
  card: {
    marginTop: theme.spacing(2.5),
  },
  text: {
    marginLeft: theme.spacing(1),
  },
  content: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    padding: `${theme.spacing(1.2)}!important`,
  },
}));
