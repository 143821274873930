/* eslint-disable no-console */
import { ZodError } from "zod";

import { IS_PROD } from "../env-vars";

export const logError = (...errors: unknown[]) => {
  if (!IS_PROD) {
    // TODO Send the error to a logging service
    errors.forEach(e => {
      if (e instanceof ZodError) {
        console.group();
        console.trace(e);
        e.issues.forEach(i => console.error(i));
        console.groupEnd();
      } else if (e instanceof Error) {
        console.trace(e);
      } else {
        console.log(e);
      }
    });
  }
};
/* eslint-enable no-console */
