import { Box, FormControl, FormControlLabel, Tooltip, Typography } from "@mui/material";
import { useController } from "react-hook-form";
import { makeStyles } from "tss-react/mui";

import { addAction, AppShieldingActions, AppShieldingFeatures } from "../../../../../_common/datadog";
import { useTranslate } from "../../../../../_common/i18n/hooks/use-translate.hook";
import { Switch } from "../../../../../_common/ui/switch/switch";

interface DebugModeSwitchProps {
  disabled: boolean;
}
export const DebugModeSwitch = ({ disabled }: DebugModeSwitchProps) => {
  const t = useTranslate();
  const { field } = useController({ name: "debugMode" });
  const { classes } = useStyles({ value: field.value as boolean });
  return (
    <Tooltip title={t("common.configuration.debug-mode.switch.tooltip")}>
      <Box className={classes.container}>
        <FormControl className={classes.switch}>
          <FormControlLabel
            control={
              <Switch
                color={field.value ? "warning" : "default"}
                id="debug-mode-switch"
                name={field.name}
                disabled={disabled}
                checked={field.value as boolean}
                onChange={e => {
                  addAction(
                    e.target.checked ? AppShieldingActions.EnableDebugMode : AppShieldingActions.DisableDebugMode,
                    AppShieldingFeatures.Configurations,
                  );
                  field.onChange(e.target.checked);
                }}
                onBlur={field.onBlur}
                inputRef={field.ref}
                inputProps={{
                  role: "switch",
                  "aria-label": t("common.configuration.debug-mode.switch.aria-label"),
                }}
              />
            }
            label={<Typography className={classes.label}>{t("common.configuration.debug-mode.switch")}</Typography>}
          />
        </FormControl>
      </Box>
    </Tooltip>
  );
};

const useStyles = makeStyles<{ value: boolean }>()((theme, { value }) => ({
  container: {
    display: "flex",
    alignItems: "center",
    backgroundColor: value ? theme.palette.warning.light : theme.palette.grey[100],
    padding: `${theme.spacing(0)} ${theme.spacing(1.5)}`,
    borderRadius: theme.spacing(0.5),
    "&:focus-within": {
      boxShadow: `inset 0 0 0 2px ${theme.palette.primary.main}`,
      "&:not(:focus-visible)": {
        boxShadow: "none",
      },
    },
  },
  label: {
    lineHeight: theme.spacing(3),
  },
  switch: {
    "& .MuiFormControlLabel-root": {
      marginLeft: 0,
      marginRight: 0,
    },
    "& .MuiSwitch-root": {
      padding: 0,
      width: 48,
      height: 24,
      marginRight: theme.spacing(1),
    },
    "& .MuiSwitch-switchBase": {
      overflow: "hidden",
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(24px)",
        color: "#fff",
        "& .MuiSwitch-thumb:before": {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg style="background-color: ${encodeURIComponent(
            theme.palette.warning.main,
          )}" xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
            "#fff",
          )}" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/></svg>')`,
        },
        "& + .MuiSwitch-track": {
          backgroundColor: theme.palette.warning.main,
          opacity: 1,
          border: 0,
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 20,
      height: 20,
      overflow: "hidden",
      "&:before": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: `url('data:image/svg+xml;utf8,<svg style="background-color: ${encodeURIComponent(
          theme.palette.grey[600],
        )}" xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          "#fff",
        )}" d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z" /></svg>')`,
      },
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.grey[600],
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  },
}));
