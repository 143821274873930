import { Box, Drawer, DrawerProps } from "@mui/material";
import { forwardRef, MouseEvent, ReactNode, useCallback } from "react";
import { makeStyles } from "tss-react/mui";

import { LAYOUT_ID } from "../constants";
import { ButtonAndLabel, PanelFooter } from "./panel-footer";
import { PanelCloseCallback, PanelHeader } from "./panel-header";

interface PanelProps extends DrawerProps {
  cancelButton?: ButtonAndLabel;
  confirmButton?: ButtonAndLabel;
  disableBackdropClick?: boolean;
  heading?: ReactNode;
  isLoading?: boolean;
  width?: number;
  onClose?: PanelCloseCallback;
}

export const Panel = forwardRef<HTMLDivElement, PanelProps>(
  (
    {
      disableBackdropClick,
      cancelButton,
      confirmButton,
      heading,
      isLoading,
      children,
      classes = {},
      width,
      onClose,
      ...drawerProps
    },
    ref,
  ) => {
    const { classes: styles } = useStyles({ width });

    const handleClose = (event: MouseEvent<HTMLButtonElement>, reason: "backdropClick") => {
      if (disableBackdropClick && reason === "backdropClick") return;
      return onClose && onClose(event, reason);
    };

    return (
      <Drawer
        className={styles.drawer}
        container={useCallback(() => document.getElementById(LAYOUT_ID), [])}
        classes={{ ...classes }}
        onClose={handleClose}
        anchor="right"
        {...drawerProps}
        ref={ref}
      >
        {heading && <PanelHeader heading={heading} closeHandler={onClose!} />}
        <Box className={styles.content}>{children}</Box>
        {(cancelButton || confirmButton) && (
          <PanelFooter
            confirmButton={confirmButton}
            cancelButton={cancelButton}
            isLoading={isLoading}
            onClose={onClose}
          />
        )}
      </Drawer>
    );
  },
);

const useStyles = makeStyles<{ width?: number }>()((theme, { width = 200 }) => ({
  drawer: {
    "& > .MuiPaper-root": {
      width,
    },
    zIndex: theme.zIndex.modal,
  },
  content: {
    flexGrow: 1,
    maxHeight: "100%",
    overflowY: "auto",
  },
}));

Panel.displayName = "Panel";
