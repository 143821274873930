import type { ConfigurationItem as ConfigurationItemType } from "@mob/shielder-metadata";
import { Card } from "@mui/material";

import { ValidationDependencies } from "../../../configuration-schema/generators/get-validation-dependencies";
import { blockTestId } from "../../../utils/block-test-id";
import { ConfigurationItem } from "../item/configuration-item";

interface ConfigurationBlockProps {
  name: string;
  isEditMode: boolean;
  isLoading: boolean;
  isUpdating: boolean;
  items: ConfigurationItemType[];
  newItems: string[] | undefined;
  validationDependencies: ValidationDependencies;
}

export function ConfigurationBlock({
  name,
  items,
  isEditMode,
  isLoading,
  isUpdating,
  newItems,
  validationDependencies,
}: ConfigurationBlockProps) {
  return (
    <Card data-testid={blockTestId(name)} sx={{ mb: 1.3 }}>
      {items.map((item, index) => (
        <ConfigurationItem
          key={item.name}
          first={!index}
          isEditMode={isEditMode}
          isLoading={isLoading}
          isUpdating={isUpdating}
          item={item}
          newItem={newItems?.some(newItemName => newItemName === item.name) ?? false}
          validationDependency={validationDependencies[item.name]}
        />
      ))}
    </Card>
  );
}
