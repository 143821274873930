import { z } from "zod";

import { DateTime } from "../../../../../../_common/types";
import { ApiCreateProject, createProjectTransformer } from "../create-project/create-project";

export const ApiUpdateProject = ApiCreateProject.extend({
  lastActivityAt: DateTime,
});
export type ApiUpdateProject = z.input<typeof ApiUpdateProject>;

export function updateProjectTransformer<T extends z.infer<typeof ApiUpdateProject>>(value: T) {
  return createProjectTransformer(value);
}

export const UpdateProject = ApiUpdateProject.transform(updateProjectTransformer);
export type UpdateProject = z.infer<typeof UpdateProject>;
