import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { ApiEntity } from "../../../../../_common/api/api-entity";
import { getMany } from "../../../../../_common/api/methods/get-many";
import { queryKeys } from "../../../../../_common/api/query-keys/query-keys";
import { useRestClient } from "../../../../../_common/api/rest-client/rest-client-context";
import { appShieldingRootPath } from "../../../app-shielding-root-path";
import { Platform } from "../types/platform";
import { ShielderStatus } from "../types/shielder-status";
import { Version } from "../types/version";
import { ShielderI18nToastKey } from "./_support/shielder-i18n-toast-key";

export const ApiGetShieldersListItem = z.object({
  shielderId: z.number(),
  shielderPlatform: z.nativeEnum(Platform),
  shielderVersion: Version,
  shielderVersionStatus: z.nativeEnum(ShielderStatus),
});
export type ApiGetShieldersListItem = z.input<typeof ApiGetShieldersListItem>;

export function getShieldersListItemTransformer<T extends z.infer<typeof ApiGetShieldersListItem>>({
  shielderVersionStatus,
  shielderId,
  ...value
}: T) {
  return {
    shielderStatus: shielderVersionStatus,
    shielderId: shielderId.toString(),
    ...value,
  };
}

const GetShieldersListItem = ApiGetShieldersListItem.transform(getShieldersListItemTransformer);
type GetShieldersListItem = z.infer<typeof GetShieldersListItem>;

export const useGetShieldersList = (...statuses: ShielderStatus[]) => {
  const restClient = useRestClient();

  return useQuery({
    queryKey: queryKeys[appShieldingRootPath].shielders().list(statuses),
    queryFn: async () =>
      getMany<GetShieldersListItem, ApiGetShieldersListItem, { status: string }>(
        restClient,
        ApiEntity.Shielder,
        { status: statuses.join(",") },
        GetShieldersListItem,
      ),
    meta: { i18nToastKey: ShielderI18nToastKey.GET_SHIELDERS_LIST },
    select: data => data?.data,
  });
};
