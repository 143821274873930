import { Box, Typography } from "@mui/material";
import { ReactNode } from "react";
import { TypeOptions } from "react-toastify";

interface ToastContentProps {
  type: TypeOptions;
  message: ReactNode;
  buttons?: ReactNode;
}

export function ToastContent({ type, message, buttons }: ToastContentProps) {
  return (
    <Box
      alignItems="center"
      aria-live="polite"
      boxSizing="border-box"
      data-testid={`${type}-toast`}
      display="flex"
      height={1}
      paddingLeft={2}
      paddingY={1.75}
      gap={1.25}
      role={type === "error" ? "alert" : undefined}
    >
      <Typography>{message}</Typography>
      {buttons}
    </Box>
  );
}
