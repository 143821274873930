import { SvgIconComponent } from "@mui/icons-material";
import { Box, Button, ButtonProps, Typography } from "@mui/material";
import { ElementType, ReactNode } from "react";
import { makeStyles } from "tss-react/mui";

interface EmptyStateProps<ButtonComponent extends ElementType> {
  icon: SvgIconComponent;
  title: string;
  description: ReactNode;
  buttonProps?: ButtonProps<ButtonComponent, { component?: ButtonComponent }> & {
    label: string;
  };
  isFullPage?: boolean;
}

export function EmptyState<ButtonComponent extends ElementType>({
  icon: Icon,
  title,
  description,
  buttonProps,
  isFullPage = true,
}: EmptyStateProps<ButtonComponent>) {
  const { classes } = useStyles({ isFullPage });
  return (
    <Box flex={1} className={classes.container} display="flex" alignItems="center" justifyContent="center">
      <Box display="flex" flexDirection="column" alignItems="center">
        <Icon className={classes.icon} />
        <Typography className={classes.title} align="center" variant="subtitle1">
          {title}
        </Typography>
        <Typography className={classes.description} align="center" color="textSecondary">
          {description}
        </Typography>
        {buttonProps && (
          <Button variant="contained" color="primary" {...buttonProps}>
            {buttonProps.label}
          </Button>
        )}
      </Box>
    </Box>
  );
}

const useStyles = makeStyles<Pick<EmptyStateProps<ElementType>, "isFullPage">>()((theme, { isFullPage }) => ({
  container: {
    // subtracting AppBar height and Layout padding
    height: isFullPage ? `calc(100vh - ${theme.spacing(8 + 2.5 * 2)})` : "100%",
    minHeight: "320px",
  },
  description: {
    "&.MuiTypography-body1": {
      marginBottom: theme.spacing(4),
      color: theme.palette.text.primary,
    },
  },
  icon: {
    "&.MuiSvgIcon-root": {
      color: theme.palette.grey[500],
      fontSize: theme.typography.pxToRem(160),
      marginBottom: theme.spacing(4),
    },
  },
  title: {
    "&.MuiTypography-subtitle1": {
      fontWeight: 600,
      color: theme.palette.text.primary,
      wordBreak: "break-word",
    },
  },
}));
