import { locale } from "../locale";

export function useDateTime() {
  return (date: string | Date, format: DateTimeFormat) => formatters[format].format(new Date(date));
}

type DateParts = Record<Intl.DateTimeFormatPartTypes, string>;

export enum DateTimeFormat {
  YYYYMMDDhmLocalTimeFormat = "YYYY/MM/DD localTimeFormat",
  timeDifferenceHHMMSS = "timeDifferenceHHMMSS",
}

type Formatter = {
  format: (date: Date) => string;
  options?: Intl.DateTimeFormatOptions;
};

// Options documentation: https://tc39.es/ecma402/#sec-datetimeformat-abstracts
const formatters: Record<DateTimeFormat, Formatter> = {
  [DateTimeFormat.YYYYMMDDhmLocalTimeFormat]: {
    format: date => {
      const formattedDateParts = Intl.DateTimeFormat(locale, {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      })
        .formatToParts(new Date(date))
        .reduce((result, { type, value }) => {
          // Removing / and other delimiters
          if (type !== "literal") {
            result[type] = value;
          }
          return result;
        }, {} as DateParts);

      const { year, month, day, hour, minute, dayPeriod } = formattedDateParts;

      return `${year}/${month}/${day} ${hour}:${minute} ${dayPeriod ?? ""}`;
    },
    options: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    },
  },
  [DateTimeFormat.timeDifferenceHHMMSS]: {
    format: date => {
      const timeDifference = date.getTime() - new Date().getTime();

      // 88:88:88
      return new Date(Math.max(timeDifference, 0)).toLocaleTimeString([], {
        timeZone: "UTC",
        hourCycle: "h23",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
    },
  },
};
