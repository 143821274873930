import axios, { AxiosInstance } from "axios";
import { ZodType, ZodTypeDef } from "zod";

import { ApiEntity } from "../api-entity";
import { parseMutationErrors } from "../errors/parse-mutation-errors";
import { ApiPresignedUrl } from "./types/api-presigned-url";

export function uploadOne<TItem, TApiItem, Def extends ZodTypeDef = ZodTypeDef>(
  restClient: AxiosInstance,
  apiEntity: ApiEntity,
  file: File,
  itemType: ZodType<TItem, Def, TApiItem>,
) {
  const payload = { fileNameWithExt: file.name };

  return restClient
    .post<unknown>(apiEntity, payload)
    .then(response => {
      const { url } = ApiPresignedUrl.parse(response.data);
      const result = itemType.parse(response.data);

      // Tokens should not be passed here because S3 doesn't accept them,
      // therefore use `axios` directly instead of `restClient`
      return axios.put(url, file).then(() => result);
    })
    .catch(parseMutationErrors<typeof payload, TItem>);
}
