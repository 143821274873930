import { alpha, Theme, ThemeOptions } from "@mui/material";

export const menuItemTheme = (theme: Theme): ThemeOptions["components"] => ({
  MuiMenuItem: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        padding: theme.spacing(1.25, 2),
        "& .MuiListItemIcon-root": {
          minWidth: 32,
          fontSize: theme.typography.pxToRem(16),
        },
        "& .MuiSvgIcon-root": {
          color: theme.palette.grey[600],
        },
        "&:hover, &.Mui-focusVisible:hover": {
          background: alpha(theme.palette.common.black, 0.05),
        },
        "&:active, &.Mui-focusVisible:active": {
          background: alpha(theme.palette.common.black, 0.1),
        },
        "&.Mui-focusVisible": {
          boxShadow: `inset 0 0 0 2px ${theme.palette.primary.main}`,
          backgroundColor: "transparent",
        },
      },
    },
  },
});
