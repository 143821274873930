import { Theme } from "@mui/material";

import { InputStatus } from "../form-input/form-input";

export const getBorderColor = ({
  theme,
  status = "primary",
}: {
  theme: Theme;
  status?: InputStatus | "primary";
}): string => {
  if (theme.palette.mode === "dark") {
    return status === "primary" ? theme.palette.grey[400] : theme.palette[status].main;
  }
  return status === "primary" ? theme.palette.grey[900] : theme.palette[status].main;
};
