import { ReactNode } from "react";

import { FormSection, FormSectionProps } from "../../../../../../../_common/ui/form-section/form-section";
import { sectionTestId } from "../../../utils/section-test-id";

type ConfigurationSectionUiProps = {
  name: string;
  descriptions: ReactNode;
} & FormSectionProps;

export function ConfigurationSectionUi({ first, name, title, descriptions, content }: ConfigurationSectionUiProps) {
  return (
    <FormSection
      data-testid={sectionTestId(name)}
      content={content}
      title={title}
      description={descriptions}
      first={first}
    />
  );
}
