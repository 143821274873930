import { AddCircle, Folder } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { SortOrder } from "../../../../../../../_common/api/methods/get-many-with-pagination";
import { addAction, AppShieldingActions, AppShieldingFeatures } from "../../../../../../../_common/datadog";
import { useTranslate } from "../../../../../../../_common/i18n/hooks/use-translate.hook";
import { ID } from "../../../../../../../_common/types";
import { EmptyState } from "../../../../../../../_common/ui/empty-state/empty-state";
import { DataTable, DataTableProps, useTableSort } from "../../../../../../../_common/ui/table/data-table";
import { Paginator, useCurrentPage } from "../../../../../../../_common/ui/table/paginator";
import { useGetPageCount } from "../../../../../../../_common/ui/table/paginator/use-get-page-count.hook";
import { GetProjectsListItem, useGetProjectsList } from "../../../api/get-projects-list-item";
import { DeleteProjectDialog } from "../../delete-project/delete-project-dialog";
import {
  PROJECTS_LIST_DEFAULT_SORT_COLUMN,
  PROJECTS_LIST_PAGE_PARAM_NAME,
  PROJECTS_LIST_SORT_COLUMN_PARAM_NAME,
  PROJECTS_LIST_SORT_ORDER_PARAM_NAME,
} from "../constants";
import { ProjectsListApiSortColumn } from "../types/projects-list-api-sort-column.enum";
import { ProjectsListUrlSortColumn } from "../types/projects-list-url-sort-column.enum";
import { CreatedAtCell } from "./cells/created-at-cell";
import { LastUpdatedCell } from "./cells/last-updated-cell";
import { NoConfigurationWarningCell } from "./cells/no-configuration-warning-cell";
import { ProjectCell } from "./cells/project-cell";
import { ProjectsListContextMenuCell } from "./cells/projects-list-context-menu-cell";
import { ShielderCell } from "./cells/shielder-cell";
import { ProjectsListContext } from "./projects-list-context.type";

const sortingParamNames = {
  sortColumnParamName: PROJECTS_LIST_SORT_COLUMN_PARAM_NAME,
  sortOrderParamName: PROJECTS_LIST_SORT_ORDER_PARAM_NAME,
};

export function ProjectsList() {
  const navigate = useNavigate();
  const t = useTranslate();
  const columns = useGenerateColumns();
  const sortingParams = useTableSort(columns, PROJECTS_LIST_DEFAULT_SORT_COLUMN);
  const page = useCurrentPage() || 1;

  const {
    isLoading,
    isError,
    data: { projects, totalCount } = {},
  } = useGetProjectsList({
    page,
    sortingParams,
    itemsPerPage: Paginator.DEFAULT_ROWS_COUNT_PER_PAGE,
  });
  const pageCount = useGetPageCount(isLoading, totalCount);

  const [selectedProject, setSelectedProject] = useState<{ id: ID; projectName: string }>();

  const context: ProjectsListContext = useMemo(
    () => ({
      selectProject: (id: ID) => {
        setSelectedProject(projects?.find(x => x.id === id));
      },
    }),
    [projects],
  );
  const onCloseModal = useCallback(() => setSelectedProject(undefined), []);

  if (totalCount === 0) {
    return (
      <EmptyState
        icon={Folder}
        title={t("component.project-list.empty-state.title")}
        description={t("component.project-list.empty-state.description")}
        buttonProps={{
          label: t("component.project-list.empty-state.button.label"),
          onClick: () => {
            addAction(AppShieldingActions.OpenCreateProject, AppShieldingFeatures.ProjectList);
            navigate("new");
          },
          startIcon: <AddCircle />,
        }}
      />
    );
  }

  return (
    <Box flex="1 0" display="flex" flexDirection="column" justifyContent="space-between">
      <Box flex={1} pb={pageCount === 1 ? 2.5 : 0}>
        <DataTable
          context={context}
          caption={t("component.project-list.table.caption")}
          onClickRow={item => {
            addAction(AppShieldingActions.ViewProjectDetails, AppShieldingFeatures.ProjectList);
            navigate(item.id);
          }}
          isLoading={(!projects && isLoading) || isError}
          columns={columns}
          defaultSortColumn={PROJECTS_LIST_DEFAULT_SORT_COLUMN}
          data={projects}
          sortingParamNames={sortingParamNames}
        />

        <DeleteProjectDialog
          projectId={selectedProject?.id}
          projectName={selectedProject?.projectName}
          onClose={onCloseModal}
        />
      </Box>
      {pageCount > 1 && <Paginator paramName={PROJECTS_LIST_PAGE_PARAM_NAME} total={pageCount} flexShrink="0" />}
    </Box>
  );
}

const useGenerateColumns = (): DataTableProps<
  ProjectsListApiSortColumn,
  ProjectsListUrlSortColumn,
  Readonly<GetProjectsListItem>,
  ProjectsListContext
>["columns"] => {
  const t = useTranslate();
  return useMemo(
    () => [
      {
        width: "100%",
        key: "project",
        title: t("component.project-list.column.project"),
        sorting: {
          urlParamName: ProjectsListUrlSortColumn.ProjectName,
          apiColumnName: ProjectsListApiSortColumn.ProjectName,
          defaultOrder: SortOrder.Asc,
        },
        component: ProjectCell,
      },
      {
        width: 210,
        key: "shield",
        title: t("component.project-list.column.shield-version"),
        component: ShielderCell,
      },
      {
        width: 200,
        key: "created-at",
        title: t("component.project-list.column.created"),
        sorting: {
          urlParamName: ProjectsListUrlSortColumn.CreatedAt,
          apiColumnName: ProjectsListApiSortColumn.CreatedAt,
          defaultOrder: SortOrder.Desc,
        },
        component: CreatedAtCell,
      },
      {
        width: 200,
        key: "last-activity",
        title: t("component.project-list.column.last-updated"),
        sorting: {
          urlParamName: ProjectsListUrlSortColumn.UpdatedAt,
          apiColumnName: ProjectsListApiSortColumn.LastActivity,
          defaultOrder: SortOrder.Desc,
        },
        component: LastUpdatedCell,
      },
      {
        width: 64,
        key: "no-configuration-warning",
        title: "",
        component: NoConfigurationWarningCell,
        ariaLabel: t("component.project-list.table.column-aria-label.no-configuration-warning"),
      },
      {
        width: 68,
        key: "menu",
        title: "",
        component: ProjectsListContextMenuCell,
        ariaLabel: t("component.project-list.table.column-aria-label.context"),
      },
    ],
    [t],
  );
};
