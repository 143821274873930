import { z } from "zod";

export const IdToken = z.object({
  // at_hash: z.string(),
  // aud: z.string(),
  // auth_time: z.number(),
  "cognito:groups": z.array(z.string()),
  "cognito:username": z.string(),
  "custom:accountid": z.string(),
  "custom:companyname": z.string().optional(),
  // "custom:planid": z.string(),
  // // "custom:salesforceaccid": z.string(),
  email: z.string(),
  // exp: z.number(),
  // iat: z.number(),
  // iss: z.string(),
  // jti: z.string(),
  name: z.string(),
  // origin_jti: z.string(),
  // sub: z.string(),
  // token_use: z.string(),
});
// type IdToken = z.infer<typeof IdToken>;
