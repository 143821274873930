import { Button, ButtonProps } from "@mui/material";
import { ReactNode } from "react";

import { ID } from "../../../../../../_common/types";
import { ButtonProgress } from "../../../../../../_common/ui/button-progress/button-progress";
import { useDownloadShielding } from "../../api/use-download-shielding";

interface DownloadShieldingButtonProps extends ButtonProps {
  className: string;
  fileName: string;
  startIcon?: ReactNode;
  shieldingId: ID;
}

export const DownloadShieldingButton = ({
  className,
  fileName,
  shieldingId,
  startIcon,
  onClick,
  ...props
}: DownloadShieldingButtonProps) => {
  const { mutateAsync: downloadShielding, isPending: isDownloading } = useDownloadShielding(shieldingId);

  const handleLinkClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onClick && onClick(event);
    !isDownloading && void downloadShielding({ shieldingId, fileName });
  };

  return (
    <Button
      color="primary"
      variant="text"
      className={className}
      startIcon={isDownloading ? <ButtonProgress role="progressbar" size={18} /> : startIcon}
      onClick={handleLinkClick}
      {...props}
    >
      {fileName}
    </Button>
  );
};
