import { Science as ScienceIcon } from "@mui/icons-material";
import { Box } from "@mui/material";
import { makeStyles } from "tss-react/mui";

interface DebugModeIconProps {
  debugMode: boolean;
  smallIcon?: boolean;
}
export const DebugModeIcon = ({ debugMode, smallIcon }: DebugModeIconProps) => {
  const { classes } = useStyles({ debugMode, smallIcon });
  return (
    <Box className={classes.box} data-testid="debug-mode-icon">
      <ScienceIcon />
    </Box>
  );
};

const useStyles = makeStyles<DebugModeIconProps>()((theme, { debugMode, smallIcon }) => ({
  box: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    width: smallIcon ? "30px" : "35px",
    height: smallIcon ? "30px" : "35px",
    backgroundColor: debugMode ? theme.palette.warning.light : theme.palette.grey[200],
    color: debugMode ? theme.palette.warning.main : theme.palette.grey[500],
  },
}));
