import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import { ReactNode, useCallback } from "react";
import { makeStyles } from "tss-react/mui";

import { useTranslate } from "../../i18n/hooks/use-translate.hook";
import { ButtonProgress } from "../button-progress/button-progress";
import { LAYOUT_ID } from "../constants";

type ConfirmButtonProps = Omit<ButtonProps, "onClick" | "color"> & {
  startIcon?: ReactNode;
};

export interface ConfirmationDialogProps<T> {
  reverseButtonOrder?: boolean;
  open: boolean;
  confirmButtonProps?: ConfirmButtonProps;
  confirmButtonLabel: string;
  cancelButtonLabel?: string;
  onConfirm?: () => T;
  onCancel: () => void;
  onExited?: () => void;
  submitting?: boolean;
  title: ReactNode;
  type?: undefined | "error";
  disableImplicitClose?: boolean;
  children: ReactNode;
}

export function ConfirmationDialog<T>({
  reverseButtonOrder,
  confirmButtonLabel,
  cancelButtonLabel,
  title,
  onCancel,
  onExited,
  children,
  onConfirm,
  confirmButtonProps,
  submitting,
  open,
  disableImplicitClose,
  type,
}: ConfirmationDialogProps<T>) {
  const { startIcon, ...restConfirmButtonProps } = confirmButtonProps || {};
  const t = useTranslate();
  const { classes } = useStyles({ reverseButtonOrder });

  const handleCloseDialog = (_: object, reason: "backdropClick" | "escapeKeyDown") =>
    !(disableImplicitClose && (reason === "backdropClick" || reason === "escapeKeyDown")) && onCancel();

  return (
    <Dialog
      open={open}
      onClose={!submitting ? handleCloseDialog : undefined}
      scroll={"paper"}
      aria-labelledby="dialog-title"
      disableEscapeKeyDown={disableImplicitClose}
      aria-describedby="dialog-description"
      container={useCallback(() => document.getElementById(LAYOUT_ID), [])}
      onTransitionExited={onExited}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Typography id="dialog-title" variant="subtitle1" component="span" fontWeight="bold">
          {title}
        </Typography>
        <IconButton disabled={submitting} onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <Divider />

      <DialogContent id="dialog-description" className={classes.dialogContent}>
        {children}
      </DialogContent>

      <DialogActions disableSpacing className={classes.dialogActions}>
        <Button variant="outlined" color="secondary" disabled={submitting} onClick={onCancel}>
          {cancelButtonLabel ? cancelButtonLabel : t("word.cancel")}
        </Button>

        <Button
          disabled={submitting}
          onClick={onConfirm ? () => onConfirm() : undefined}
          startIcon={submitting ? <ButtonProgress /> : startIcon}
          variant="contained"
          color={type || "primary"}
          {...restConfirmButtonProps}
        >
          {confirmButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles<{ reverseButtonOrder?: boolean }>()((theme, { reverseButtonOrder }) => ({
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2.25, 2.5),
  },
  dialogContent: {
    padding: theme.spacing(2.5),
  },
  dialogActions: {
    boxShadow: "inset 0px 1px 0px rgba(0, 0, 0, 0.1)",
    padding: theme.spacing(2.75, 2.5),
    gap: theme.spacing(1),
    justifyContent: reverseButtonOrder ? "flex-start" : undefined,
    flexDirection: reverseButtonOrder ? "row-reverse" : undefined,
  },
}));
