import { Box, Card, Typography } from "@mui/material";

import { DateTimeFormat, useDateTime } from "../../../../../../../_common/i18n/hooks/use-date-time.hook";
import { useTranslate } from "../../../../../../../_common/i18n/hooks/use-translate.hook";
import { CardHeader } from "../../../../../../../_common/ui/card/card-header";
import { CardSection } from "../../../../../../../_common/ui/card/card-section";
import { FixedWidthSkeleton } from "../../../../../../../_common/ui/skeleton/fixed-width-skeleton";
import { FullWidthSkeleton } from "../../../../../../../_common/ui/skeleton/full-width-skeleton";
import { GetProject } from "../../../api/get-project";
import { ShielderInfo } from "./shielder-info";

interface BasicInfoProps {
  project?: GetProject;
  isLoading?: boolean;
}

export const BasicInfo = ({ project, isLoading }: BasicInfoProps) => {
  const t = useTranslate();

  const dateFormat = useDateTime();
  return (
    <Card component="section" aria-label={t("component.basic-info.title")}>
      <CardHeader
        title={
          isLoading ? (
            <FixedWidthSkeleton data-testid="basic-info-title-skeleton-loader" />
          ) : (
            <Typography component="h2" fontSize="1rem" fontWeight="500">
              {t("component.basic-info.title")}
            </Typography>
          )
        }
      />

      <CardSection
        title={
          isLoading ? (
            <FixedWidthSkeleton data-testid="basic-info-description-title-skeleton-loader" />
          ) : (
            t("word.description")
          )
        }
        description={
          isLoading ? (
            <FullWidthSkeleton data-testid="basic-info-description-content-skeleton-loader" />
          ) : (
            project?.projectDescription
          )
        }
        borderBottom
      />

      <Box display="flex">
        <CardSection
          title={
            isLoading ? (
              <FixedWidthSkeleton data-testid="basic-info-created-title-skeleton-loader" />
            ) : (
              t("word.created")
            )
          }
          description={
            isLoading ? (
              <FullWidthSkeleton data-testid="basic-info-created-date-skeleton-loader" />
            ) : (
              project && dateFormat(project.projectCreatedAt, DateTimeFormat.YYYYMMDDhmLocalTimeFormat)
            )
          }
          borderRight
        />
        <CardSection
          title={
            isLoading ? (
              <FixedWidthSkeleton data-testid="basic-info-updated-title-skeleton-loader" />
            ) : (
              t("word.updated")
            )
          }
          description={
            isLoading ? (
              <FullWidthSkeleton data-testid="basic-info-updated-date-skeleton-loader" />
            ) : (
              project && dateFormat(project.projectUpdatedAt, DateTimeFormat.YYYYMMDDhmLocalTimeFormat)
            )
          }
        />
      </Box>
      <ShielderInfo project={project} isLoading={isLoading} />
    </Card>
  );
};
