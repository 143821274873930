import { Navigate, Route, Routes } from "react-router-dom";

import { useTranslate } from "../../_common/i18n/hooks/use-translate.hook";
import { NotFound } from "../../_common/ui/not-found/not-found";
import { appShieldingRootPath } from "./app-shielding-root-path";
import { ChangeShieldVersionPanel } from "./features/change-shield/change-shield-version-panel";
import { ConfigurationPage } from "./features/configurations/configuration-page";
import { PageType } from "./features/configurations/page-type";
import { DuplicateProjectPanel } from "./features/projects/features/duplicate-project/duplicate-project-panel";
import { EditProjectSummaryPanel } from "./features/projects/features/edit-project-summary/edit-project-summary-panel";
import { NewProjectPanel } from "./features/projects/features/new-project/new-project-panel";
import { ProjectDetailsPage } from "./features/projects/features/project-details/project-details-page";
import { ProjectsListPage } from "./features/projects/features/projects-list/projects-list-page";
import { ShieldingDetailsPage } from "./features/shieldings/features/shielding-details/shielding-details-page";

export const AppShieldingRouter = () => {
  const t = useTranslate();
  const notFound = (
    <NotFound
      rootPath={`/${appShieldingRootPath}`}
      rootButtonLabel={t(`component.empty-state.not-found.button.${appShieldingRootPath}`)}
    />
  );

  return (
    <Routes>
      <Route path="/" element={<Navigate to="projects" />} />
      <Route path="projects">
        <Route path="" element={<ProjectsListPage />}>
          <Route path="new" element={<NewProjectPanel />} />
          <Route path="edit" element={<EditProjectSummaryPanel />} />
          <Route path="duplicate" element={<DuplicateProjectPanel />} />
        </Route>
        <Route path=":projectId">
          <Route path="" element={<ProjectDetailsPage />}>
            <Route path="edit" element={<EditProjectSummaryPanel />} />
            <Route path="duplicate" element={<DuplicateProjectPanel />} />
            <Route path="select-version" element={<ChangeShieldVersionPanel />} />
          </Route>
          <Route path="configuration">
            <Route path="" element={<ConfigurationPage type={PageType.Show} />}>
              <Route path="select-version" element={<ChangeShieldVersionPanel />} />
            </Route>
            <Route path="new" element={<ConfigurationPage type={PageType.New} />} />
            <Route path="edit" element={<ConfigurationPage type={PageType.Edit} />} />
          </Route>
          <Route path="change-version" element={<ConfigurationPage type={PageType.Edit} changeVersion />} />
          <Route path="shieldings">
            <Route path=":shieldingId" element={<ShieldingDetailsPage />} />
          </Route>
        </Route>
      </Route>
      <Route path="not-found" element={notFound} />
      <Route path="*" element={notFound} />
    </Routes>
  );
};
