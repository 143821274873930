import { alpha, ListItem, ListItemButton, ListItemButtonProps, ListItemIcon, ListItemText } from "@mui/material";
import { ReactElement } from "react";
import { Link, useLocation } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { sideBarTransition, TransitionType } from "../theme/transitions";

type MenuItemProps = {
  buttonProps?: ListItemButtonProps & { component?: typeof Link; to?: string };
  icon: ReactElement;
  label: string;
  subLabel?: string;
  open: boolean;
};
export const MenuItem = ({ buttonProps, icon, label, subLabel, open }: MenuItemProps) => {
  const { classes } = useStyles({ open });
  const { pathname } = useLocation();

  const isSelected = buttonProps?.to ? new RegExp(`^${buttonProps.to}(/.*)?$`).test(pathname) : false;

  return (
    <ListItem disablePadding>
      <ListItemButton
        selected={isSelected}
        classes={{ root: classes.listItemButton, selected: classes.listItemButtonSelected }}
        title={label}
        {...buttonProps}
      >
        <ListItemIcon classes={{ root: classes.listItemIcon }}>{icon}</ListItemIcon>
        <ListItemText
          primary={label}
          secondary={subLabel}
          classes={{ primary: classes.listItemTextPrimary, secondary: classes.listItemTextSecondary }}
        />
      </ListItemButton>
    </ListItem>
  );
};

const useStyles = makeStyles<{ open: boolean }>()((theme, { open }) => ({
  listItemButton: {
    height: theme.spacing(6.5),
    padding: theme.spacing(1.25, 2),
    boxSizing: "border-box",
  },
  listItemButtonSelected: {
    paddingLeft: theme.spacing(1.75),
    "&:focus": {
      paddingLeft: theme.spacing(2),
      "&:not(:focus-visible)": {
        paddingLeft: theme.spacing(1.75),
      },
    },
  },
  listItemIcon: {
    display: "flex",
    width: 31,
    minWidth: 31,
    justifyContent: "center",
    alignItems: "center",
    marginRight: theme.spacing(2),
    color: alpha(theme.palette.grey[800], 0.79),
  },
  listItemTextPrimary: {
    fontWeight: 600,
    color: theme.palette.grey[800],
    lineHeight: theme.typography.pxToRem(18),
    ...theme.mixins.lineClamp(1),
    ...(open
      ? {
          opacity: 1,
          transition: sideBarTransition(theme, TransitionType.Open, "opacity"),
        }
      : {
          opacity: 0,
          transition: sideBarTransition(theme, TransitionType.Close, "opacity"),
        }),
  },
  listItemTextSecondary: {
    fontWeight: 400,
    color: theme.palette.grey[800],
    lineHeight: theme.typography.pxToRem(18),
    ...theme.mixins.lineClamp(1),
  },
}));
