import { Card } from "@mui/material";

import { CardSection } from "../../../../../../../_common/ui/card/card-section";
import { FullWidthSkeleton } from "../../../../../../../_common/ui/skeleton/full-width-skeleton";
import { Platform } from "../../../../shielders/types/platform";
import { ShielderStatus } from "../../../../shielders/types/shielder-status";
import { Version } from "../../../../shielders/types/version";
import { ShielderLabel } from "../../../../shielders/ui/shielder-label";

interface ConfigurationShielderSectionContentProps {
  isLoading: boolean;
  projectName: string | undefined;
  shielder:
    | {
        shielderPlatform: Platform;
        shielderVersion: Version;
        shielderStatus: ShielderStatus;
      }
    | undefined;
}

export const ConfigurationShielderSectionContent = ({
  isLoading,
  projectName,
  shielder,
}: ConfigurationShielderSectionContentProps) => (
  <Card>
    <CardSection
      title="Name"
      description={projectName && !isLoading ? projectName : <FullWidthSkeleton />}
      borderBottom
    />
    <CardSection
      title="Shield"
      description={
        shielder && !isLoading ? (
          <ShielderLabel
            platform={shielder.shielderPlatform}
            version={shielder.shielderVersion}
            status={shielder.shielderStatus}
          />
        ) : (
          <FullWidthSkeleton />
        )
      }
    />
  </Card>
);
