import { useEffect } from "react";

import { useRerender } from "./use-rerender";

export function useRerenderWithInterval({
  refreshInterval,
  enabled = true,
}: {
  refreshInterval: number;
  enabled?: boolean;
}) {
  const rerender = useRerender();

  useEffect(() => {
    const interval = setInterval(() => {
      rerender();
    }, refreshInterval);

    if (!enabled) {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [refreshInterval, enabled, rerender]);
}
