import { Roles } from "./roles";

export const GROUP_ONLINE_USER = "online_user";
export const GROUP_OFFLINE_USER = "offline_user";

export const groupsToRoles = (groups: string[]): Roles => {
  const isGroupOnline = groups.includes(GROUP_ONLINE_USER);
  const isGroupOffline = groups.includes(GROUP_OFFLINE_USER);

  return {
    hasAppShielding: isGroupOnline,
    hasOnPremShielders: isGroupOffline,
    hasSettings: isGroupOnline,
  };
};
