import { ZodError } from "zod";

import { ParsedTokens } from "../parsed-tokens";
import { TOKENS_KEY } from "./tokens-key";

export const getTokens = (storage?: string) => {
  const tokensRecord = storage || localStorage.getItem(TOKENS_KEY);

  if (tokensRecord) {
    try {
      return ParsedTokens.parse(JSON.parse(tokensRecord));
    } catch (e) {
      if (!(e instanceof SyntaxError) && !(e instanceof ZodError)) throw e;
    }
  }
  return null;
};
