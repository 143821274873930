import { z } from "zod";

import { ID } from "../../../../../_common/types";
import { EditProjectSummaryFormSchema } from "./edit-project-summary-form-schema";

export const CreateProjectFormSchema = EditProjectSummaryFormSchema.extend({
  shielderId: ID,
});

export type CreateProjectFormSchema = z.infer<typeof CreateProjectFormSchema>;
