import { Box } from "@mui/material";

import { useTranslate } from "../../_common/i18n/hooks/use-translate.hook";
import { Layout } from "../../_common/ui/layout/layout";
import { OnPremShieldersList } from "./components/on-prem-shielders-list";

export const OnPremShieldersPage = () => {
  const t = useTranslate();

  return (
    <Layout title={t("component.on-prem-shielders.layout.content.title")}>
      <Box display="flex" flexDirection="column" width="100%">
        <OnPremShieldersList />
      </Box>
    </Layout>
  );
};
