import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { ApiEntity } from "../../../../../../_common/api/api-entity";
import { getOne } from "../../../../../../_common/api/methods/get-one";
import { queryKeys } from "../../../../../../_common/api/query-keys/query-keys";
import { useRestClient } from "../../../../../../_common/api/rest-client/rest-client-context";
import { ID } from "../../../../../../_common/types";
import { appShieldingRootPath } from "../../../../app-shielding-root-path";
import { AccessApiKeyI18nToastKey } from "../_support/access-api-key-i18n-toast-key";

export const ApiGetAccessApiKey = z.object({
  apiKeyId: z.number(),
  apiKey: z.string(),
});
export type ApiGetAccessApiKey = z.input<typeof ApiGetAccessApiKey>;

// eslint-disable-next-line import/no-unused-modules
export const GetAccessApiKey = ApiGetAccessApiKey;
// eslint-disable-next-line import/no-unused-modules
export type GetAccessApiKey = z.infer<typeof GetAccessApiKey>;

interface UseGetAccessApiKey {
  projectId: ID | undefined;
  apiKeyId: ID | undefined;
  enabled: boolean;
}

export const useGetAccessApiKey = ({ projectId, apiKeyId, enabled }: UseGetAccessApiKey) => {
  const restClient = useRestClient();

  return useQuery({
    queryKey: queryKeys[appShieldingRootPath].projects().item(projectId).accessApiKey().item(apiKeyId),
    queryFn: () => getOne(restClient, ApiEntity.AccessApiKey, apiKeyId, GetAccessApiKey),
    enabled: enabled,
    meta: { i18nToastKey: AccessApiKeyI18nToastKey.GET_API_KEY, skipNavigationOnNotFound: true },
  });
};
