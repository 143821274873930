import { Box, Button, ButtonProps } from "@mui/material";
import { MouseEvent, useCallback } from "react";

export interface AlertButtonBarProps {
  primaryButton?: ButtonProps & { label: string };
  secondaryButton?: ButtonProps & { label: string };
  type?: "success" | "warning" | "error" | "info";
  onClose?: () => void;
  onCollapse?: () => void;
}

export const AlertButtonBar = ({ primaryButton, secondaryButton, type, onClose }: AlertButtonBarProps) => {
  const textButtonHandler = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      secondaryButton?.onClick && secondaryButton.onClick(e);
      onClose && onClose();
    },
    [onClose, secondaryButton],
  );

  const outlineButtonHandler = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      primaryButton?.onClick && primaryButton.onClick(e);
      onClose && onClose();
    },
    [onClose, primaryButton],
  );

  if (!primaryButton && !secondaryButton) return null;

  return (
    <Box display="flex" marginTop={2} gap={1}>
      {primaryButton && (
        <Button color={type} variant="outlined" size="small" {...primaryButton} onClick={outlineButtonHandler}>
          {primaryButton.label}
        </Button>
      )}
      {secondaryButton && (
        <Button color={type} size="small" {...secondaryButton} onClick={textButtonHandler}>
          {secondaryButton.label}
        </Button>
      )}
    </Box>
  );
};
