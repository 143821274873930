import { ToastContainer as ToastifyToastContainer } from "react-toastify";
import { makeStyles } from "tss-react/mui";

import { CloseButton } from "./close-button";

export const ToastContainer = () => {
  const { classes } = useStyles();
  return (
    <ToastifyToastContainer
      className={classes.root}
      closeButton={<CloseButton />}
      hideProgressBar
      icon={false}
      position="top-right"
      role="presentation"
      theme="dark"
      closeOnClick
    />
  );
};

const useStyles = makeStyles()(theme => {
  return {
    root: {
      "&.Toastify__toast-container--top-right": {
        top: theme.spacing(9.25),
        right: theme.spacing(0.75),
      },
      ".Toastify__toast": {
        borderRadius: theme.shape.borderRadius,
      },
      ".Toastify__toast-theme--dark.Toastify__toast--default, .Toastify__toast-theme--dark.Toastify__toast--success": {
        background: theme.palette.grey[800],
      },
      ".Toastify__toast-theme--dark.Toastify__toast--error": {
        background: theme.palette.error.main,
      },
    },
  };
});
