import { z } from "zod";

import { IS_DEV } from "../../env-vars";
import type { StoredTokens } from "../storages/stored-tokens";
import { IdToken } from "./id-token";
import { strToTokenData } from "./str-to-token-data";

export const Tokens = z
  .object({
    id_token: z.string(),
    access_token: z.string(),
    expires_in: z.number(),
    token_type: z.string(),
  })
  .transform<StoredTokens>(data => {
    const idTokenData = strToTokenData(data.id_token);

    /* eslint-disable no-console */
    if (IS_DEV) {
      console.log("Access token", strToTokenData(data.access_token));
      console.log("ID token", idTokenData);
    }
    /* eslint-enable no-console */

    IdToken.parse(idTokenData);

    return {
      accessToken: data.access_token,
      idToken: data.id_token,
      expiresAt: Date.now() + data.expires_in * 1000,
    };
  });
