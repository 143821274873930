import {
  CheckCircle as CheckCircleIcon,
  ErrorRounded as ErrorRoundedIcon,
  WarningRounded as WarningRoundedIcon,
} from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { ReactNode } from "react";
import { makeStyles } from "tss-react/mui";

import { useId } from "../../../../../_common/hooks/use-id.hook";
import { useTranslate } from "../../../../../_common/i18n/hooks/use-translate.hook";
import { InputStatus } from "../../../../../_common/ui/form-input/form-input";
import { StyledScreenReadersOnly } from "../../../../../_common/ui/screen-readers-only";
import { ShielderStatus } from "../types/shielder-status";

const statusIcon: Record<ShielderStatus, ReactNode> = {
  [ShielderStatus.Latest]: <CheckCircleIcon fontSize="small" />,
  [ShielderStatus.Outdated]: <WarningRoundedIcon fontSize="small" />,
  [ShielderStatus.Unsupported]: <ErrorRoundedIcon fontSize="small" />,
  [ShielderStatus.Deleted]: <ErrorRoundedIcon fontSize="small" />,
};
const statusColorMap: Record<ShielderStatus, InputStatus> = {
  [ShielderStatus.Latest]: "success",
  [ShielderStatus.Outdated]: "warning",
  [ShielderStatus.Unsupported]: "error",
  [ShielderStatus.Deleted]: "error",
};

export interface ShielderStatusLabelProps {
  status: ShielderStatus;
}
export function ShielderStatusLabel({ status }: ShielderStatusLabelProps) {
  const t = useTranslate();
  const id = useId();
  const { classes } = useStyles({ status: statusColorMap[status] });

  return (
    <Box data-testid="shielder-status" className={classes.root} aria-labelledby={id}>
      {status !== ShielderStatus.Latest && (
        <StyledScreenReadersOnly id={id}>{t(`enum.shielder-status.aria-label.${status}`)}</StyledScreenReadersOnly>
      )}
      {statusIcon[status]}
      <Typography aria-hidden={true}>{t(`enum.shielder-status.${status}`)}</Typography>
    </Box>
  );
}

const useStyles = makeStyles<{ status: InputStatus }>()((theme, { status }) => ({
  root: {
    display: "flex",
    alignItems: "center",
    color: theme.palette[status].main,
    "& p": {
      marginLeft: theme.spacing(0.5),
      fontSize: theme.typography.pxToRem(14),
    },
  },
}));
