import { Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export function MainDropzoneTypography({ children }: { children: React.ReactNode }) {
  const { classes } = useStyles();

  return <Typography className={classes.dropzoneMessage}>{children}</Typography>;
}

const useStyles = makeStyles()(theme => {
  return {
    dropzoneMessage: {
      fontSize: theme.typography.pxToRem(12),
      fontWeight: 400,
      lineHeight: theme.typography.pxToRem(20),
    },
  };
});
