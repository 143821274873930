import { useTranslate } from "../../../../../_common/i18n/hooks/use-translate.hook";
import { FormSection } from "../../../../../_common/ui/form-section/form-section";
import { GetShielder } from "../../shielders/api/get-shielder";
import { ValidationDependencies } from "../configuration-schema/generators/get-validation-dependencies";
import { DebugModeWarnings } from "./ui/debug-mode-warnings";
import { ConfigurationSection } from "./ui/section/configuration-section";
import { ConfigurationShielderSectionContent } from "./ui/section/configuration-shielder-section-content";

interface ConfigurationFormProps {
  debuggable: boolean | undefined;
  newItems: string[] | undefined;
  isEditMode: boolean;
  isLoadingMetadata: boolean;
  isLoadingConfiguration: boolean;
  isUpdating: boolean;
  projectName: string | undefined;
  newShielder: GetShielder | undefined;
  validationDependencies: ValidationDependencies | undefined;
}
export function ConfigurationFormContent({
  debuggable,
  newItems,
  isEditMode,
  isLoadingMetadata,
  isLoadingConfiguration,
  isUpdating,
  projectName,
  newShielder,
  validationDependencies,
}: ConfigurationFormProps) {
  const t = useTranslate();
  return (
    <>
      {!isLoadingMetadata && !isLoadingConfiguration && (
        <DebugModeWarnings isEditMode={isEditMode} debuggable={debuggable} />
      )}
      <FormSection
        first
        title={t("component.base-configuration-form.section.project-title")}
        content={
          <ConfigurationShielderSectionContent
            isLoading={isLoadingMetadata}
            projectName={projectName}
            shielder={newShielder}
          />
        }
      />
      {!isLoadingMetadata &&
        newShielder?.shielderMetadata?.map(section => (
          <ConfigurationSection
            key={section.name}
            isEditMode={isEditMode}
            isLoading={isLoadingConfiguration}
            isUpdating={isUpdating}
            newItems={newItems}
            validationDependencies={validationDependencies!}
            {...section}
          />
        ))}
    </>
  );
}
