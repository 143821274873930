import "./css/styles.css";

import React from "react";
import ReactDOM from "react-dom/client";

import { version } from "../package.json";
import { App } from "./app/app";

// eslint-disable-next-line no-console
console.log("App Shielding version:", version);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
