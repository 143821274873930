import { useMutation, useQueryClient } from "@tanstack/react-query";

import { ApiEntity } from "../../../../../../_common/api/api-entity";
import { MutationError } from "../../../../../../_common/api/errors/mutation-error";
import { putOne } from "../../../../../../_common/api/methods/put-one";
import { queryKeys } from "../../../../../../_common/api/query-keys/query-keys";
import { useRestClient } from "../../../../../../_common/api/rest-client/rest-client-context";
import { ID } from "../../../../../../_common/types";
import { appShieldingRootPath } from "../../../../app-shielding-root-path";
import { ConfigurationI18nToastKey } from "../_support/configuration-i18n-toast-key";
import { GetConfiguration } from "../get-configuration";
import { ConfigValues, GeneralConfigValues } from "../types/config-values";
import { ReplaceConfiguration, replaceConfiguration } from "./replace-configuration";
import { ReplaceConfigurationInput, replaceConfigurationInput } from "./replace-configuration-input";

export type ReplaceConfigurationMutateAsync = ReturnType<typeof useReplaceConfiguration>["mutateAsync"];

interface UseReplaceConfigurationParams {
  projectId?: ID;
  configId?: ID;
  projectName?: string;
}
export function useReplaceConfiguration(
  { projectId, configId, projectName }: UseReplaceConfigurationParams,
  toastKey: ConfigurationI18nToastKey,
  configurationResponseSchema: GeneralConfigValues | undefined,
  isShielderChanged: boolean,
  oldConfigValues: ConfigValues | undefined,
  certificateFields: string[] | undefined,
) {
  const queryClient = useQueryClient();
  const restClient = useRestClient();

  return useMutation<ReplaceConfiguration, MutationError<ReplaceConfigurationInput>, ReplaceConfigurationInput>({
    mutationKey: queryKeys[appShieldingRootPath].projects().item(projectId).configuration(),
    mutationFn: data =>
      putOne(
        restClient,
        ApiEntity.Configuration,
        configId!,
        replaceConfigurationInput(certificateFields!).parse({ ...data, isShielderChanged, oldConfigValues }),
        replaceConfiguration(configurationResponseSchema!),
      ),
    onSuccess: data => {
      queryClient.setQueryData<GetConfiguration>(
        queryKeys[appShieldingRootPath].projects().item(projectId).configuration(),
        data,
      );
      void queryClient.invalidateQueries({
        queryKey: queryKeys[appShieldingRootPath].projects().item(projectId).details(),
      });
      void queryClient.invalidateQueries({ queryKey: queryKeys[appShieldingRootPath].projects().lists() });
    },
    meta: {
      i18nToastKey: toastKey,
      resourceName: projectName,
    },
  });
}
