import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider as ReactQueryClientProvider,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { ReactNode, useState } from "react";

import { globalRouter } from "../../app/global-router";
import { AuthActionsContext, useAuthActions } from "../auth/auth-provider/auth-actions-context";
import { API_RETRY } from "../env-vars";
import { logError } from "../error-logging/log-error";
import { i18n } from "../i18n/i18n";
import { ApiCallType } from "../i18n/utils/get-api-call-toast-i18n-key";
import { NotFound } from "../ui/not-found/not-found";
import { showToast } from "../ui/toast";
import { searchParams } from "../utils/search-params";
import { ErrorToast, errorToast } from "./error-toast";
import { getErrorI18nKeys } from "./errors/get-error-i18n-keys";
import { ValidationErrors } from "./errors/validation-errors";
import { getSuccessTranslationMessage } from "./get-success-translation-key";
import { QueryKey } from "./query-keys/query-key.type";
import { considerQueryKey404As500, invalidationKeysOn404 } from "./query-keys/query-keys";
import { I18nToastKey, Meta } from "./types";

const DEFAULT_STALE_TIME = 5 * 60 * 1000;

const shouldRetry = (failureCount: number, error: unknown) =>
  failureCount < 3 && error instanceof AxiosError && (!error.response || error.response.status >= 500);

const isUnauthorizedError = (error: unknown) => error instanceof AxiosError && error?.response?.status === 401;
const isNotFoundError = (error: unknown) => error instanceof AxiosError && error?.response?.status === 404;

interface QueryClientProviderProps {
  children: ReactNode;
}
export const QueryClientProvider = ({ children }: QueryClientProviderProps) => {
  const authActions = useAuthActions();

  const [queryClient] = useState(() => generateQueryClient(authActions, errorToast));

  return <ReactQueryClientProvider client={queryClient}>{children}</ReactQueryClientProvider>;
};

const generateQueryClient = (
  { authenticationConfirmed, authenticationRejected }: AuthActionsContext,
  errorToast: ErrorToast,
) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: DEFAULT_STALE_TIME,
        retry: API_RETRY && shouldRetry,
      },
    },
    queryCache: new QueryCache({
      onSuccess: authenticationConfirmed,
      onError(error, query) {
        if (
          errorHandler(ApiCallType.Query, error, query.queryKey as QueryKey | undefined, query.meta, () =>
            query.fetch(),
          ).shouldLogError
        ) {
          logError(error, "Error message:", error.message, "Query Key:", query.queryKey, "Query Meta:", query.meta);
        }
      },
    }),
    mutationCache: new MutationCache({
      onError(error, variables, _, mutation) {
        if (error instanceof ValidationErrors) {
          authenticationConfirmed();
          return;
        }

        if (
          errorHandler(ApiCallType.Mutation, error, mutation.options.mutationKey as QueryKey | undefined, mutation.meta)
            .shouldLogError
        ) {
          logError(
            error,
            "Error message:",
            error.message,
            "Mutation variables:",
            variables,
            "Mutation ID:",
            mutation.mutationId,
            "Mutation Meta:",
            mutation.meta,
            "Mutation Key:",
            mutation.options.mutationKey,
          );
        }
      },
      onSuccess(_error, _variables, _context, mutation) {
        authenticationConfirmed();
        const message = getSuccessTranslationMessage(mutation.meta?.i18nToastKey as I18nToastKey | undefined);
        message && showToast("success", message);
      },
    }),
  });

  return queryClient;

  function errorHandler(
    apiCallType: ApiCallType,
    error: unknown,
    queryKey: readonly string[] | undefined,
    meta: Meta | undefined,
    fetch?: () => Promise<unknown>,
  ) {
    let shouldLogError = false;

    if (isUnauthorizedError(error)) {
      authenticationRejected();
    } else if (isNotFoundError(error) && (!queryKey || !considerQueryKey404As500(queryKey))) {
      authenticationConfirmed();
      if (queryKey) {
        const { invalidateKey, removeKey } = invalidationKeysOn404(queryKey);

        if (invalidateKey) void queryClient.invalidateQueries({ queryKey: invalidateKey });
        if (removeKey) void queryClient.invalidateQueries({ queryKey: removeKey, refetchType: "none" });
      }

      if (!meta?.skipNavigationOnNotFound) {
        const params = meta?.resourceName ? searchParams({ [NotFound.resourceName]: meta.resourceName }) : "";
        void globalRouter.navigate(`${queryKey ? "/" + queryKey[0] : ""}/not-found${params}`, { replace: true });
      }
    } else {
      // Show only unique messages
      new Set(
        getErrorI18nKeys(apiCallType, meta?.i18nToastKey, error).map(i18nKey =>
          i18n.intl.formatMessage({ id: i18nKey }),
        ),
      ).forEach(m => errorToast.show(m, fetch, m));

      shouldLogError = true;
    }
    return { shouldLogError };
  }
};
