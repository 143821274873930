import { AxiosInstance } from "axios";

import { ID } from "../../types";
import { ApiEntity } from "../api-entity";
import { parseMutationErrors } from "../errors/parse-mutation-errors";

// consider 404 error code as successful delete
const validateStatus = (status: number) => [204, 404].includes(status);

export function deleteOne(restClient: AxiosInstance, apiEntity: ApiEntity, entityId?: ID) {
  return restClient
    .delete<unknown>(`${apiEntity}${entityId ? "/" + entityId : ""}`, { validateStatus })
    .catch(parseMutationErrors)
    .then(() => {}); // To explicitly state that there is no data returned
}
