import { Button, ButtonProps } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { Status } from "../status-icon/status-icon";

interface ToastButtonProps extends Omit<ButtonProps, "size" | "variant" | "onClick"> {
  toastType: Status;
  onClick: () => Promise<unknown>;
}

export const ToastButton = ({ children, className, toastType, ...buttonProps }: ToastButtonProps) => {
  const { classes, cx } = useStyles({ toastType });

  return (
    <Button {...buttonProps} className={cx(classes.button, className)} size="small" variant="text">
      {children}
    </Button>
  );
};

const useStyles = makeStyles<{ toastType: Status }>()((theme, { toastType }) => ({
  button: {
    backgroundColor: theme.palette[toastType].main,
    borderColor: theme.palette[toastType].contrastText,
    color: theme.palette[toastType].contrastText,
    fontWeight: 500,
    "&:hover": {
      borderColor: theme.palette[toastType].contrastText,
    },
  },
}));
