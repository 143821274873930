import { useMutation, useQueryClient } from "@tanstack/react-query";

import { ApiEntity } from "../../../../../../_common/api/api-entity";
import { MutationError } from "../../../../../../_common/api/errors/mutation-error";
import { CustomAction } from "../../../../../../_common/api/methods/custom-action";
import { postOne } from "../../../../../../_common/api/methods/post-one";
import { uploadOne } from "../../../../../../_common/api/methods/upload-one";
import { queryKeys } from "../../../../../../_common/api/query-keys/query-keys";
import { useRestClient } from "../../../../../../_common/api/rest-client/rest-client-context";
import { ID } from "../../../../../../_common/types";
import { appShieldingRootPath } from "../../../../app-shielding-root-path";
import { Platform } from "../../../shielders/types/platform";
import { Version } from "../../../shielders/types/version";
import { CreateShielding } from "./create-shielding";

interface CreateShieldingInput {
  projectId: ID;
  configId: ID;
  shielderId: ID;
  shielderVersion: Version;
  shielderPlatform: Platform;
  file: File;
}
function startShieldingInputTransformer(input: CreateShieldingInput) {
  const { file, ...startShieldingInput } = input;
  return { ...startShieldingInput, fileNameWithExt: file.name };
}

export function useCreateShielding(projectId: ID | undefined) {
  const queryClient = useQueryClient();
  const restClient = useRestClient();

  return useMutation<CreateShielding, MutationError<CreateShieldingInput>, CreateShieldingInput>({
    mutationKey: queryKeys[appShieldingRootPath].projects().item(projectId).shieldings().create(),
    mutationFn: variables =>
      uploadOne(restClient, ApiEntity.Shielding, variables.file, CreateShielding).then(({ id }) =>
        postOne(
          restClient,
          ApiEntity.Shielding,
          startShieldingInputTransformer(variables),
          CreateShielding,
          CustomAction.Start,
          id,
        ),
      ),
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: queryKeys[appShieldingRootPath].projects().item(projectId).shieldings().lists(),
      });
    },
  });
}
