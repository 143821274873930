import { Box } from "@mui/material";

import { ShielderStatusLabel, ShielderStatusLabelProps } from "./shielder-status-label";
import { ShielderVersionLabel, ShielderVersionLabelProps } from "./shielder-version-label";

interface ShielderLabelProps extends ShielderVersionLabelProps, ShielderStatusLabelProps {
  multiline?: boolean;
}

export const ShielderLabel = ({ platform, version, hidePlatformName, status, multiline }: ShielderLabelProps) => {
  return (
    <Box display="flex" flexDirection={multiline ? "column" : "row"}>
      <ShielderVersionLabel platform={platform} version={version} hidePlatformName={hidePlatformName} />
      <ShielderStatusLabel status={status} />
    </Box>
  );
};
