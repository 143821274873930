import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Outlet } from "react-router-dom";

import { CONTAINER_ID } from "../constants";
import { ToastContainer } from "../toast";

export const GlobalLayout = () => (
  <>
    <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-right" />
    <ToastContainer />
    <div id={CONTAINER_ID} />
    <Outlet />
  </>
);
