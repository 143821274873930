import { ReactNode, useEffect, useRef, useState } from "react";

import { ParsedTokens } from "../storages/parsed-tokens";
import { getTokens } from "../storages/token-storage/get-token";
import { removeTokens } from "../storages/token-storage/remove-tokens";
import { noUserProfile } from "../user-profile/no-user-profile";
import { UserProfile } from "../user-profile/user-profile";
import { AuthActionsContext } from "./auth-actions-context";
import { AuthState } from "./auth-state";
import { AuthStateContext } from "./auth-state-context";
import { AuthUserProfileContext } from "./auth-user-profile-context";

interface AuthProviderProps {
  children: ReactNode;
}

const getUserProfile = (tokens: ParsedTokens) => ({
  id: tokens.id,
  accountId: tokens.accountId,
  name: tokens.name,
  email: tokens.email,
  company: tokens.company,
  roles: tokens.roles,
});

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const wasAuthenticatedRequestRef = useRef(false);
  const [authData, setAuthData] = useState<{ state: AuthState; userProfile: UserProfile }>(() => {
    const tokens = getTokens();
    return tokens && tokens.expiresAt > Date.now()
      ? {
          state: AuthState.AuthenticatedNotConfirmed,
          userProfile: getUserProfile(tokens),
        }
      : {
          state: AuthState.RedirectingToLoginPage,
          userProfile: noUserProfile,
        };
  });

  // We use state to get all stable actions at once
  // We cannot use `useMemo` because React doesn't guarantee that memoized object
  // will not be recreated for performance reasons: https://react.dev/reference/react/useMemo#caveats
  // We don't use `useRef` because its initial value will be recalculated on each rerender
  // We could use `useCallback` for each of them, and then useMemo for the final object
  // but one `useState` requires less code and should be more performant
  const [actions] = useState<AuthActionsContext>(() => ({
    login: () =>
      setAuthData({
        state: AuthState.RedirectingToLoginPage,
        userProfile: noUserProfile,
      }),
    logout: () => {
      removeTokens();
      setAuthData({
        state: AuthState.RedirectingToLogoutPage,
        userProfile: noUserProfile,
      });
    },
    authenticationConfirmed: () => {
      setAuthData(prev => {
        if (prev.state === AuthState.AuthenticatedConfirmed) return prev;

        const tokens = getTokens();

        wasAuthenticatedRequestRef.current = Boolean(tokens);
        return tokens
          ? {
              state: AuthState.AuthenticatedConfirmed,
              userProfile: getUserProfile(tokens),
            }
          : {
              state: AuthState.RedirectingToLogoutPage,
              userProfile: noUserProfile,
            };
      });
    },
    authenticationRejected: () => {
      removeTokens();
      setAuthData(({ userProfile }) =>
        wasAuthenticatedRequestRef.current
          ? {
              state: AuthState.ShowingSessionExpiredPage,
              userProfile,
            }
          : {
              state: AuthState.RedirectingToLoginPage,
              userProfile: noUserProfile,
            },
      );
    },
  }));

  useEffect(() => {
    if (authData.state === AuthState.AuthenticatedConfirmed) {
      const tokens = getTokens();

      if (tokens) {
        let timer: ReturnType<typeof setTimeout>;

        const expireOrReset = () => {
          const delay = tokens.expiresAt - Date.now();
          if (delay && delay > 0) {
            timer = setTimeout(expireOrReset, delay);
          } else {
            actions.authenticationRejected();
          }
        };

        expireOrReset();
        return () => clearTimeout(timer);
      }
    }
  }, [actions, actions.authenticationRejected, authData.state]);

  return (
    <AuthActionsContext.Provider value={actions}>
      <AuthStateContext.Provider value={authData.state}>
        <AuthUserProfileContext.Provider value={authData.userProfile}>{children}</AuthUserProfileContext.Provider>
      </AuthStateContext.Provider>
    </AuthActionsContext.Provider>
  );
};
