import { useEffect } from "react";
import { Outlet } from "react-router-dom";

import { useTranslate } from "../i18n/hooks/use-translate.hook";
import { Spinner } from "../ui/spinner/spinner";
import { AuthState } from "./auth-provider/auth-state";
import { useAuthState } from "./auth-provider/auth-state-context";
import { isRedirecting } from "./auth-provider/is-redirecting";
import { CognitoAuthMethod, redirectToCognito } from "./congito-api/cognito-api";
import { pushLocation } from "./storages/location-storage";
import { SessionExpired } from "./ui/session-expired";

const authMethod: Partial<Record<AuthState, CognitoAuthMethod>> = {
  [AuthState.RedirectingToLoginPage]: CognitoAuthMethod.Login,
  [AuthState.RedirectingToLogoutPage]: CognitoAuthMethod.Logout,
};

export const AuthGuard = () => {
  const t = useTranslate();
  const authState = useAuthState();

  useEffect(() => {
    if (isRedirecting(authState)) {
      if (authState === AuthState.RedirectingToLoginPage) pushLocation();
      redirectToCognito(authMethod[authState]!);
    }
  }, [authState]);

  return isRedirecting(authState) ? (
    <Spinner>{t(`component.auth.${authState}.message`)}</Spinner>
  ) : (
    <>
      {authState === AuthState.ShowingSessionExpiredPage && <SessionExpired />}
      <Outlet />
    </>
  );
};
