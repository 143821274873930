import { Theme, ThemeOptions } from "@mui/material/styles";

export const selectTheme = (theme: Theme): ThemeOptions["components"] => ({
  MuiSelect: {
    styleOverrides: {
      select: {
        "&[multiple]": {
          padding: 0,
        },
        "&[multiple] option": {
          color: "currentColor",
          padding: theme.spacing(0.5, 1),
        },
        "&:focus, &:active": {
          background: theme.palette.background.paper,
        },
        height: `calc(${theme.spacing(5)} - 2px)`,
        boxSizing: "border-box",
        display: "flex",
        alignItems: "center",
        flex: 1,
        padding: theme.spacing(0, 1),
      },
    },
  },
});
