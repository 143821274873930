import { z } from "zod";

import { ApiValidationErrorsSchema } from "./api-validation-errors-schema";
import { NonValidationErrors } from "./non-validation-error";
import { ValidationErrors } from "./validation-errors";

export const ValidationErrorsSchema = ApiValidationErrorsSchema.transform(apiErrors => {
  if (apiErrors.errors.every(e => "handler" in e && e.handler === "user")) {
    const errors = new ValidationErrors();

    for (const apiError of apiErrors.errors) {
      const { fieldName, errorCode, errorDescription } = apiError;
      if (fieldName) {
        errors.addFieldError(fieldName, errorCode, errorDescription);
      } else {
        errors.addGeneralError(errorCode, errorDescription);
      }
    }

    return errors;
  } else {
    const errors = new NonValidationErrors();

    apiErrors.errors
      .filter(e => !("hander" in e) || e.hander !== "user")
      .forEach(e => errors.add(e.errorCode, e.errorDescription));

    return errors;
  }
});
export type ValidationErrorsSchema = z.infer<typeof ValidationErrorsSchema>;
