import { AddCircle } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useCallback } from "react";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";

import { addAction, AppShieldingActions, AppShieldingFeatures } from "../../../../../../_common/datadog";
import { usePersistedNavigationParameters } from "../../../../../../_common/hooks/use-persisted-navigation-parameters";
import { useTranslate } from "../../../../../../_common/i18n/hooks/use-translate.hook";
import { ApplicationBarPrimaryButton } from "../../../../../../_common/ui/application-bar/application-bar-primary-button";
import { Layout } from "../../../../../../_common/ui/layout/layout";
import { Banners } from "../banners/banners";
import { ProjectsList } from "./components/projects-list";
import {
  PROJECTS_LIST_PAGE_PARAM_NAME,
  PROJECTS_LIST_SORT_COLUMN_PARAM_NAME,
  PROJECTS_LIST_SORT_ORDER_PARAM_NAME,
} from "./constants";

export const ProjectsListPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  usePersistedNavigationParameters(
    PROJECTS_LIST_PAGE_PARAM_NAME,
    PROJECTS_LIST_SORT_COLUMN_PARAM_NAME,
    PROJECTS_LIST_SORT_ORDER_PARAM_NAME,
  );

  const t = useTranslate();

  const handleCreateProject = useCallback(() => {
    addAction(AppShieldingActions.OpenCreateProject, AppShieldingFeatures.ProjectList);
    navigate(`new?${searchParams.toString()}`);
  }, [navigate, searchParams]);

  return (
    <Layout
      title={t("component.projects.layout.content.title")}
      appBarContent={
        <ApplicationBarPrimaryButton
          startIcon={<AddCircle />}
          aria-label={t("component.new-project.panel.heading")}
          onClick={handleCreateProject}
        >
          {t("word.create")}
        </ApplicationBarPrimaryButton>
      }
    >
      <Box display="flex" flexDirection="column" width="100%" gap={2}>
        <Banners />
        <ProjectsList />
      </Box>
      <Outlet />
    </Layout>
  );
};
