import { ErrorOutline as ErrorOutlineIcon, WarningAmber as WarningAmberIcon } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { ariaLabelledById } from "../../../products/app-shielding/features/configurations/utils/aria-labelled-by-id";
import { InputStatus } from "./form-input";

interface InputValidationMessageProps {
  inputName: string;
  status: InputStatus;
  message: string | undefined;
  className?: string;
}

export function InputValidationMessage({ inputName, message, className, status }: InputValidationMessageProps) {
  const { classes, cx } = useStyles({ status });

  return (
    <Typography component="span" className={cx(classes.message, className)} id={ariaLabelledById(inputName)}>
      {status === "error" && <ErrorOutlineIcon fontSize="small" />}
      {status === "warning" && <WarningAmberIcon fontSize="small" />}
      {message}
    </Typography>
  );
}

const useStyles = makeStyles<{ status: InputStatus }>()((theme, { status }) => ({
  message: {
    margin: 0,
    color: theme.palette[status].main,
    display: "flex",
    alignItems: "center",
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
    "& svg": {
      marginRight: theme.spacing(0.7),
    },
  },
}));
