import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  FirstPage as FirstPageIcon,
  LastPage as LastPageIcon,
} from "@mui/icons-material";
import { alpha, Box, BoxProps, Paper, Typography, useTheme } from "@mui/material";
import { Navigate, useSearchParams } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { useTranslate } from "../../../i18n/hooks/use-translate.hook";
import { searchLink } from "../../../utils/search-link";
import { DEFAULT_PARAM_NAME } from "./default-param-name";
import { PaginationButton } from "./pagination-button";
import { useCurrentPage } from "./use-current-page.hook";

interface PaginatorProps extends BoxProps {
  total: number;
  paramName?: string;
}
export const Paginator = ({ total, paramName = DEFAULT_PARAM_NAME, ...boxProps }: PaginatorProps) => {
  const current = useCurrentPage(paramName);
  const t = useTranslate();
  const { classes } = useStyles();

  const [searchParams] = useSearchParams();
  const firstPage = searchLink(searchParams, { [paramName]: "1" });
  const previousPage = searchLink(searchParams, { [paramName]: (current - 1).toString() });
  const nextPage = searchLink(searchParams, { [paramName]: (current + 1).toString() });
  const lastPage = searchLink(searchParams, { [paramName]: total.toString() });
  const theme = useTheme();

  if (current <= 0) return <Navigate to={firstPage} replace />;
  if (current > total) return <Navigate to={lastPage} replace />;

  return (
    <Box className={classes.stickyContainer}>
      <Box
        alignItems="center"
        borderTop={`1px solid ${alpha(theme.palette.common.black, 0.1)}`}
        borderRadius="0 0 3px 3px"
        component={Paper}
        display="flex"
        height={68}
        justifyContent="center"
        {...boxProps}
      >
        <Box
          component="nav"
          alignItems="center"
          color="text.primary"
          display="flex"
          height="100%"
          aria-label="pagination"
          justifyContent="space-between"
          width={225}
        >
          <PaginationButton
            aria-label={t("component.paginator.first-page.aria-label")}
            disabled={current === 1}
            to={firstPage}
          >
            <FirstPageIcon />
          </PaginationButton>

          <PaginationButton
            aria-label={t("component.paginator.prev-page.aria-label")}
            disabled={current === 1}
            to={previousPage}
          >
            <ChevronLeftIcon />
          </PaginationButton>

          <Typography
            aria-label={`${t("component.paginator.page-index.aria-label", { total, current })}`}
            aria-live="assertive"
            sx={{
              lineHeight: "24px",
              outlineColor: theme.palette.primary.main,
              padding: theme.spacing(0, 1),
            }}
          >
            {t("component.paginator.page-index", { total, current })}
          </Typography>

          <PaginationButton
            aria-label={t("component.paginator.next-page.aria-label")}
            disabled={current === total}
            to={nextPage}
          >
            <ChevronRightIcon />
          </PaginationButton>

          <PaginationButton
            aria-label={t("component.paginator.last-page.aria-label")}
            disabled={current === total}
            to={lastPage}
          >
            <LastPageIcon />
          </PaginationButton>
        </Box>
      </Box>
    </Box>
  );
};

Paginator.DEFAULT_ROWS_COUNT_PER_PAGE = 25;

const useStyles = makeStyles()(theme => ({
  stickyContainer: {
    position: "sticky",
    bottom: 0,
    margin: theme.spacing(0, -1, -2.5),
    padding: theme.spacing(0, 1, 2.5),
    background: theme.palette.grey["100"],
  },
}));
