import { useMutation, useQueryClient } from "@tanstack/react-query";

import { ApiEntity } from "../../../../../_common/api/api-entity";
import { MutationError } from "../../../../../_common/api/errors/mutation-error";
import { deleteOne } from "../../../../../_common/api/methods/delete-one";
import { queryKeys } from "../../../../../_common/api/query-keys/query-keys";
import { useRestClient } from "../../../../../_common/api/rest-client/rest-client-context";
import { ClientCredentialsI18nToastKey } from "./_support/client-credentials-i18n-toast-key";
import { GetClientCredentials } from "./get-client-credentials";

export function useDeleteClientCredentials() {
  const queryClient = useQueryClient();
  const restClient = useRestClient();

  return useMutation<void, MutationError<void>, void>({
    mutationKey: queryKeys.settings.clientCredentials().item().delete(),
    mutationFn: () => deleteOne(restClient, ApiEntity.ClientCredentials),
    onSuccess() {
      queryClient.setQueryData<GetClientCredentials>(queryKeys.settings.clientCredentials().item().details(), {
        clientId: "",
        clientSecret: "",
      });
    },
    meta: { i18nToastKey: ClientCredentialsI18nToastKey.DELETE },
  });
}
