import { MouseEvent } from "react";
import { Link, LinkProps } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

export const RowLink = ({ children, ...props }: LinkProps) => {
  const { classes } = useStyles();
  const handleClick = (event: MouseEvent<HTMLAnchorElement>) => event.preventDefault();

  return (
    <Link className={classes.link} {...props} onClick={handleClick} tabIndex={-1}>
      {children}
    </Link>
  );
};

const useStyles = makeStyles()(theme => ({
  link: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 600,
    textDecoration: "none",
    ...theme.mixins.lineClamp(1),
  },
}));
