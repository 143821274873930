import { MenuItem, MenuItemProps } from "@mui/material";
import React, { forwardRef, ReactNode } from "react";

export interface OptionProps extends React.HTMLProps<HTMLOptionElement> {
  children: ReactNode;
  multiple?: boolean;
  native?: boolean;
}

export const Option = forwardRef<HTMLOptionElement & HTMLLIElement & HTMLButtonElement, OptionProps & MenuItemProps>(
  ({ action, children, multiple, native, ...props }, ref) => {
    return multiple || native ? (
      <option {...props} ref={ref}>
        {children}
      </option>
    ) : (
      <MenuItem {...props} ref={ref}>
        {children}
      </MenuItem>
    );
  },
);

Option.displayName = "Option";
