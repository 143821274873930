import { useWatch } from "react-hook-form";

import { useTranslate } from "../../../../../../_common/i18n/hooks/use-translate.hook";
import { Alert } from "../../../../../../_common/ui/alert/alert";
import { DebugModeParameterWarning } from "../../ui/debug-mode-parameter-warning";

interface DebugModeWarningsProps {
  isEditMode: boolean;
  debuggable: boolean | undefined;
}
export const DebugModeWarnings = ({ isEditMode, debuggable }: DebugModeWarningsProps) => {
  const t = useTranslate();
  const isDebugMode = (useWatch({ name: "debugMode", exact: true }) as boolean) ?? false;
  return (
    <>
      {isEditMode && isDebugMode && (
        <Alert
          type="warning"
          title={t("common.configuration.debug-mode.alert.title")}
          description={t("common.configuration.debug-mode.alert.description")}
        />
      )}
      {isEditMode && debuggable && <DebugModeParameterWarning debugMode={isDebugMode} />}
    </>
  );
};
