import { alpha, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { useTranslate } from "../../i18n/hooks/use-translate.hook";
import { Logo } from "../logo/logo";
import { sideBarTransition, TransitionType } from "../theme/transitions";

type LogoMenuItemProps = {
  open: boolean;
};

export const LogoMenuItem = ({ open }: LogoMenuItemProps) => {
  const { classes } = useStyles({ open });
  const t = useTranslate();
  const oneSpanLabel = t("word.onespan");

  return (
    <ListItem classes={{ root: classes.listItemButton }} disablePadding role="banner" aria-label={oneSpanLabel}>
      <ListItemIcon classes={{ root: classes.listItemIcon }}>
        <Logo className={classes.logoIcon} aria-label={oneSpanLabel} data-testid="logo" />
      </ListItemIcon>
      <ListItemText primary={oneSpanLabel} classes={{ primary: classes.listItemText }} />
    </ListItem>
  );
};

const useStyles = makeStyles<{ open: boolean }>()((theme, { open }) => ({
  listItemButton: {
    lineHeight: theme.typography.pxToRem(24),
    padding: theme.spacing(1.25, 2),
    background: theme.palette.primary.light,
    height: theme.spacing(7.875),
    "&:focus": {
      background: theme.palette.primary.light,
    },
  },
  logoIcon: {
    width: 31,
    height: 31,
  },
  listItemIcon: {
    display: "flex",
    width: 31,
    minWidth: 31,
    justifyContent: "center",
    alignItems: "center",
    marginRight: theme.spacing(2),
    color: alpha(theme.palette.grey[800], 0.79),
  },
  listItemText: {
    fontWeight: 600,
    color: theme.palette.primary.main,
    ...(open
      ? {
          opacity: 1,
          transition: sideBarTransition(theme, TransitionType.Open, "opacity"),
        }
      : {
          opacity: 0,
          transition: sideBarTransition(theme, TransitionType.Close, "opacity"),
        }),
  },
}));
