import { alpha } from "@mui/material";
import { makeStyles } from "tss-react/mui";

interface InputCharacterCounterProps {
  max?: number;
  value?: number;
}

export const InputCharacterCounter = ({ value = 0, max }: InputCharacterCounterProps) => {
  const { classes } = useStyles();
  return max ? <span className={classes.root} data-testid="input-character-counter">{`${value}/${max}`}</span> : null;
};

const useStyles = makeStyles()(theme => ({
  root: {
    marginRight: theme.spacing(0.1),
    color: alpha(theme.palette.text.primary, 0.79),
    minWidth: "60px",
    flex: 1,
    textAlign: "right",
  },
}));
