import { Theme, ThemeOptions } from "@mui/material";

export const menuTheme = (theme: Theme): ThemeOptions["components"] => ({
  MuiMenu: {
    styleOverrides: {
      paper: {
        marginTop: theme.spacing(0.5),
        gap: theme.spacing(2),
        filter: "none!important",
        border: "none",
        boxShadow:
          "0px 3px 15px -1px rgba(0, 0, 0, 0.10), 0px 2px 2px -1px rgba(0, 0, 0, 0.15), 0px 0px 1px 0px rgba(0, 0, 0, 0.20);",
      },
      list: {
        padding: theme.spacing(1, 0),
        "& .MuiMenuItem-root": {
          padding: theme.spacing(1.25, 2),
        },
      },
    },
  },
});
