import { Box, Button, ButtonProps } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { MouseEvent } from "react";
import { makeStyles } from "tss-react/mui";

import { FixedWidthSkeleton } from "../skeleton/fixed-width-skeleton";
import { createBoxShadow } from "../utils/create-box-shadow";

export const PanelFooter = ({ confirmButton, cancelButton, isLoading, onClose }: PanelFooterProps) => {
  const { classes } = useStyles();
  const theme = useTheme();

  return (
    <Box className={classes.footer}>
      {cancelButton && (
        <Button
          variant="outlined"
          color="primary"
          onClick={e => onClose && onClose(e, "footerCloseClick")}
          {...cancelButton}
        >
          {cancelButton.label}
        </Button>
      )}
      {confirmButton &&
        (!isLoading ? (
          <Button variant="contained" color="primary" {...confirmButton}>
            {confirmButton.label}
          </Button>
        ) : (
          <FixedWidthSkeleton height={theme.spacing(5.5)} data-testid="side-form-submit-skeleton-loader" />
        ))}
    </Box>
  );
};

const useStyles = makeStyles()(theme => ({
  footer: {
    boxShadow: `inset ${createBoxShadow(theme.palette.common.black)}`,
    boxSizing: "border-box",
    height: theme.spacing(10.5),
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: theme.spacing(2.5),
    "& button:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },
}));

interface PanelFooterProps {
  cancelButton?: ButtonAndLabel;
  confirmButton?: ButtonAndLabel;
  isLoading?: boolean;
  onClose?: (e: MouseEvent<HTMLButtonElement>, reason: "footerCloseClick") => void;
}

export type ButtonAndLabel = ButtonProps & { label: string };
