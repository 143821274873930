import { useTranslate } from "../../i18n/hooks/use-translate.hook";
import { ToastButton } from "./toast-button";

interface RetryToastButtonProps {
  onClick: () => Promise<unknown>;
}

export const RetryToastButton = ({ onClick }: RetryToastButtonProps) => {
  const t = useTranslate();

  return (
    <ToastButton toastType="error" onClick={onClick}>
      {t("word.retry")}
    </ToastButton>
  );
};
