import { Typography } from "@mui/material";
import { ReactNode } from "react";
import { Link } from "react-router-dom";

import { ENV } from "../../../../../../_common/env-vars";

enum BannerType {
  Info = "info",
  Warning = "warning",
  Error = "error",
}

type Banner = {
  type: BannerType;
  title: string;
  description: ReactNode;
};

const exampleTestData: Banner[] = [
  {
    type: BannerType.Info,
    title: "Some title 1",
    description: (
      <Typography>
        Some description with <Link to="/link">link</Link>
      </Typography>
    ),
  },
  {
    type: BannerType.Warning,
    title: "Some title 2",
    description: "Some description 2",
  },
  {
    type: BannerType.Error,
    title: "Some title 3",
    description: "Some description 3",
  },
];

export const bannersData: Banner[] = !ENV.TEST ? [] : exampleTestData;
