import { ShielderMetadata } from "@mob/shielder-metadata";
import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import { ApiEntity } from "../../../../../_common/api/api-entity";
import { getOne } from "../../../../../_common/api/methods/get-one";
import { queryKeys } from "../../../../../_common/api/query-keys/query-keys";
import { useRestClient } from "../../../../../_common/api/rest-client/rest-client-context";
import { useTranslate } from "../../../../../_common/i18n/hooks/use-translate.hook";
import { ID } from "../../../../../_common/types";
import { appShieldingRootPath } from "../../../app-shielding-root-path";
import { Platform } from "../types/platform";
import { Version } from "../types/version";
import { ShielderI18nToastKey } from "./_support/shielder-i18n-toast-key";
import { ApiGetShieldersListItem, getShieldersListItemTransformer } from "./get-shielders-list-item";

export const ApiGetShielder = ApiGetShieldersListItem.extend({
  shielderPackageUrl: z.string().optional(),
  shielderMetaData: ShielderMetadata,
});
export type ApiGetShielder = z.input<typeof ApiGetShielder>;

function getShielderTransformer<T extends z.infer<typeof ApiGetShielder>>({ shielderMetaData, ...value }: T) {
  return { ...getShieldersListItemTransformer(value), shielderMetadata: shielderMetaData };
}

export const GetShielder = ApiGetShielder.transform(getShielderTransformer);
export type GetShielder = z.infer<typeof GetShielder>;

interface UseGetShielder {
  shielderId?: ID;
  shielderPlatform?: Platform;
  shielderVersion?: Version;
}
export const useGetShielder = ({ shielderId, shielderPlatform, shielderVersion }: UseGetShielder | undefined = {}) => {
  const t = useTranslate();
  const resourceName = shielderVersion
    ? t(`component.shielder.${shielderPlatform}`, { version: shielderVersion })
    : t(`word.shield`);
  const restClient = useRestClient();

  return useQuery({
    queryKey: queryKeys[appShieldingRootPath].shielders().item(shielderId).details(),
    queryFn: () => getOne(restClient, ApiEntity.Shielder, shielderId, GetShielder),
    enabled: Boolean(shielderId),
    meta: { i18nToastKey: ShielderI18nToastKey.GET_SHIELDER, resourceName },
  });
};
