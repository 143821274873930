import { z } from "zod";

import { IdToken } from "../congito-api/id-token";
import { strToTokenData } from "../congito-api/str-to-token-data";
import { groupsToRoles } from "../user-profile/groups-to-roles";
import { StoredTokens } from "./stored-tokens";

export const ParsedTokens = StoredTokens.transform(data => {
  const idTokenData = strToTokenData(data.idToken);

  const idToken = IdToken.parse(idTokenData);

  return {
    ...data,
    id: idToken["cognito:username"],
    accountId: idToken["custom:accountid"],
    name: idToken.name,
    company: idToken["custom:companyname"],
    email: idToken.email,
    roles: groupsToRoles(idToken["cognito:groups"]),
  };
});
export type ParsedTokens = z.infer<typeof ParsedTokens>;
