import { CheckCircle as CheckCircleIcon, Error as ErrorIcon, SvgIconComponent } from "@mui/icons-material";
import { useTheme } from "@mui/material";

import { useTranslate } from "../../../../../_common/i18n/hooks/use-translate.hook";
import { Tag } from "../../../../../_common/ui/tag/tag";
import { ApiShieldingStatus } from "../api/types";
import { ShieldingStatus } from "../types";
import { isShieldingInProgress } from "../utils/is-shielding-in-progress";

const shieldingIconMap: Record<ShieldingStatus, SvgIconComponent | undefined> = {
  [ShieldingStatus.Success]: CheckCircleIcon,
  [ShieldingStatus.Failed]: ErrorIcon,
  [ShieldingStatus.InProgress]: undefined,
};

const shieldingStatusTextMap: Record<ApiShieldingStatus, ShieldingStatus | false> = {
  [ApiShieldingStatus.Success]: ShieldingStatus.Success,
  [ApiShieldingStatus.Aborted]: ShieldingStatus.Failed,
  [ApiShieldingStatus.Failed]: ShieldingStatus.Failed,
  [ApiShieldingStatus.Timeout]: ShieldingStatus.Failed,
  [ApiShieldingStatus.InProgress]: ShieldingStatus.InProgress,
  [ApiShieldingStatus.Queued]: ShieldingStatus.InProgress,
};

interface ShieldingStatusTagProps {
  status: ApiShieldingStatus;
  progress?: number;
}

export function ShieldingStatusTag({ status, progress }: ShieldingStatusTagProps) {
  const t = useTranslate();
  const theme = useTheme();
  const inProgress = isShieldingInProgress(status);
  let color = "transparent";

  if (inProgress) {
    color = theme.palette.info.main;
  } else if (status === ApiShieldingStatus.Success) {
    color = theme.palette.success.main;
  } else if ([ApiShieldingStatus.Aborted, ApiShieldingStatus.Timeout, ApiShieldingStatus.Failed].includes(status)) {
    color = theme.palette.error.main;
  }

  const shieldStatus = shieldingStatusTextMap[status];

  return shieldStatus ? (
    <Tag
      fontWeight={500}
      icon={shieldingIconMap[shieldStatus]}
      text={t(`component.shielding.status.${shieldStatus}`, {
        progress: progress ?? -1,
      })}
      isLoading={inProgress}
      color={color}
    />
  ) : null;
}
