import { useEffect } from "react";
import { useRouteError } from "react-router-dom";

import { logError } from "../../error-logging/log-error";
import { ErrorPage } from "./error-page";

export const RouterErrorBoundary = () => {
  const error = useRouteError();

  useEffect(() => logError(error), [error]);

  return <ErrorPage error={error} />;
};
