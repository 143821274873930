import { Tooltip } from "@mui/material";

import { useTranslate } from "../../../../../../../../_common/i18n/hooks/use-translate.hook";
import { WarningAmberIcon } from "../../../../../../../../_common/ui/icons/warning-amber-icon";
import { ColumnCellProps } from "../../../../../../../../_common/ui/table/data-table";
import { ConfigStatus } from "../../../../../configurations/api/types/config-status";

type NoConfigurationWarningCellProps = ColumnCellProps<
  {
    configStatus: ConfigStatus;
  },
  unknown
>;
export const NoConfigurationWarningCell = (props: NoConfigurationWarningCellProps) => {
  const t = useTranslate();

  return !props.isLoading && props.configStatus === ConfigStatus.None ? (
    <Tooltip title={t("component.no-configuration.warning")} placement="top" arrow={false}>
      <WarningAmberIcon color="warning" aria-label={t("component.no-configuration.warning")} />
    </Tooltip>
  ) : null;
};
