import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import NotFoundIcon from "@mui/icons-material/BrowserNotSupported";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { useTranslate } from "../../i18n/hooks/use-translate.hook";
import { ApplicationBarIconButtonType } from "../application-bar/types";
import { EmptyState } from "../empty-state/empty-state";
import { Layout } from "../layout/layout";

const resourceParamName = "resource-name";

interface NotFoundProps {
  rootPath: string;
  rootButtonLabel?: string;
}
export const NotFound = ({ rootPath, rootButtonLabel }: NotFoundProps) => {
  const resourceName = useSearchParams()[0].get(resourceParamName);
  const t = useTranslate();
  const navigate = useNavigate();

  const navigateBack = () => {
    navigate("/", { replace: true });
  };

  return (
    <Layout isLoading iconButtonType={ApplicationBarIconButtonType.Back} iconButtonClickHandler={navigateBack} title="">
      <EmptyState
        icon={NotFoundIcon}
        title={
          resourceName
            ? t("component.empty-state.resource-not-found.title", { resourceName })
            : t("component.empty-state.not-found.title")
        }
        description={
          resourceName
            ? t("component.empty-state.resource-not-found.description")
            : t("component.empty-state.not-found.description")
        }
        buttonProps={{
          component: Link,
          to: rootPath,
          startIcon: <ArrowBackIcon />,
          label: rootButtonLabel ?? t("component.empty-state.not-found.button"),
          replace: true,
        }}
      />
    </Layout>
  );
};

NotFound.resourceName = resourceParamName;
