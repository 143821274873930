import { Folder } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useMemo } from "react";

import { useTranslate } from "../../../_common/i18n/hooks/use-translate.hook";
import { EmptyState } from "../../../_common/ui/empty-state/empty-state";
import { DataTable, DataTableProps } from "../../../_common/ui/table/data-table";
import { useGetOnPremShieldersList } from "../api/get-on-prem-shielders-list-item";
import { FileNameCell } from "./cells/file-name-cell";

export const OnPremShieldersList = () => {
  const { data = [], isLoading, isError } = useGetOnPremShieldersList();

  const t = useTranslate();
  const columns = useGenerateColumns();

  if (!isLoading && !isError && !data.length) {
    return (
      <EmptyState
        icon={Folder}
        title={t("component.on-prem-shielders.list.empty-state.title")}
        description={t("component.on-prem-shielders.list.empty-state.description")}
      />
    );
  }

  return (
    <Box pb={2.5}>
      <DataTable
        context={null}
        caption={t("component.on-prem-shielders.list.table.caption")}
        isLoading={isLoading || isError}
        columns={columns}
        data={data.map(item => ({
          id: item.fileName,
          fileName: item.fileName,
        }))}
        defaultSortColumn="fileName"
      />
    </Box>
  );
};

const useGenerateColumns = (): DataTableProps<"", "", Readonly<{ fileName: string }>, null>["columns"] => {
  const t = useTranslate();
  return useMemo(
    () => [
      {
        width: "100%",
        key: "fileName",
        title: t("component.on-prem-shielders.list.column.app-shielding"),
        component: FileNameCell,
      },
    ],
    [t],
  );
};
