import { serverErrorMessage } from "./utils/server-error-message";

type ValidationError = {
  code: number;
  description: string;
};

export class ValidationErrors {
  // General errors API
  private _generalErrors: ValidationError[] = [];
  addGeneralError(code: number, description: string) {
    this._generalErrors.push({ code, description });
  }
  get generalErrors() {
    return this._generalErrors;
  }
  get hasGeneralErrors() {
    return Boolean(this.generalErrors?.length);
  }
  get generalErrorsMessage() {
    return ValidationErrors._validationErrorsMessage(this.generalErrors);
  }

  // Field errors API
  private _fieldErrors: Record<string, ValidationError[]> = {};
  addFieldError(fieldName: string, code: number, description: string) {
    this._fieldErrors[fieldName] ||= [];
    this._fieldErrors[fieldName].push({ code, description });
  }
  fieldErrors(fieldName: string): ValidationError[] | undefined {
    return this._fieldErrors[fieldName];
  }
  hasFieldErrors(fieldName?: string) {
    return Boolean(fieldName ? this.fieldErrors(fieldName)?.length : Object.keys(this._fieldErrors).length);
  }
  fieldErrorsMessage(fieldName: string) {
    return ValidationErrors._validationErrorsMessage(this.fieldErrors(fieldName));
  }
  get fieldsWithErrors() {
    return Object.keys(this._fieldErrors);
  }

  // Translation and formatting helper
  private static _validationErrorsMessage(errors: ValidationError[] | undefined) {
    return errors?.map(error => serverErrorMessage(error.code, error.description)).join(" ");
  }
}
