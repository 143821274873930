import { z } from "zod";

import { DateTime } from "../../../../../../_common/types";
import { ApiShieldingStatus } from "../types";

export const ApiGetShieldingProgress = z.object({
  uuid: z.string(),
  shieldingStatus: z.nativeEnum(ApiShieldingStatus),
  progressInPercent: z.number().int().nonnegative().lte(100).optional(),
  progressTimestamp: DateTime.optional(),
  shieldingLogs: z.string().optional(),
});
export type ApiGetShieldingProgress = z.input<typeof ApiGetShieldingProgress>;

function getShieldingProgressTransformer<T extends z.infer<typeof ApiGetShieldingProgress>>({ uuid, ...rest }: T) {
  return {
    id: uuid,
    ...rest,
  };
}

export const GetShieldingProgress = ApiGetShieldingProgress.transform(getShieldingProgressTransformer);
export type GetShieldingProgress = z.infer<typeof GetShieldingProgress>;
