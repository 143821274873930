import { Box, BoxProps } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { Multiline } from "../multiline/multiline";

interface CodeViewerProps extends BoxProps {
  code: string | undefined;
}

export function CodeViewer({ code = "", ...boxProps }: CodeViewerProps) {
  const { classes } = useStyles();

  return (
    <Box component="code" className={classes.root} {...boxProps}>
      <Multiline value={code.trim()} />
    </Box>
  );
}

const useStyles = makeStyles()(theme => ({
  root: {
    backgroundColor: theme.palette.default?.main,
    color: theme.palette.common.white,
    flex: 1,
    overflow: "auto",
    padding: theme.spacing(2),
    margin: 0,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.spacing(3),
    fontFamily: "Courier, monospace",
    fontWeight: 400,
    textAlign: "left",
  },
}));
