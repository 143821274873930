import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { SortOrder } from "../../../api/methods/get-many-with-pagination";
import { ID } from "../../../types";
import { DEFAULT_SORT_COLUMN_PARAM_NAME, DEFAULT_SORT_ORDER_PARAM_NAME } from "./default-sort-param-names";
import { sortOrderUrlToApi } from "./sort-order-url-api";
import { Column, SortParamsNames } from "./types";

export interface SortingParams<TApiSortColumn extends string> {
  sortColumn: TApiSortColumn;
  sortOrder: SortOrder;
}
export function useTableSort<
  TApiSortColumn extends string,
  TUrlSortColumn extends string,
  TData extends { readonly id: ID },
  TContext,
>(
  columns: Partial<Column<TApiSortColumn, TUrlSortColumn, TData, TContext>>[],
  defaultSortColumn: TApiSortColumn,
  {
    sortColumnParamName = DEFAULT_SORT_COLUMN_PARAM_NAME,
    sortOrderParamName = DEFAULT_SORT_ORDER_PARAM_NAME,
  }: SortParamsNames = {},
): SortingParams<TApiSortColumn> {
  const [searchParams] = useSearchParams();
  const sortColumnParamValue = searchParams.get(sortColumnParamName);
  const sortOrderParamValue = searchParams.get(sortOrderParamName);

  return useMemo(() => {
    let sortOrder = sortOrderUrlToApi(sortOrderParamValue),
      defaultSortOrder: SortOrder = SortOrder.Asc;
    let sortColumn: TApiSortColumn | undefined;

    if (sortColumnParamValue || !sortOrder) {
      for (const { sorting } of columns) {
        if (sorting?.urlParamName === sortColumnParamValue) {
          sortColumn = sorting.apiColumnName;
          sortOrder ??= sorting.defaultOrder;
        }
        if (sorting?.apiColumnName === defaultSortColumn) defaultSortOrder = sorting.defaultOrder;
      }
    }

    sortColumn ??= defaultSortColumn;
    sortOrder ??= defaultSortOrder;
    return { sortColumn, sortOrder };
  }, [sortColumnParamValue, sortOrderParamValue, columns, defaultSortColumn]);
}
