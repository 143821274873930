import { CardHeader as MuiCardHeader, CardHeaderProps as MuiCardHeaderProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ReactNode } from "react";

type CardHeaderProps = Omit<MuiCardHeaderProps, "title"> & {
  title: ReactNode;
};
export const CardHeader = (props: CardHeaderProps & { title: JSX.Element | string }) => {
  return (
    <StyledCardHeader
      titleTypographyProps={{
        variant: "subtitle1",
      }}
      {...props}
    />
  );
};
const StyledCardHeader = styled(MuiCardHeader)(({ theme }) => ({
  padding: theme.spacing(2.5, 2.5, 0, 2.5),
  display: "flex",
  justifyContent: "space-between",
  borderBottom: "none",
}));
