import axios from "axios";

import { getTokens } from "../../auth/storages/token-storage/get-token";
import { ENV } from "../../env-vars";

export const restClient = axios.create({ baseURL: `${ENV.API_BASE}/v1` });

restClient.interceptors.request.use(
  config => {
    const { accessToken, idToken } = getTokens() ?? {};
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
      config.headers["Id-Token"] = idToken;
    }
    return config;
  },
  error => Promise.reject(error),
);
